body {
    section.start-section {

        .heading {
            
            @media screen and (min-width: 1280px) {
                padding-top: unset;
            }

            .heading-positioner {
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                flex-direction: column;
                padding-top: 0;

                @media screen and (min-width: 1280px) {
                    flex-direction: row;
                    padding-top: $headerHeightLarge;
                }
            }
        }

        &.product {
            
            @media screen and (min-width: 1280px) {
                min-height: 50vh;
                height: 100vh;
            }
        }

        .product-slider {

            .template-content {
    
                @media screen and (min-width: 1280px) {
                    height: 100%;
                    width: 100%;
                    margin-left: 0;
                    padding-right: unset;
                    display: flex;
                    min-height: fit-content;
                    background: linear-gradient(90deg, transparent 20%, hsla(0, 0%, 8%, .9));
                }
                
    
                .heading-positioner {
                    margin-top: auto;
                    margin-bottom: auto;
                    display: flex;
                    flex-direction: column;
                    padding-top: 0;
    
                    @media screen and (min-width: 1280px) {
                        padding-top: $headerHeightLarge;
                        width: 50%;
                        margin-left: 50%;
                        padding-right: 15%;
                    }
    
                    h1, .template-txt {
                        margin-left: 0;
                    }
                }
            }
        }

    }
}