h4, h5 {
    word-break: break-word;
    font-family: 'Arboria-Bold', sans-serif;
    color: $black;
}

h3 {
    font-family: 'Arboria-Book', sans-serif;
    font-weight: 300;
}

h1, h2 {
    font-family: 'Arboria-Light' , sans-serif;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 1em;
    position: relative;
    margin-top: 0;
    margin-bottom: 0.9em;
    float: none;
    padding: 0;
    letter-spacing: normal;
    font-size: 2.2em;

    @media only screen and (max-width: 1535px) {

    }
    @media only screen and (max-width: 1279px) {

    }

    &:after {
        content: '';
        position: absolute;
        height: 3px;
        width: 130px;
        background: $orange;
        bottom: -20px;
        left: -20px;
    }
}