.faq--content{
    margin-top: 200px;
    @media only screen and (max-width: 767px) {
        margin-top: 100px; 
        // h1{
        //     margin-bottom: 90px !important;
        // }
    }
    

    .faq--link{
        display: flex;
        justify-content: space-between;
        margin-top: 110px;
        height: fit-content;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
            padding: 0;
            gap: 2rem;
        }
        
    }

    .questions--list{
        width: 100%;
        margin: 0;
        padding: 0;

        @media only screen and (max-width: 767px) {
            margin-right: 20px;
            margin-left: 20px;
            width: auto;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }

        .faq-block {
            background: #fff;
            box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
            padding: 30px;
            margin: 30px;
            margin-top: 0;
            transition: .2s ease-in;
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 767px) {
                margin: 0;
            }
    
            .question--title {
                position: relative;
                color: #4B4D54;
                font-weight: 800;
                width: 100%;
                margin-right: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0;
                transition: all 0.4s ease-in-out;
                cursor: pointer;
    
                &:after {
                    flex-shrink: 0;
                    content: "";
                    width: 11px;
                    height: 18px;
                    background: url(../img/icon_faq/arrow.svg) no-repeat center center;
                    background-size: contain;
                    position: relative;
                    padding-right: 0;
                    transition: all 0.2s ease-in-out;

                    
                }

                h2 {
                    color: inherit;
                    font-size: 1.1rem;
                    line-height: 1.2;
                    font-weight: 800;
                    text-transform: none;
                    margin: 0;
                    padding-right: 30px;
                }
            }

            .question--content {
                display: none;
                padding-left: 20px;
                padding-right: 60px;

                @media only screen and (max-width: 767px) {
                    padding: 0;
                }

                p {
                    cursor: auto;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
    
            &:hover {
                .question--title {
                    color: #EA5D37;

                    &:after {
                        color: #EA5D37;
                        padding-right: 20px;

                        @media only screen and (max-width: 767px) {
                            padding-right: 0;
                        }
                    }
                }

            }

            &.question--active {
                display: block;
            }

            &.selected {
                .question--title {

                    &.opened-title {

                        &:after {
                            transform: rotate(90deg);
                
                        }
                    }
                
                }
            }
        }
  
    }
}

.category--list{
    box-shadow: 0 8px 24px rgba(0, 0, 0, .1);
    padding: 20px;
    height: fit-content;
    margin-left: 20px;
    min-width: 220px;

    @media only screen and (max-width: 767px) {
        margin-right: 20px;
    }

    ul{
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;

        @media only screen and (max-width: 767px) {
            padding: 0;
            gap: 1rem;
            align-items: center;
        }
        li{
            color: #585960;
            opacity: .5;
            font-weight: 600;
            transition: .2s ease-in;
            cursor: pointer;
            position: relative;

            &:hover,
            &.active {
                color: #EA5D37;
                opacity: 1;
        
                &:after {}
            }
           
            @media only screen and (max-width: 767px) {
                padding: 0;
                &:after{
                    top: 15px !important;
                    left: 20px !important;
                    transform: inherit !important;
                    content: "";
                }
            }
           
        }

    }
}

.faqs-grid {}

.faqs-item {
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0;
    display: block;
    border-bottom: solid 1px #ea6645;
    // background-color: rgba(0, 0, 0, 0.5);

    h2 {

        &:after {
            content: none;
            position: unset;
            height: unset;
            width: unset;
            background: unset;
            bottom: unset;
            left: unset;
        }
    }
    
    .faqs-title {
        color: #494a52;
        font-size: 1.5rem;
        font-weight: bold;
        text-decoration: none;
        width: 100%;
        outline: none;
        transition: all 0.4s ease-in-out;
        margin-top: 0 !important;
        padding-right: 40px;
        position: relative;
        text-align: start;
    }

    .active,
    .faqs-title:hover {
        // background-color: rgba(0, 0, 0, 1);
    }

    .faqs-title:after {
        content: '\002B';
        color: #ea6645;
        font-weight: normal;
        margin-left: auto;
        font-size: 24px;
        line-height: 1;
        padding-left: 20px;
        position: absolute;
        top: 3px;
        right: 0;
        transition: all 0.4s ease-in-out;
    }

    .active:after {
        content: "\2212";
    }

    .faqs-content {
        padding: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }

    .faqs-content-inside {
        // padding: 20px;
    }
}

.faqs-item:last-child {
  border-bottom: none;
}






