body {
  section.notfound {
    // div.mobile-bg {
    //   height: 100%;
	  // width: 100%;
	  // position:absolute;
	  // opacity:.9;
    //   top: 0;
    //   left: 0;
    //   @media screen and (min-width: 840px) {
    //     display:none
    //   }
    // }

    div.block_background {
      height: 100vh;

      picture {
        height: inherit;
        width: 100%;
        background-size: cover;

        img {
          width: auto;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    div.infos {
	    background:linear-gradient(90deg,transparent 50%,hsla(0,0%,8%,.9));

      div.content {

        p {
    		  padding-top:5px;
		      margin-bottom:30px;
        }

        ul {
          padding: 0;
          margin: 0;
        
          li {
            list-style: none;
			      display: list-item;
            
            a {
              padding: 0;
              margin-bottom: 0.2em;
              border: none; 
              text-align:left;
              font-size:1rem;

              @media screen and (max-width:460px){
                font-size:.9rem;
              }
              
              @media screen and (max-width:410px){
                font-size:.8rem;
              }
              
              @media screen and (max-width:840px){
                margin-bottom:.8rem;
              }
              
              span {
                color: $orange;
              }
              
              &:hover {
                color: $orange !important;
              }
            }
          }
        }
      }
    }
  }
}
