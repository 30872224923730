.page-connexion , .page-inscription{
    background-image: url(../../dist/img/bg_mystuv.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    background-size: 100% 100%;
    background-position: 50%;
    min-height: 90vh;
    @media only screen and (max-width: 567px) {
        background-size: cover;
        background-position: 90%;
        padding-bottom: 5em;
    }
    
    p.message {
	    background: white;
	    padding: 1em;
    }
    
    div.template-content{
        background: none;
        max-width: 40vw;
        position: relative;
        max-width: 567px;
        margin: 0 auto;
        padding-top: 13em;
        max-width: 567px;
        padding-bottom: 5em;
        @media only screen and (max-width: 1279px) {
            max-width: 40vw;
        }
        @media only screen and (max-width: 567px) {
            padding-top: 7em;
            max-width: 95vw;
        }

        &.template-content-inscription{
            max-width: 575px;
            @media only screen and (max-width: 567px) {
                padding-top: 7em;
                max-width: 95vw;
            }
        }
       
        h1{
            color: #fff;
            // width: inherit;
            // margin: 0;
            // margin-bottom: 2em;
            // @media only screen and (max-width: 567px) {
            //     margin-bottom: 0em;
            //     margin-bottom: 1em;

            //     &:after{
            //         bottom: 0px;
            //     }
            // }
        }
        p.sub{
            text-align: center;
            color: #fff;
            font-size: 20px;
        }
    }
    
}


form.form-mystuv {
    max-width: 40vw;
    margin: 0 auto;
    
    @media only screen and (max-width: 567px) {
        max-width: 100vw;
    }
    select {
        display: inline-block;
        float: none;
        width: calc(100% - 30px);
        width: calc(33.0033% - 30px);
        background: #fff;
        border: 1px solid #ccc;
        padding: 15px;
        margin: 0 15px 15px;
        height: 3em;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: Arboria-Light,serif;
        border-radius: 0;
    }

    input, select{
        background-color: transparent;
        border: 1px solid #ccc;
        border-radius: 0;
        font-family: Arboria-Light,serif;
        outline: none;
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
        padding: 15px 20px;
        transition: .3s ease;
        background: #fff;
        box-shadow: 0 0 50px 0 rgba(0,0,0,.05);

        &.wide-columns{
            width: calc(50% - 10px);
            
        }
        &.large-columns{
            width: calc(70% - 20px);
            @media only screen and (max-width: 1279px) {
                width: calc(70% - 10px);
            }
            &.medium-columns-adress{
                @media only screen and (max-width: 1279px) {
                    width: 100%;
                }
            }
            
        }
        &.medium-columns{
            width: calc(41% - 10px);
            @media only screen and (max-width: 1279px) {
                width: calc(50% - 10px);
            }
            &.medium-columns-adress{
                @media only screen and (max-width: 1279px) {
                    width: 100%;
                }
            }
            
        }
        &.short-columns{
            width: calc(30% - 0px);
            @media only screen and (max-width: 1279px) {
                width: calc(30% - 10px);
                font-size: inherit;
            }
        }
        &.small-columns{
            width: calc(15% - 0px);
            @media only screen and (max-width: 1279px) {
                width: calc(50% - 10px);
                font-size: inherit;
            }
        }
    }

    .input-container{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    input[type=submit] {
        clear: both;
        float: none;
        display: inline-block;
        font-size: 20px;
        padding: 15px 45px;
        text-transform: uppercase;
        background: #ea6645;
        border: 1px solid #ea6645;
        font-family: Arboria-Bold,serif;
        color: #fff;
        transition: .3s ease;
        text-align: center;
        margin-top: 40px;

        &:hover{
            background: #da5432;
        }
    }


    .cta_connexion , .cta_inscription{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        @media only screen and (max-width: 567px) {

            margin-top: 4em;
        }
        a , span{
            width: calc(49% - 0px);
            @media only screen and (max-width: 567px) {
                width: 100%;
                margin-bottom: 2em;
            }
        }

        span input{
            clear: both;
            float: none;
            display: inline-block;
            font-size: 15px;
            padding: 15px 15px;
            text-transform: uppercase;
            background: #ea6645;
            border: 1px solid #ea6645;
            font-family: Arboria-Bold,serif;
            color: #fff;
            transition: .3s ease;
            text-align: center;
            margin-top: 40px;
            margin: 0;
        }
        a{
            clear: both;
            float: none;
            display: inline-block;
            font-size: 15px;
            padding: 15px 15px;
            text-transform: uppercase;
            background: #fff;
            border: 1px solid #fff;
            font-family: Arboria-Bold,serif;
            color: #ea6645;
            transition: .3s ease;
            text-align: center;
            margin-top: 40px;
            margin: 0;
            @media only screen and (max-width: 567px) {
                width: 100%;
                margin-bottom: 2em;
            }
        }

        &.cta_inscription{
            display: block;
            span{
                display: block;
                margin: 0 auto;
            }
            a{
                background-color: inherit;
                font-size: 14px;
                display: block;
                border: none;
                color: #fff;
                margin: 0 auto;
            }
        }
    }
}

.mystuv-link{
    padding: 5px 0;
    border-left: 1px solid grey;
    padding-left: 30px;
    position: relative;
    z-index: 9;
    color: #fff;
    float: right;
    
    .name {
	    opacity: 1;
	    transition: .4s;
    }
    
    .action {
	    opacity: 0;
	    transition: .4s;
	    position: absolute;
	    left: 30px;
    }
    
    &:hover {
	    .action {
		    opacity: 1;
	    }
	    
	    .name {
		    opacity: 0; 
	    }
    }

    @media only screen and (max-width: 1279px) {
        padding-left: 10px;
        margin-left: 12px;
     }
   
    @media only screen and (max-width: 567px) {
       display: none;
    }
    &.mystuv-link-responsive{
        color: #26272c;
        font-size: 0.9em;
        text-transform: uppercase;
        text-decoration: none;
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 20px;
        border-bottom: 1px solid #bfc0c2;
        list-style: inside none;
        display: block;
        text-align: left;
        border: none;
        display: none;
        @media only screen and (max-width: 567px) {
            display: block;
        }

    }
}