$gris-stuv: #2B2E2D;
$gris-clair: #D5D5D5;
$gris-moyen: #A6A7A7;
$gris-fonce: #707070;
$blanc-stuv: #F4F4E9;
$font_ABC: 'ABC-Diatype', Helvetica, sans-serif;

//modal
div.dcAppContainer {
    #dataceed-modal {
        --dc-font-heading: 'ABC-Diatype', Helvetica, sans-serif;
        --dc-font-body: 'ABC-Diatype', Helvetica, sans-serif;
        --dc-text-button-color: #F4F4E9;
        --dc-text-button-color-hover: #F4F4E9;

        p, li {
            font-family: var(--dc-font-body);
        }

        .dataceed-modal-wrapper {
            border-radius: 0;
    
            .dataceed-modal-container {
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
                
                &::-webkit-scrollbar {
                    display: none;
                }

                @media (max-width: 639px) {
                    position: relative;
                }

                @media (max-width: 900px) {
                    flex-direction: column;
                    overflow-y: auto;
                }
    
                .dataceed-modal-container-img {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 170px;
    
                    @media (min-width: 640px) {
                        width: var(--dc-width-modal-container-img);
                        height: 240px;
                    }
                    
                    @media (min-width: 901px) {
                        height: auto;
                    }

                    img {
                        object-position: 70% top;

                        @media (min-width: 640px) {
                            object-position: 70% bottom;
                        }
                        
                        @media (max-width: 1024px) {
                            object-position: 60% top;
                        }
                        
                        @media (max-width: 767px) {
                            object-position: 70% top;
                        }
                    }

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        z-index: 2;
                        background-image: url(https://s3.eu-central-1.amazonaws.com/dataceed-mailcoach/93/conversions/Stuv_Days_Logo_White_Horizontal_RVB-image.png);
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: 50%;
                        width: 200px;
                        height: 38px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);

                        @media (min-width: 640px) {
                            top: 2em;
                            left: 2em;
                            width: 200px;
                            height: 58px;
                            transform: none;
                        }

                        @media (min-width: 768px) {
                            width: 300px;
                        }

                        @media (min-width: 1024) {
                            width: 360px;
                        }
                    }
                }
    
                .dataceed-modal-container-content {
                    background-color: #F4F4E9;
                    position: relative;
                    min-width: 350px;
                    max-width: unset;

                    @media (min-width: 768px) {
                        min-width: 420px;
                    }

                    @media (max-width: 639px) {
                        position: unset;
                    }

                    @media (max-width: 900px) {
                        width: 100%;
                    }
    
                    .dataceed-close {
                        color: #2B2E2D;
                        position: absolute;
                        right: 2em;
    
                        @media (max-width: 639px) {
                            right: 1.5em;
                            top: 1.5em;
                            color: #F4F4E9;
                        }
    
                        svg {
                            path {
                                fill: currentColor;
                            }
                        }
                    }
    
                    .dataceed-form {
                        color: #2B2E2D;

                        #dataceed-modal-container-content-form-intro {
                        
                            p {
                                font-size: 1.25rem;
                    
                                a {}
                            }
                    
                            .dataceed-text-large {
                                width: 90%;
                                display: block;
                                font-size: 2.5rem;
                                line-height: 1;
                            }
                            
                        }
                    }

                    .dataceed-text-small {
                        font-family: var(--dc-font-body);
                    }
    
                    a.dataceed-signature {
                        display: none;
    
                        img {}
    
                    }
    
                    a.dataceed-signature-white {
    
                        img {}
                    }
                }
            }
        }
    }
    &.active {

    }
}

//form
div.dcAppContainer {
    #dataceed-modal {

        .dataceed-modal-wrapper {

            .dataceed-modal-container {

                .dataceed-modal-container-content {

                    .dataceed-form {
                        
                        #dataceed-modal-container-content-form-content {

                            .dataceed-form-outer {
                                margin-bottom: .75rem;

                                &[data-floating-label=true] {

                                    &:not([data-empty=true]) {
                                        .dataceed-form-label {
                                            transform: translate(-8px, 13%);
                                            background-color: transparent !important;
                                        }
                                    }

                                    .dataceed-form-input {

                                        &:focus {
                                            &~.dataceed-form-label {
                                                transform: translate(-8px, 13%);
                                                background-color: transparent !important;
                                            }
                                        }
                                    }
                                }

                                .dataceed-form-input {
                                    &::placeholder {
                                        color: var(--dc-neutral);
                                    }
                                }
                            }
                            .dataceed-form-outer-button {

                                .dataceed-form-wrapper {
                                    margin-top: .5em;

                                    .dataceed-form-input {
                                        border-radius: 0;
                                        font-weight: 100;
                                        font-size: 1.25em;
                                        display: flex;
                                        align-items: center;
                                        line-height: 1;
                                        background-color:#2B2E2D;

                                        .dataceed-form-suffix-icon {
                                            width: 1.5em;
                                        }
                                    }
                                }
                            }

                            #stepOne {
                                .dataceed-form-outer-button {
                                
                                    .dataceed-form-wrapper {
                                        width: 100%;
                                        
                                        .dataceed-form-input {
                                            width: 100%;
                                            justify-content: space-between;
                                        }
                                    }
                                }
                            }

                            .dataceed-form-step-previous {

                                .dataceed-form-outer-button {
                                
                                    .dataceed-form-wrapper {
                            
                                        .dataceed-form-input {
                                            background: transparent;
                                        }
                                    }
                                }
                            }

                            .dataceed-form-outer-text, 
                            .dataceed-form-outer-select {
                                .dataceed-form-inner {
                                    background-color: white;
                                }

                                .dataceed-form-label {
                                    font-weight: 100;
                                }
                            }

                            .dataceed-form-outer-radio-images {
                                

                                .dataceed-form-legend {
                                    font-weight: 100;
                                }

                                .dataceed-form-wrapper {
                                    background-color: white;
                                    &[data-checked=true] {
                                        background-color: #2B2E2D;
                                        color: #F4F4E9;
                                    }
                                }

                                .dataceed-form-options {
                                    .dataceed-form-option {

                                        .dataceed-form-image-wrapper {
                                            &:before {
                                                background-color: #2B2E2D;
                                            }
                                        }

                                        .dataceed-form-option-label-wrapper {

                                            .dataceed-form-option-label {
                                                transition: all .1s ease;
                                                font-weight: 100;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }
    &.active {
    
    }
} 