ul.inbound-catalogue {
    max-width: 1140px;
    padding: 0 20px;
    margin: 0 auto;
    margin-top: 70px;

    li.inbound-list-block {
        background-color: white;
        list-style: none;
        padding: 10px;
        width: calc((100% / 3) - 30px);
        min-height: 200px;
        height: 20vh;
        float: left;
        margin: 15px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.06);
        @media screen and (max-width:940px){
            width: calc((100% / 2) - 30px);            
        }
        @media screen and (max-width:620px){
            width: calc(100% - 30px);
            height: 30vh;
              
        }

        h2 {
            background: #f9f9f9 !important;
            text-align: center;
            text-transform: uppercase;
            opacity: 1;
            color: rgba(black, .75);
            font-size: 17px;
        }
        
        img.badge {
            width: 60px;
            position: absolute;
            top: 10px;
            right: 15px;
        }

        span {

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    li.inbound-list-block {
        background-color: #fff;
        list-style: none;
        padding: 10px;
        width: calc(33.3333333333% - 30px);
        min-height: 200px;
        height: 20vh;
        float: left;
        margin: 15px;
        box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
        height: auto;
        overflow:hidden;
        display: flex;
        flex-direction:column;
        padding:0;
        cursor: pointer;
        min-height:0;

        h2 {
            display: block;
            text-align: left;
            width: 100%;
            margin: 0;
            padding: 20px 50px 20px 20px;
            background: white;
            opacity: 1;
            text-transform: initial;
            position: relative;
            font-family: 'Arboria-Bold';

            &:after {
                content: '';
                position: absolute;
                height: 18px;
                width: 18px;
                right: 25px;
                top: 50%;
                opacity: .75;
                transform: translateY(-50%);
                background-image: url('../img/inbound/link.svg');
                background-size: cover;
                background-position: 50% 50%;
                background-color: unset;
                left: unset;                     
            }
        }

        span {
            height: 150px;
            width: 100%;
            background-position: 50% 0%;
            background-size: cover;
            background-repeat: no-repeat;
            transition: all ease .7s;
            transform: scale(1.01);
            
            @media screen and (max-width:620px){
                background-position: 0% 0%;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &:hover {

            span {
                transform: scale(1.07);
                transition: all ease .5s;
            }
        }
    }
}

p.sub {
    margin-left: 5vw;
    margin-right: 5vw;
    text-align: center;
    font-family: 'Arboria-Light',serif;
    position: relative;
    font-size: 20px;
    margin-top: 40px;
}