section.block-slider {
	
	height: 100vh;
	width: 100%;
	overflow: hidden;
	position: relative;

	@media only screen and (max-width: 1279px) {
		background: #494a52;
	}

	&.header-page {
		height: 100vh; 
	}
	   
	div[data-slides] {
	    background-repeat: no-repeat;
	    background-position: center top;

	    background-size: 100%;
		transition: background-image 1s linear;
		
		background-size: 100% 100%;
		background-position: center center;
		animation: shrink 7s infinite alternate;

		@media only screen and (max-width: 1535px) and (min-width: 1024px) {
			animation: none;
			background-size: cover !important;
		}

		@media only screen and (max-width: 1279px) {
			animation: none;
			background-size: cover !important;
		}
	
	}
	
	@keyframes shrink {
		0% {
		  background-size: 100% 100%;
		}
		100% {
		  background-size: 108% 108%;
		}
	  }
	
	.content {
		width: 50%;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		padding: 100px;
		z-index: 10;

		&.content-left {
			right: auto;
			left:0;
		}

		p {
			margin-bottom: 40px;
		}
	}

	.line {
	  bottom: 0;
	  height: 20px;
	  width: 0px;
	  background-color: white;
	  left: 0px;
	  z-index: 9999;
	}
	
	div.scroll-down {
		width: 45px;
		height: 45px;
		background: url(/dist/img/cartouche-bas.svg) no-repeat center center; 
		background-size: cover;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 9;
		transition: .3s;
		cursor: pointer;

		&:hover {
			bottom: -5px;
		}
	}

	div.super-scroll {
		width: 45px;
		height: 45px;
		background: url(/dist/img/cartouche-bas.svg) no-repeat center center; 
		background-size: cover;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 9;
		transition: .3s;
		cursor: pointer;
		z-index: 2;

		&:hover {
			bottom: -5px;
		}
	}

	div.background {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	h1 {
		color: rgba($white,.95);
		// position: relative;
		// text-transform: uppercase;
		// font-size: 9.5em;

		// font-size: 10.5vw;

		// margin: 0;
		// padding-top: 245px;
		// padding-top: 17.5vw;
		// padding-top: 28vh;
		// padding-left: 240px;
		// padding-left: 17vw;
		// float: left;
		// padding-right: 20px;

		// letter-spacing: -0.4vw;

		// @media only screen and (min-width: 1275px) and (max-width:1285px) {
		// 	padding-top: 28vh;
		// 	padding-left: 14vw;
		// }
	}

	div.content {
		padding: 40px;

		h1, h2 {
			color: $white;
			// font-family: 'Arboria-Light' , sans-serif;
			// text-transform: uppercase;
			// font-size: 2.8em;
			// font-weight: 300;
			// line-height: 1em;
			// position: relative;
			// margin-top: 0;
			// margin-bottom: 0.9em;
			
			// float: none;
			// padding: 0;
			// letter-spacing: normal;


			&.special_fr:before{
				top: -7px;
				font-size: 4.1em;
				@media only screen and (max-width: 1279px) {
					font-size: 2.9em;
				}
				@media only screen and (max-width: 567px) {
					font-size: 3em;
					padding-bottom: 0;    right: 41vw;
					position: relative;
					text-align: left;
					display: block;
					right: auto;
					max-width: 50%;
					padding-bottom: 30px;
					display: none;
				}
			}


			// @media only screen and (max-width: 1535px) {
			// 	font-size: 2.2em;
			// }
			// @media only screen and (max-width: 1279px) {
			//     font-size: 2.2em;
			// }

			// &:after {
			// 	content: '';
			// 	position: absolute;
			// 	height: 3px;
			// 	width: 130px;
			// 	background: $orange;
			// 	bottom: -20px;
			// 	left: -20px;
			// }

		}

		p {
			color: $white;
			font-weight: .9em;
			line-height: 1.5em;
			width: 480px;

			&.intro-mobile{
				display: none;
			}
		}

		@media only screen and (max-width: 1023px) {
			position: absolute;
			margin: 2em;
			transform: translate(0);
			width: 100%;
			right: auto;
			top: 35vh;
			margin-left: 20px;
			padding-top: inherit;
		}

		@media only screen and (max-width: 767px) {
			position: absolute;
			margin: 2em;
			transform: translate(0);
			width: 100%;
			right: auto;
			top: 20vh;
			margin-left: 0;
			padding-top: inherit;
		}

		@media only screen and (max-width: 639px) {
			position: static;
			margin: 2em;
			transform: translate(0,0);
			width: 100%;
			margin-left: 0;	
			padding-top: 110px;
		}

		@media only screen and (max-width: 567px) {
			// width: 95%;
			// padding-right: 50px;
			// padding-top: 100px;
			// margin-left: 20px;
			
			// width: 95%;
			// max-height: 215px;
			// overflow: hidden;

			p {
				width: 100%;

				&.intro-desktop{
					display: none;
				}
				&.intro-mobile{
					display: block;
					font-size: 14px;
					width: 300px;
				}
			}

		}		
	}

	@media only screen and (max-width: 1279px) {
		.godown {
			display: none;
		}
	}
	
}


// CORPO SLIDER CSS



.slider {

	
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
  position: relative;

  .main-corpo-line {
    position: absolute;
    top: 0px;
    left: 0px;
    letter-spacing: 1px;
    font-family: 'Arboria-Bold',sans-serif;
    font-size: 5em;
    line-height: .8em;
    text-align: right;	
  }

  h1:before,
  h2:before {
	word-break: initial;
    position: absolute;
    top: -1px;
    left: auto;
    right: 620px;
    right: 44vw;
    font-family: 'Arboria-Bold',sans-serif;
    font-size: 2.4em;
    line-height: .8em;
    text-align: right;
    content: attr(data-title);
	width: 80%;
	padding-right: 2rem;

    @media only screen and (min-width: 1650px) {
    	right: 46vw;
    }

    @media only screen and (max-width: 1535px) {
    	font-size: 2em;
    }



    @media only screen and (max-width: 1279px) {
		font-size: 1.8em;
		
    }

	@media only screen and (max-width: 1023px) {
		font-size: 1.8em;
		padding-bottom: 0;
		right: 41vw;
		position: relative;
		text-align: left;
		display: block;
		right: auto;
		max-width: 50%;
		padding-bottom: 30px;
    }

	@media only screen and (max-width: 767px) {
		font-size: 1.8em;
		padding-bottom: 0;
		right: 41vw;
		position: relative;
		text-align: left;
		display: block;
		right: auto;
		max-width: 50%;
		padding-bottom: 30px;
    }

	@media only screen and (max-width: 639px) {
		font-size: 12vw;
		padding-bottom: 0;
		right: 41vw;
		position: relative;
		text-align: left;
		display: block;
		right: auto;
		max-width: 50%;
		padding-bottom: 30px;
    }

	

    
  }
  
  @media only screen and (max-width: 1279px) {
	  
	width: 100vw;		
	height: 100vh;	

  }

 //  	@media screen and (min-width:500px) and (max-width:700px) {
	// 	height: 170vh;   
	// }

  
}

.slide_viewer {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.slide_group {
  height: 100%;
  position: relative;
  width: 100%;
}

.slide {
  display: none;
  height: 100%;
  position: absolute;
	width: 100%;
	
	@media only screen and (max-width: 1023px){
			height: 100%;
			width: auto;
			width: 100%;
			background-image: url("/sites/all/themes/shared/dist/img/slider/201027_slider-responsive.jpg") !important;
			background-size: cover;
			background-position: 50%;
			background-size: cover !important;
			background-position: 19%!important;
	}


}

.slide:first-child {
  display: block;
}


.slide:nth-of-type(2) {
  background: #F4E4CD;
}

.slide:nth-of-type(3) {
  background: #C75534;
}

.slide:nth-of-type(4) {
  background: #D1D1D4;
}

.slide_buttons {
  left: 10px;
  position: absolute;
  bottom: 0px;
  text-align: center;
}

a.slide_btn {
  color: $white;
  font-size: 42px;
  margin: 0 0.175em;
  transition: all 0.4s ease-in-out;
}

.slide_btn.active, .slide_btn:hover {
  color: $orange;
  cursor: pointer;
}



///// SCSS FOR ACTU BLOCK

section.block-slider .content {
	@media only screen and (max-width: 1535px) {
		top: 60%;
	}
	@media only screen and (max-width: 1279px) {
		right: 10%;
	}
	
}

div.stuvdays-holder {
	position: absolute;
	bottom: 20px;
	right: 20px; 
	width: 380px; 
	height: auto;
	background: rgba($black, .8);

	@media only screen and (max-width: 1535px) {
		right: auto;
		left: 20px;
	}

	@media only screen and (max-width: 1279px) {
		right: 20px;
		left: auto; 
	}

	@media only screen and (max-width: 767px) {
		position: relative;
		width:100%;
		padding: 20px;
		margin: 0 auto;
		left: auto;
		right: auto;
		background: #494a52; 
		margin-top: -40px;
	}

	img.stuvdays-logo {
		position: relative;
		left: 0;
		right: 0;
		text-align: center;
		margin: 0 auto;
		margin-top: -50px;
		display: block;
		width: 180px;
	}

	h2,h3 {
		text-align: center; 
		color: $white;
	}

	h2 {
		margin-top: 0px;
		text-transform: uppercase;
		font-size: 1.2em;
	}

	h3 {
		margin-bottom: 0;
		font-size: 1em;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: center;

		li {
			padding: 0;
			margin: 0;
			width: 110px;
			display: inline-block;
			padding: 3px;

			@media only screen and (max-width: 767px) {
				width: 30%;
			}

			img { 
				width: 100%;
			}
		}
	}

	div.stuvdays-link {
		text-align: center;
		margin: 10px 0px 15px 0px;

		a {
			display: inline-block;
			text-align: center;
			color: $white;
			background: $orange; 
			padding: 5px 20px;
			text-transform: uppercase;
			text-decoration: none;

			@media only screen and (max-width: 767px) {
				padding: 10px 40px; 
			}
		}
	}
}

div.block-actu:not(.promotion-news-block-active) { 
	
//	position: absolute;
//	bottom: 30px;
//	right: 30px;
//	width: 350px;
//	height: 100px;
//	background: white;
//	overflow: hidden;

//	@media only screen and (max-width: 639px) {
//	    position: absolute;
//	    bottom: 30px;
//	    right: 0;
//	    left: 0;
//	    margin: 0 auto;
//	    width: 86%;
//	    height: 100px;
//	    background: #fff;
//	    overflow: hidden;
//	    padding: 10px;
//
//	}

//	&:after {
//		content: '';
//		line-height: 50px;
//		font-weight: bold;
//		font-size: 15px;
//		color: $white;
//		height: 100%;
//		width: 50px;
//		background: $orange url('/sites/default/files/keyboard-right-arrow-button.svg') center center;
//		background-size: 50%;
//		background-repeat: no-repeat;
//		position: absolute;
//		top: 0;
//		right: -50px;
//		transition: .3s;
//	}
//
//
//
//	&:hover {
//		.block-actu-img {
//			margin-left: -50px;
//			transition-delay: .1s;
//		}
//
//		&:after {
//			right: 0;
//		}
//
//		.arrow, img {
//			right: 12px;
//		}
//	}

	.arrow, img {
		width: 25px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: -30px;
		transition: .3s;
		z-index: 3;

		&.stuvday-img{
			width: 180px;
			display: inline-block;
			position: relative;
			transform: translate(0px , 10px);
			left: 0;
			@media only screen and (max-width: 567px) {
				width: 100px;
			}

		}
	}

	ul {
		list-style: none;

	}

	div {
		float: left;
		width: 230px;

		@media only screen and (max-width: 567px) {
			padding-left: 10%;
			width: 150px;
		}

		h3 {
		padding: 20px 10px 10px 0;
		margin: 0;
		color: $hard-grey;
		text-transform: uppercase;
		font-size: 0.8em;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    overflow: hidden;

        @media only screen and (max-width: 1535px) {
					width: 160px;
        }
        
        // @media only screen and (max-width: 1279px) {
        //     padding-top: 0;
        // }
	}

	.block_news_intro{
		color: #494a52;
    transition: .3s;
    padding-right: 40px;
    position: inherit;
    bottom: 0;
    padding-top: 25px;
	}
	p {
		color: $hard-grey;
		//padding-left: 20px;
		float: left;
		transition: .3s;
		padding-right: 50px;
		font-size: .93em;

		&.block_news_intro-stuvday {
			color: $orange;
		}
	}
}
    
   h4 {
        color: $hard-grey;
        text-decoration: none;
        text-transform: uppercase;
        padding: 10px 20px;
        border: 3px solid #ea6645;
        //background: rgba(29,29,29,.4);
        position: absolute;
        bottom: 30px;
        margin: 0;
        left: 380px;
        font-size: .9em;
        transition: .3s ease;
       
       @media only screen and (max-width: 1279px) {
           left: 250px;
       }
       
       &:hover:after {
           right: -60px;
       }
        
        &:after {
            content: "\25B6";
            color: #ea6645;
            background: #ea6645;
            height: 3px;
            right: -50px;
            width: 60px;
            text-align: right;
            display: block;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            line-height: 5px;
            font-size: 12px;
            letter-spacing: -5px;
            transition: .3s;
        }
    }
    
	p {
		margin: 0;
		color: rgba($hard-grey, 1);
        font-weight: bold;
        font-size: 20px;
	}
	
	.nopic {
		
		h3 {
			padding-top: 15px;
		}
		
		h3, p {
			margin-left: 1em;
		}
	}
}

div.padded-content {
    padding: 30px 30px 30px 0px;
    height: 100%;
}

div.block-actu-img {
	height: 100%;
	width: 200px;
	margin-right: 20px;
	overflow: hidden;
	float: left;
	transition: .3s;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
    margin-left: 160px;
    
    @media only screen and (max-width: 1279px) {
        margin-left: 30px;
	}
	
	&.img-news{
		height: 100%;
		width: 100%;
		transition: .3s;
		background-position: 10% 20%;
		background-repeat: no-repeat;
		background-size: cover;
		margin-left: 0;
	}
}

.interSlider {
	height: 100%;
	background: white; 
    margin: 0;
    padding: 0;
}

.interSlider li {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; 
  background: white;
}

  .interSlider li .progressBar {
    position: absolute;
    top: 0;
    left: 0;
    background: $orange;
    height: 5px;
    width: 0;
    opacity: 1; }
// .interSlider.loading {
//   background: green; }


@media only screen and (max-width: 1023px){
	body div.video-bg.video-bg_home{
		display: none;
	}
}

#swiper4677{
	@media only screen and (max-width: 567px){
		.swiper-wrapper{
			max-height: 250px;

			.swiper-slide{
				max-height: 250px;
				min-height: 250px;
			}
		}
	}
}