table {
	border-color: #f26745;
    thead {
        background-color: #171717;
        color: white;

        tr {
            th {
                border-right-color: white;
                border-bottom: 0;
            }

            th:last-child {
                border-right-color: inherit;
            }
        }
    }
    
    th, td {
        padding: 10px;
    }
    
}

