@font-face {
    font-family: 'ABC-Diatype';
    src: url('../fonts/ABCDiatype-Regular.eot');
    src: url('../fonts/ABCDiatype-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$breakpoint-xsmall: 450px;
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$breakpoint-desktop-second: 1024px;
$breakpoint-large: 1280px;
$breakpoint-xlarge: 1400px;

/** Colors **/
$gris-stuv: #2B2E2D;
$gris-clair: #D5D5D5;
$gris-moyen: #A6A7A7;
$gris-fonce: #707070;
$blanc-stuv: #F4F4E9;

/** Sizes **/
$header-height-mobile: 90px;
$header-height-desktop: 115px;

/** Fonts **/
$font_ABC: 'ABC-Diatype', Helvetica, sans-serif;

/** Misc **/
$background_overlay_black: linear-gradient(90deg, rgba(0, 0, 0, .42) 20%, transparent);

%text-reset {
    font-size: unset;
    line-height: unset;
    font-weight: unset;
    margin: unset;
    color: $gris-stuv;

    &:after {
        content: none;
    }
}

%margin-x-reset {
    margin-left: unset;
    margin-right: unset;
}

%margin-y-reset {
    margin-top: unset;
    margin-bottom: unset;
}

%margin-reset {
    margin: unset;
}

%padding-x-reset {
    padding-left: unset;
    padding-right: unset;
}

%padding-y-reset {
    padding-top: unset;
    padding-bottom: unset;
}

%padding-reset {
    padding: unset;
}

body.new-design {
    background-color: $blanc-stuv;

    main, header, footer {
        font-family: $font_ABC;
        h1, h2, h3, p, a, ul, li {
            font-family: $font_ABC;
        }
    }

    h1, h2 {
        @extend %text-reset;
    }

    h1 {
        font-weight: 600;
        font-size: 5rem;
    }

    h2 {
        text-transform: none;
        font-size: 2.5rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    .cta {
        font-size: 1rem;
        font-weight: 100;
        padding: 15px 20px;
        padding-bottom: 12px;
        width: fit-content;
        text-transform: uppercase;
        transition: all .2s ease-in-out;
        min-width: fit-content;

        &--light {
            color: $gris-stuv !important;
            background-color: $blanc-stuv;
            border: 1px solid $gris-stuv;

            &:hover {
                background-color: #EFEFE9;
            }
        }

        &--dark {
            color: $blanc-stuv !important;
            background-color: $gris-stuv;
            border: 1px solid $blanc-stuv;

            &:hover {
                background-color: #464847;
            }
        }
    }

    .cta-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 0;
    }

    a.store {
        height: 44px;
        width: 150px;
        display: block;
        font-size: 0px;
        background-repeat: no-repeat;
        
        &-apple {
            background-image: url('../img/stores/app-store.svg');
        }
        
        &-google {
            background-image: url('../img/stores/google-play.svg');

        }
    }

    main {
        padding-top: $header-height-mobile;
        
        @media (min-width: $breakpoint-large) {
            padding-top: $header-height-desktop;
        }

        p {
            margin-top: unset;

            &:last-child {
                margin-bottom: 0;
            }

            &.cta-container {
                margin-bottom: 0;
            }
        }

        ul {
            @extend %margin-y-reset;

        }

        hr {
            margin: 0 2rem;
            height: 1px;
            background: $gris-stuv;
            border: none;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;

            &.cover {
                object-fit: cover;
            }

            &.height-300 {
                height: 300px;
            }
        }

        .swiper-slide {
            cursor: pointer;
            height: auto;
            opacity: .7;
            transition: opacity .3s ease-out;

            &-visible {
                &:hover {
                    opacity: .85;
                }
            }

            &-active {
                opacity: 1;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .slide-scroll-container {
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transition: transform 0.5s ease-out;

                &.slide-2 {
                    transform: translateY(100%);
                }
            }
        }

        section {
            overflow: hidden;

            &.light {
                background-color: $blanc-stuv;
            }

            &.dark {
                background-color: $gris-stuv;
            }

            &.full {
                height: calc(100vh - $header-height-mobile);
                margin-bottom: 2rem;
            
                @media (min-width: $breakpoint-large) {
                    height: calc(100vh - $header-height-desktop);
                }
            
                .container {
                    width: 100%;
                    height: inherit;
                    max-width: unset;
                    display: flex;
            
                    @media (min-width: $breakpoint-desktop) {
                        // display: grid;
                        // grid-template-columns: 1fr 2fr;
                    }
                }

                .logo-product-container {
                    display: flex;
                    height: inherit;
                    padding: 2rem;
                    width: 100%;
                    position: absolute;
                    flex-direction: column;
                    align-items: flex-start;
                    z-index: 10;
                    justify-content: space-between;
                    background: linear-gradient(270deg, transparent, hsla(0, 0%, 10%, .6));
                    

                    @media (min-width: $breakpoint-desktop) {
                        min-width: 640px;
                    }

                    .logo-product {
                        text-transform: none;
                        color: $blanc-stuv;
                        font-size: 15vw;

                        @media (min-width: $breakpoint-desktop) {
                            font-size: 5rem;
                        }

                        .product-black {
                            display: none;

                            @media (min-width: $breakpoint-desktop) {
                                display: block;
                            }
                        }

                        .product-white {
                            display: block;

                            @media (min-width: $breakpoint-desktop) {
                                display: none;
                            }
                        }

                        img {
                            object-fit: contain;
                            width: 100%;
                            height: 100%;
                        }
                    }

                }

                .content {
                    position: relative;
                    height: inherit;
                    width: 100%;

                    @media (min-width: $breakpoint-desktop) {}

                    picture {
                        height: inherit;
                        object-fit: contain;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                    
                        img {
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                        }
                    }

                    .video-bg {
                        height: 100%;
                        
                        @media (min-width: $breakpoint-desktop) {
                            height: 100%;
                        }
                    }

                    video {
                        z-index: 1;
                        position: absolute;
                        height: inherit;
                        object-fit: contain;

                        @media (min-width: $breakpoint-desktop) {
                            height: 100%;
                            object-fit: cover;
                        }

                        &[poster] {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .textual {
                        position: relative;
                        height: inherit;
                        z-index: 2;
                        display: flex;
                        flex-direction: column;
                        justify-content: end;
                        padding: 2rem;
                    }
                }
            }

            &.contained {
                padding: 2rem;
                display: flex;
                flex-direction: column-reverse;
                height: auto;
                gap: 2rem;

                @media (min-width: $breakpoint-large) {
                    --current-height: 700px;
                    flex-direction: row;
                    height: var(--current-height);
                    gap: 0;
                }

                .thumb-gallery {
                    overflow: hidden;
                    width: 100%;
                    height: 600px;

                    @media (min-width: $breakpoint-large) {
                        width: 50%;
                        height: auto;
                    }

                    picture {
                        display: block;
                        width: 100%;
                        height: 100%;

                        img {
                            object-fit: cover;
                        }
                    }
                }



                .content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 2rem;
                    overflow: hidden;

                    @media (min-width: $breakpoint-large) {
                        gap: 0;
                        width: 50%;
                    }

                    .textual {
                        grid-template-rows: 1fr auto;
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;
                        // border-left: 1px solid $gris-stuv;

                        h2 {
                            grid-area: 1 / 1 / 2 / 3;
                            font-size: 2.5rem;
                            width: 100%;

                            @media (min-width: $breakpoint-large) {
                                width: 75%;
                            }
                        }

                        a {
                            grid-area: 2/1/2/3;
                        }

                        .details {
                            font-size: 75%;

                            ul {
                                &.hidden {
                                    height: 0;
                                }
                            }
                        }
                    }

                    .img-gallery {
                        height: 100%;
                        flex-shrink: 0;
                        width: 100%;

                        @media (min-width: $breakpoint-large) {
                            // width: 50%;
                        }

                        img {
                            object-fit: cover;
                        }
                    }

                    .textual,
                    .img-gallery {

                        @media (min-width: $breakpoint-large) {
                            padding-left: 2rem;
                        }
                    }
                }
            }

            &.full_screen {
                margin-bottom: 0;
                height: auto;

                @media (min-width: $breakpoint-large) {
                    max-height: 1024px;
                }

                &.one_half {
                    display: flex;
                    flex-direction: column-reverse;
                
                    @media (min-width: $breakpoint-large) {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                    }
                
                    .container-left,
                    .container-right {
                        height: inherit;
                
                
                        @media (min-width: $breakpoint-large) {
                            min-height: unset;
                        }
                
                        img {
                            object-fit: cover;
                
                            &.store {
                                max-width: 150px;
                                position: relative;
                                object-fit: contain;
                            }
                        }
                
                    }
                
                    .container-left {
                        z-index: 2;
                        position: relative;
                        min-height: 700px;
                
                        video {
                            z-index: 1;
                            position: absolute;
                
                            &[poster] {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                
                        img {
                            position: absolute;
                            object-fit: cover;
                        }
                
                        .content {
                            padding: 2rem;
                            height: fit-content;
                            z-index: 1;
                            position: relative;
                
                            .textual {
                                padding: 0;
                                display: flex;
                                flex-direction: column;
                                gap: 3rem;
                                color: $blanc-stuv;

                                @media (min-width: $breakpoint-large) {
                                    gap: 0;
                                    display: grid;
                                    grid-template-columns: repeat(3, 1fr);
                                    grid-column-gap: 0px;
                                    grid-row-gap: 0px;
                                    grid-template-rows: 1fr auto;
                                }
                
                                h2 {
                                    color: $blanc-stuv;
                                    grid-area: 1 / 1 / 2 / 3;
                                    width: 75%;
                                }
                            }
                        }
                
                    }
                
                    .container-right {
                        min-height: fit-content;
                        z-index: 1;
                    }

                    .swiper-container-halfscreen {
                        --swiper-pagination-bottom: 20px;
                        position: relative;
                        height: inherit;
                        height: 100%;
                        background-color: $gris-stuv;

                        .swiper-wrapper {

                            .swiper-slide {
                                overflow: hidden;
                                background-position: center center;
                                background-size: cover;
                                width: 100% !important;
                                transition-timing-function: ease-out;

                                img {
                                    object-fit: cover;
                                }
                            }
                        }

                        .swiper-pagination-bullet {
                            background: $gris-stuv;

                            &-active {
                                background: $blanc-stuv;
                            }
                        }

                    }
                }
            }

            &.grid_col_2 {
                picture {
                    width: 100%;
                    height: 100%;
                    display: block;
            
                    img {
                        object-fit: cover;
                    }
                }

                .content-left,
                .content-right {
                    height: calc(var(--current-height) - 4rem);
                }

                .content-left {
                    order: 1;
                }

                .content-right {
                    order: 2;
                }
            }
            
            &.grid_col_2_reverse {
                picture {
                    width: 100%;
                    height: 100%;
                    display: block;
            
                    img {
                        object-fit: cover;
                    }
                }

                .content-left,
                .content-right {
                    height: calc(var(--current-height) - 4rem);
                }

                .content-left {
                    order: 2;
                }

                .content-right {
                    order: 1;
                }
            }

            &.grid_col_3 {
                display: flex;
                flex-direction: column;

                @media (min-width: $breakpoint-large) {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                }

                picture {
                    width: 100%;
                    height: 100%;
                    display: block;

                    img {
                        object-fit: cover;
                    }
                }

                .content-left,
                .content-right {
                    height: calc(var(--current-height) - 4rem);
                }

                .content-left {
                    order: 1;
                }

                .content-right {
                    order: 2;
                }

                .colstart {
                    flex-basis: 33%;
                    flex-shrink: 0;
                    width: 100%;

                    .textual {
                        margin-left: 0;
                        padding-left: 0;
                        padding-right: 0;
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;

                        @media (min-width: $breakpoint-large) {
                            padding-right: 2rem;
                        }
                    }
                }


                .colspan-2 {
                    grid-column: span 2 / span 2;
                    width: 100%;
                    flex-basis: auto;
                    flex-shrink: 1;
                }

                .vertical-top {
                    height: 100%;

                    .slide-scroll-container {
                        height: inherit;
                    }
                }

            }

            &.grid_col_3_reverse {
                display: flex;
                flex-direction: column;

                @media (min-width: $breakpoint-large) {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                }

                picture {
                    width: 100%;
                    height: 100%;
                    display: block;

                    img {
                        object-fit: cover;
                    }
                }

                .content-left,
                .content-right {
                    height: calc(var(--current-height) - 4rem);
                }

                .content-left {
                    order: 2;
                }

                .content-right {
                    order: 1;
                    // height: 600px;

                    @media (min-width: $breakpoint-large) {
                        // height: auto;
                    }
                }

                .colstart {
                    flex-basis: 33%;
                    flex-shrink: 0;
                    width: 100%;

                    .textual {
                        margin-left: 0;
                        padding-left: 0;
                        padding-right: 0;
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;

                        @media (min-width: $breakpoint-large) {
                            padding-right: 2rem;
                            margin-left: 2rem;
                        }
                    }
                }

                .colspan-2 {
                    grid-column: span 2 / span 2;
                    width: 100%;
                    flex-basis: auto;
                    flex-shrink: 1;
                }

                .vertical-top {
                    height: 100%;

                    .slide-scroll-container {
                        height: inherit;
                    }
                }

            }

            &.full_screen.cover {
                overflow: hidden;
                position: relative;
                height: 600px;
                min-height: fit-content;

                @media (min-width: $breakpoint-large) {
                    height: calc(100vh - 90px);
                }


                &:after {
                    z-index: 2;
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    background-image: $background_overlay_black;
                    top: 0;
                    left: 0;
                }

                &>.swiper-container-fullscreen {
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    position: relative;

                    picture {
                        width: 100%;
                        height: 100%;

                        img {
                            object-fit: cover;
                            position: absolute;
                        }
                    }
                }

                &>img {
                    position: absolute;
                }


                .content {
                    position: absolute;
                    top: 0;
                    z-index: 3;
                    padding: 2rem;
                    height: fit-content;

                    .textual {
                        padding: 0;
                        // padding-left: 2rem;
                        // border-left: 1px solid #F4F4E9;
                        color: #F4F4E9;
                        width: auto;
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;

                        @media (min-width: $breakpoint-large) {
                            width: 50%;
                            display: grid;
                            grid-template-rows: 1fr auto;
                            grid-template-columns: repeat(3, 1fr);
                        }


                        h2 {
                            color: $blanc-stuv;
                            grid-area: 1 / 1 / 2 / 3;
                            font-size: 2.5rem;
                            width: 75%;
                        }
                    }
                }
            }

            &.full_text {
                height: auto;
                padding-top: 4rem;
                padding-bottom: 4rem;
            }

            &.gallery-accessoires {
                --current-height: 300px;
                gap: 2rem;
                display: flex;
                flex-direction: column;

                @media (min-width: $breakpoint-large) {
                    --current-height: 700px;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                }

                .content-left {
                    // width: 33%;
                    // flex-shrink: 0;
                    // justify-content: flex-start;
                    // gap: 2rem;
                    flex-basis: 33%;
                    flex-shrink: 0;
                    width: 100%;
                    gap: 2rem;

                    .textual {
                        padding-left: 0;
                    }

                    /*
                    .thumbs-gallery {
                        height: auto;
                        max-height: 250px;
                        min-height: 150px;
                        overflow: hidden;

                        @media (min-width: $breakpoint-large) {
                            height: 100%;
                            max-height: unset;
                            min-height: unset;
                        }

                        .swiper-wrapper {
                            flex-direction: row;

                            @media (min-width: $breakpoint-large) {
                                flex-direction: column;
                            }
                        }

                        .swiper-slide {
                            // height: 100% !important;
                            width: 50%;

                            @media (min-width: $breakpoint-large) {
                                width: auto;
                            }
                        }
                    }
                    */

                    .thumbs-gallery {
                        display: none;
                        height: 100%;
                        // height: 500px;
                        position: relative;

                        @media (min-width: $breakpoint-large) {
                            display: block;
                        }

                        .swiper-slide {
                            // height: 100% !important;
                            // width: 50%;

                            @media (min-width: $breakpoint-large) {
                                // width: auto;
                            }
                        }

                    }
                    
        
                }

                .content-right {
                    grid-column: span 2 / span 2;
                    width: 100%;
                    flex-basis: auto;
                    flex-shrink: 1;

                    @media (min-width: $breakpoint-large) {}

                    .image-gallery, .image-single {
                        overflow: hidden;
                        position: relative;

                        @media (min-width: $breakpoint-large) {
                            
                        }

                        picture {

                            @media (min-width: $breakpoint-large) {

                            }

                            img {
                                object-fit: cover;
                            }
                        }
                    }

                    .swiper-wrapper {
                        transition: all .2s ease-out;
                    }

                    &>div {
                        width: 100%;
                        height: 100%;

                        .swiper-slide {
                            display: flex;
                            flex-direction: column;
                            gap: 2rem;

                            @media (min-width: $breakpoint-large) {
                                display: grid;
                                grid-template-columns: 0.5fr 2fr;
                            }

                            h3 {
                                margin-top: 0;
                                font-size: 1.5rem;
                                color: $blanc-stuv;
                            }
                        }
                    }

                    .image-single-wrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;

                        @media (min-width: $breakpoint-large) {
                            display: grid;
                            grid-template-columns: 0.5fr 2fr;
                        }

                        h3 {
                            margin-top: 0;
                            font-size: 1.5rem;
                            color: $blanc-stuv;
                        }
                    }
                }

                .content-left,
                .content-right {
                    @media (min-width: $breakpoint-large) {
                        height: calc(var(--current-height) - 4rem);
                    }
                }

                .swiper-nav-container {
                    position: relative;
                    display: flex;
                    height: 40px;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    gap: 1rem;

                    .swiper-button-next,
                    .swiper-button-prev {
                        background: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        top: 0;
                        margin-top: 0;
    
                        &:after {
                            content: "";
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            border-right: 2px solid $blanc-stuv;
                            border-bottom: 2px solid $blanc-stuv;
                        }
                    }
    
                    .swiper-button-next {
    
                        &:after {
                            transform: rotate(-45deg);
                            // margin-left: 5px;
                        }
                    }
    
                    .swiper-button-prev {
    
                        &:after {
                            transform: rotate(135deg);
                            // margin-right: 5px;
                        }
                    }
                }

            }

            &.auto-height {
                height: fit-content;

                .content-left,
                .content-right {
                    height: fit-content;
                }
            }

            &.pos {
                .block_background {
                    position: absolute;
                }

                .infos {
                    padding-top: 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .content {
                        position: relative;
                        top: unset;
                        transform: unset;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 2rem;
                        padding-left: 2rem;
                        margin-left: 0 !important;

                        h2 {
                            font-family: $font_ABC;
                            font-size: 2.5rem;
                            font-weight: unset;
                            text-transform: none;
                            color: $gris-stuv;

                            &:after {
                                content: none;
                            }
                        }
                    }

                    .selector {
                        form {

                            &>* {
                                font-weight: unset;
                                font-family: $font_ABC;
                            }

                            .selector-block {
                                background-color: $gris-stuv;
                                padding: 2rem;

                                h3 {
                                    color: $blanc-stuv;
                                    text-transform: uppercase;
                                    text-align: center;
                                }

                                .input-field {
                                    background-color: unset;
                                    margin-bottom: 1rem;

                                    .select-wrapper {

                                        .caret {

                                            // &:after {
                                            //     content: ">";
                                            //     transform: rotate(90deg);
                                            //     position: absolute;
                                            // }
                                        }


                                    }

                                    .select-dropdown {
                                        background-color: $blanc-stuv;

                                        &.active {
                                            width: 100% !important;
                                            // opacity: .95 !important;
                                        }

                                        li {

                                            &:hover {
                                                background-color: #EFEFE9;
                                            }

                                            span {
                                                color: $gris-stuv;

                                            }
                                        }
                                    }

                                    input[type=text] {
                                        border-radius: 50px;
                                        border: 0px;
                                        background-color: #616460;
                                        color: white;
                                        font-family: $font_ABC;
                                        font-size: .8rem;

                                        &::placeholder {
                                            color: white;
                                        }

                                        &:focus {
                                            box-shadow: none;
                                        }
                                    }
                                }

                                .resume-block {
                                    margin-top: .5rem;
                                    margin-bottom: .5rem;

                                    .do-search {
                                        font-family: $font_ABC;
                                        text-transform: capitalize;
                                        color: $gris-stuv;
                                        border-radius: 50px;
                                        background-color: $blanc-stuv;
                                        bottom: unset;

                                        div {
                                            svg {
                                                fill: $gris-stuv;
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .cards {
                padding-left: 2rem;
                display: grid;
                grid-template-columns: 1fr;

                @media (min-width: $breakpoint-large) {
                    grid-template-columns: repeat(2, 1fr);
                }

                .card {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    padding-left: 2rem;
                    padding-right: 2rem;
                    gap: 2rem;
                    border-left: 1px solid $gris-stuv;
                    display: flex;
                    flex-direction: column;

                    @media (min-width: $breakpoint-large) {
                        display: grid;
                        grid-template-columns: 1fr 2fr;
                    }

                    .card-header {
                        h3 {
                            margin-top: 0;
                            font-size: 1.5rem;
                        }
                    }

                    .card-content {
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;

                        picture {
                            height: 250px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .card-content-text {}

                    }
                }
            }

            &.cards-section {}
            &.accessoires-section {}
            &.catalogue-section, 
            &.technical-section {
                picture {
                    height: 300px;
                }
            }
        }
    }
}

body.new-design.d4 {

    main {

        section {

            &:first-of-type {
                margin-bottom: 0;
            }

            &.full {
                .content {
                    picture {
                        img {
                            height: 100%;
                        }
                    }
                }
            }
            &.grid_col_2,
            &.grid_col_2_reverse,
            &.grid_col_3,
            &.grid_col_3_reverse {
    
                .content-right {
                    @media (max-width: $breakpoint-large) {
                        height: 400px;
                    }
                }
            }

            &.grid_col_2,
            &.grid_col_2_reverse {
                picture {
            
                    img {
                        object-fit: contain;
                    }
                }
            }

            &.full_screen {

                &.cover {

                    &:after {
                        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.52) 20%, transparent 40%);
                    }
                }
            }
        }

        hr {
            &:first-of-type {
                display: none;
            }
        }
    }
}