.turnaround {
  background: #000;
  cursor: move;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  //position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.turnaround .turnaround-item {
  background: no-repeat left center;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.turnaround .turnaround-item.active {
  z-index: 2;
}
.turnaround .turnaround-slider {
  background: #FFF;
  background: rgba(255, 255, 255, 0.5);
  bottom: 100px;
  position: absolute;
  z-index: 3;
  left: 15%;
  width: 75%;
  height: 2px;
  box-shadow: 0 0 10px rgba(0,0,0,0.4);
  -webkit-border-radius: 5px; 
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.turnaround .turnaround-slider .ui-slider-handle {
  background: #FFF;
  cursor: pointer;
  display: block;
  margin: -20px -35px;
  position: relative;
  outline: none;
  width: 100px;
  height: 40px;
  
  
  span.drag {

	  text-transform: uppercase;
	  display: block;
	  width: 100%;
	  text-align: center;
	  font-size: 0.75em;
	  padding-top: 1em;

	  position: relative;
	  
	  &:before, &:after {
		  position: absolute;
		  font-family: 'Pe-icon-7-stroke';
		  top: 0;
		  font-size: 3.5em;
		  line-height: 1em;
	  }
	  
	  &:before {
		  content: "\25B6";
		  left: 5px;
      top: 10px;
      font-size: 15px;
      transform: rotate(180deg);
      color: $orange;
	  }
	  
	  &:after {
		  right: 5px;
      top: 12px;
      font-size: 15px;
		  content: "\25B6";
      color: $orange;
	  }
	  
  }
  
}
.turnaround .turnaround-instruction {
  background: url("../../files/turnaround/instructions.png") no-repeat left center;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.turnaround .turnaround-loader {
  background: url("../../files/turnaround/loader.png") no-repeat center center;
  -webkit-background-size: 32px;
  -moz-background-size: 32px;
  -o-background-size: 32px;
  background-size: 32px;
  bottom: 50%;
  display: block;
  left: 50%;
  margin: -16px 0 0 -16px;
  position: absolute;
  z-index: 10;
  width: 32px;
  height: 32px;
  -webkit-animation: rotator 1s linear infinite;
  -moz-animation: rotator 1s linear infinite;
  -o-animation: rotator 1s linear infinite;
}
.turnaround .turnaround-loader {
  display: block;
}
.turnaround .turnaround-loader.hide {
  display: none;
}
@-webkit-keyframes rotator {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes rotator {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(-360deg);
  }
}
@-o-keyframes rotator {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(-360deg);
  }
}
/* !CSS3
/* -------------------------------------------------- */
.csstransitions .turnaround-instruction {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.opacity .turnaround-item {
  opacity: 0;
  filter: alpha(opacity=0);
}
.opacity .turnaround-item.active {
  opacity: 1;
  filter: alpha(opacity=100);
}
.opacity .turnaround-instruction {
  opacity: 1;
  filter: alpha(opacity=100);
}
.opacity .turnaround-instruction.hide {
  opacity: 0;
  filter: alpha(opacity=0);
}
.no-opacity .turnaround-item {
  display: none;
}
.no-opacity .turnaround-item.active {
  display: block;
}
.no-opacity .turnaround-instruction {
  display: block;
}
.no-opacity .turnaround-instruction.hide {
  display: none;
}