/* Switch
   ========================================================================== */
.materialize {

	.switch,
	.switch * {
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -khtml-user-select: none;
	  -ms-user-select: none;
	}
	
	.switch label {
	  cursor: pointer;
	}
	
	.switch label input[type=checkbox] {
	  opacity: 0;
	  width: 0;
	  height: 0;
	
	  &:checked + .lever {
	    background-color: $switch-checked-lever-bg;
	
	    &:after {
	      background-color: $switch-bg-color;
	      left: 24px;
	    }
	  }
	}
	
	.switch label .lever {
	  content: "";
	  display: inline-block;
	  position: relative;
	  width: 40px;
	  height: 15px;
	  background-color: $switch-unchecked-lever-bg;
	  border-radius: $switch-radius;
	  margin-right: 10px;
	  transition: background 0.3s ease;
	  vertical-align: middle;
	  margin: 0 16px;
	
	  &:after {
	    content: "";
	    position: absolute;
	    display: inline-block;
	    width: 21px;
	    height: 21px;
	    background-color: $switch-unchecked-bg;
	    border-radius: 21px;
	    box-shadow: 0 1px 3px 1px rgba(0,0,0,.4);
	    left: -5px;
	    top: -3px;
	    transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
	  }
	}
	
	// Switch active style
	input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after,
	input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
	  box-shadow: 0 1px 3px 1px rgba(0,0,0,.4), 0 0 0 15px transparentize($switch-bg-color, .9);
	}
	
	input[type=checkbox]:not(:disabled) ~ .lever:active:after,
	input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
	  box-shadow: 0 1px 3px 1px rgba(0,0,0,.4), 0 0 0 15px rgba(0, 0, 0, .08);
	}
	
	// Disabled Styles
	.switch input[type=checkbox][disabled] + .lever {
	  cursor: default;
	}
	
	.switch label input[type=checkbox][disabled] + .lever:after,
	.switch label input[type=checkbox][disabled]:checked + .lever:after {
	  background-color: $input-disabled-solid-color;
	}

}
