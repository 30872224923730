section.template-container {
    
    div.template-content {

        ul.inbound-list {

            div.model-3D {
                display: flex;
                margin-top: 90px;
                flex-direction: column;
                align-items: center;
            
                .inbound-template-menu, .tutorial {
                    background-color: transparent;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-shadow: none;
            
                    h3 {
                        margin-right: 0;
            
                    }
            
                    select {
                        outline: none;
                        display: inline-block;
                        float: none;
                        background: #fff;
                        border: 1px solid #ccc;
                        padding: 15px;
                        height: 3em;
                        -webkit-appearance: none;
                        appearance: none;
                        font-family: Arboria-Light,serif;
                        border-radius: 0;
                        margin-right: 0;

                        &:focus {
                            border: 0;
                            outline: 1px solid #ea6645;
                        }
                    }
                }
            
                .inbound-template-list {
                    margin-left: 10vw;
                    margin-right: 10vw;
                }

                .inbound-template-block {
                    padding: 0;
                    justify-content: start;

                    div.image {
                        width: 190px;
                        margin-left: 5%;
                        margin-right: 5%;

                        img {
                            object-fit: none;
                            width: 100%;
                            height: 100%;
                            max-width: unset;
                            margin: unset;
                        }
                    }

                    div.content {
                        padding: 20px;
                        padding-left: 0;

                        p {
                            margin-top: 0;
                        }
                    }
                }

                .tutorial {

                    div.content {
                        display: flex;
                        flex-direction: column;
                        width: 35vw;
                        padding: 0;

                        p {
                            text-align: center;
                            font-size: 1.17em;
                        }

                        ul {
                            display: flex;
                            justify-content: space-around;
                        }
                    }
                }
            }
        }
    }
}
