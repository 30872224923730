ul.inbound-contact {
    max-width: 1140px;
    padding: 0 20px;
    margin: 0 auto;
    margin-top: 70px;

    form {

        p {
            margin-left: 15px;
            font-family: 'Arboria-Bold',sans-serif;
            font-size: 20px;
        }

        h3 {
            margin-left: 15px;
            font-family: 'Arboria-Light',sans-serif;
            font-size: 1.4em;
            margin-bottom: 0.7em;
        }

        label {
            display: inline;
            font-weight: normal;
            font-family: 'Arboria-book', serif;
            vertical-align: top;

            &.label-gdpr{
                // margin-left: 5px;

                a{
                    text-decoration: none;
                    color: #ea6645;
                    vertical-align: top;
                }
            }

        } 

        input[type=email], input[type=text], input[type=textarea] {
            background-color: transparent;
            border: 1px solid #CCC;
            border-radius: 0;
            font-family: 'Arboria-Light', serif;
            outline: none;
            width: calc((100% / 3.03) - 30px);
            margin: 15px;
            padding: 15px 20px;
            transition: ease .3s;
            background: white;
            box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.05);
            
            &.half {
                width: calc(50% - 30px);
                display: inline-block;
            }

            &:focus {
                border: 1px solid #ea6645;
            }
            
            @media screen and (max-width:1558px){
                width: calc(49% - 30px);
            }
            
            @media screen and (max-width:620px){
                width: calc(100% - 30px);
            }
            
        }

        input[type=textarea], textarea {
            outline: none;
            width: calc(100% - 31px);
            min-height: 150px;
            padding-bottom: 80px;
            margin: 15px;
            margin-bottom: 0;
            padding: 15px;
            border: 1px solid #CCC;
            font-family: 'Arboria-Light', serif;
            box-shadow: 0 0 50px 0 rgba(0,0,0,.05);
            
            &:focus {
                border: 0;
                outline: 1px solid #ea6645;
            }

        }

        input[type="checkbox"] {
            //opacity: 0;
            appearance: none;
            background: white;
            border: 2px solid $orange;
            margin-left: 15px;
            margin-right: 5px;
            
            cursor: pointer;
            
            width: 18px;
            height: 18px;
            outline: none;
            
            &:checked {
                
                   background-color: $orange;
                   position: relative;
                   
                   &:before { 
                       content: "";
                       display: block;
                    position: absolute;
                    top: 0;
                    left: auto;
                    right: 8px;
                    width: 8px;
                    height: 13px;
                    border-top: 2px solid transparent;
                    border-left: 2px solid transparent;
                    border-right: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    transform: rotate(37deg);
                    transform-origin: 100% 100%;
                   }
                   
            }
            
            & + label {
                width: calc(100% - 50px);
                display: inline-block;
                @media screen and (max-width:500px){
                    position: inherit;
                }
            }
        }
        
        select {
            outline: none;
            display: inline-block;
            float: none;
            width: calc(100% - 30px);
            width: calc(33.0033% - 30px);
            background: white;
            border: 1px solid #CCC;
            padding: 15px;
            margin: 0px 15px 15px 15px;
            height: 3em;
            appearance: none;
            font-family: 'Arboria-Light', serif;
            border-radius: 0;

            &:focus {
                border: 0;
                outline: 1px solid #ea6645;
            }


            @media screen and (max-width:620px){
                width: calc(100% - 30px);
                margin-top: 20px;
            }
            
            @media screen and (min-width:621px) and (max-width: 1558px){
                margin-top: 20px;
                width: calc(49% - 30px);
            }

            @media screen and (min-width:1559px){
                margin-top: 0;
                width: calc(33.00330033% - 30px);
            }
            
            &.half {
                width: calc(50% - 35px);
                display: inline-block;
            }
        }

        span {
            display: block;
            width: 100%;
            text-align: center;
            margin-top: 3em;
             @media screen and (max-width:768px){
                margin-top: 6em;
            }
            @media screen and (max-width:415px){
                // margin-top: 10em;
            }
        }

        input[type=submit] {
            clear: both;
            float: none;
            display: inline-block;
            font-size: 20px;
            text-align: center;
            padding: 15px 45px;
            text-transform: uppercase;
            background: #ea6645;
            border: 1px solid #ea6645;
            font-family: 'Arboria-Bold', serif;
            color: #fff;
            transition: .3s ease;
            text-align: center;
            margin-top: 40px;
        }

        .required-form {
            font-size: 10px;
            margin-bottom: 15px;
        }
    }

    .cta_download-pdf {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        float: left;
        margin-bottom: 2em;
        /* height: 25vw; */

        a {
            width: inherit;
            display: contents;

            li.inbound-list-block {
                height: 240px !important;
                display: flex !important;
                overflow: unset;

                span {

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    li.inbound-list-block {
        background-color: white;
        list-style: none;
        padding: 10px;
        width: calc((100% / 3) - 30px);
        min-height: 200px;
        height: 20vh;
        float: left;
        margin: 15px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.06);
        @media screen and (max-width:940px){
            width: calc((100% / 2) - 30px);            
        }
        @media screen and (max-width:620px){
            width: calc(100% - 30px);
            height: 30vh;
              
        }

        h2 {
            background: #f9f9f9 !important;
            text-align: center;
            text-transform: uppercase;
            opacity: 1;
            color: rgba(black, .75);
            font-size: 17px;
        }
        
        img.badge {
            width: 60px;
            position: absolute;
            top: 10px;
            right: 15px;
        }

        ul {
            
            margin: 0;
            padding: 0 40px;
            list-style: none;

            li {
                
                font-size: 18px;
                margin-bottom: 0.2em;
                position: relative;
                font-family: 'Arboria-Light';
                
                @media screen and (max-height:1035px){
                    font-size: 16px; 
                }
                
                @media screen and (max-height:955px){
                    font-size: 15px; 
                }
                
                a {
                    
                    text-decoration: inherit;
                    color: inherit;
                    transition: .3s ease;
                    cursor: pointer;
                    position: relative;
                    left: 0;
                    
                    font-family: 'Arboria-Light';

                    &:hover {
                        color: #ea6645;
                        left: 5px;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    height: 10px;
                    width: 10px;
                    background-image: url('../img/inbound/arrow.svg');
                    background-size: cover;
                    background-position: 50% 50%;
                    left: -15px;
                    top: 5px;
                }
            }
        }

        span {

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .inbound-gallery-block {
        flex-wrap: wrap;
        justify-content: flex-start !important;
        padding: 0 !important;
        margin-left: 10vw !important;
        margin-right: 10vw !important;

        h3 {
            width: 100% !important;
            text-align: center;
            padding: 0;

            @media screen and (min-width: 415px) {
                width: 40% !important;
                text-align: left;
                padding-left: 20px;
            }
        }

        a {
            display: flex;
            width: 100% !important;

            @media screen and (min-width: 415px) {
                width: 60% !important;
            }
        }
    }
}