section.promotion-news-block-active {

	div.content-holder {
		background: white;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 225px;
		
		@media only screen and (max-width: 1279px) {
			height: 180px;
		}
		
		@media only screen and (max-width: 1023px) {
			height: 450px;
		}
		
		@media only screen and (max-width: 639px) {
			height:  120px;
		}
		
		div.promotion {
			height: 100%;
			width: 50%;
			float: right;
			position: relative;
			
			@media only screen and (max-width: 1023px) {
				float: none;
				width: 100%;
				height: 50%;
			}
			
			@media only screen and (max-width: 639px) {
				height: 100%;
			}
			
			a.promotion-type {
				display: block;
			}
			
			div.promotion-type,
			a.promotion-type {
				position: absolute;
				left:0;
				width: 50%;
				height: 100%;
				text-align: center;
				
				&.as-background {
					
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					
					/* @media only screen and (max-width: 1279px) {
						 background-size: cover;
					} */
				}
				
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%) rotate(45deg);
					right: -10px;
					height: 20px;
					width: 20px;
					background: white;
					z-index: 9;
				}
				
				img {
					height: 100%;
					width: 100%;
					text-align: center;
				}
			}
			
			div.promotion-date {
				position: absolute;
				right: 0;
				width: 50%;
				height: 100%;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				
				div.promotion-date-content {
					position: relative;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					right: 0;
					margin: 0 auto;
					background: white;
					padding: 15px;
					width: 50%;
					
					transition: 0.4s;
					
					/*@media only screen and (max-width: 1630px) {
						width: 70%;
					}
					
					@media only screen and (max-width: 1120px) {
						width: 85%;
					}*/
					
					a {
						text-decoration: none;
					}
					
					p {
						padding: 0;
						margin: 0;
						color: #494a52;
						text-transform: uppercase;
						font-size: .9em;
						font-weight: 600;
						text-align: center;
						
						span {
							color: $orange;
						}
						
						@media only screen and (max-width: 639px) {
							font-size: 0;
							span {
								font-size: 2rem;    
							}
						}
					}
					
					&:hover {
						background: #3b1109;
						p {
							color: white;
						}                       
					}
				}
			}
		}
		
		div.block-actu {
			height: 100%;
			width: 50%;
			float: left;
			background: #efefef;
			position: relative;
			bottom: 0;
			right: 0;
			
			background: #E0E0E0;
			
			@media only screen and (max-width: 1023px) {
				float: none;
				width: 100%;
				height: 50%;
			}
			
			@media only screen and (max-width: 639px) {
				display: none;
			}
			
			h2 {
				margin: 0;
				padding: 30px;
				position: absolute;
				font-family: Arboria-Light,sans-serif;
				text-transform: uppercase;
				font-size: 2.2em;
				font-weight: 300;
				line-height: 1em;
				letter-spacing: normal;
				
				@media only screen and (max-width: 1279px) {
					display: none;
				}
				
				&:after {
					content: "";
					position: absolute;
					height: 3px;
					width: 110px;
					background: #ea6645;
					bottom: 15px;
					right: 30px;
				}
			}
			
			ul.interSlider {
				background: none;
				padding-left: 0;
				
				a {
					
					li {
						background: none;
					}
				}
			}
		}
	}

	&.block-slider {
	
	height: 100vh;
	width: 100%;
	overflow: hidden;
	position: relative;

	@media only screen and (max-width: 1279px) {
		background: #494a52;
	}

	&.header-page {
		height: 100vh; 
	}
	   
	div[data-slides] {
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		-webkit-transition: background-image 1s linear;
		transition: background-image 1s linear;
	}
	
	.content {
		width: 50%;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		padding: 100px;

		&.content-left {
			right: auto;
			left:0;
		}

		p {
			margin-bottom: 40px;
		}
	}

	.line {
	  bottom: 0;
	  height: 20px;
	  width: 0px;
	  background-color: white;
	  left: 0px;
	  z-index: 9999;
	}
	
	div.scroll-down {
		width: 45px;
		height: 45px;
		background: url(/dist/img/cartouche-bas.svg) no-repeat center center; 
		background-size: cover;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 9;
		transition: .3s;
		cursor: pointer;

		&:hover {
			bottom: -5px;
		}
	}

	div.super-scroll {
		width: 45px;
		height: 45px;
		background: url(/dist/img/cartouche-bas.svg) no-repeat center center; 
		background-size: cover;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 9;
		transition: .3s;
		cursor: pointer;
		z-index: 2;

		&:hover {
			bottom: -5px;
		}
	}

	div.background {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	h1 {
		color: rgba($white,.95);
		// position: relative;
		// text-transform: uppercase;
		// font-size: 9.5em;

		// font-size: 10.5vw;

		// margin: 0;
		// padding-top: 245px;
		// padding-top: 17.5vw;
		// padding-top: 28vh;
		// padding-left: 240px;
		// padding-left: 17vw;
		// float: left;
		// padding-right: 20px;

		// letter-spacing: -0.4vw;

		// @media only screen and (min-width: 1275px) and (max-width:1285px) {
		// 	padding-top: 28vh;
		// 	padding-left: 14vw;
		// }
	}

	div.content {
		
		h1, h2 {
			color: $white;
			// font-family: 'Arboria-Light' , sans-serif;
			// text-transform: uppercase;
			// font-size: 2.8em;
			// font-weight: 300;
			// line-height: 1em;
			// position: relative;
			// margin-top: 0;
			// margin-bottom: 0.9em;
			
			// float: none;
			// padding: 0;
			// letter-spacing: normal;


			// @media only screen and (max-width: 1535px) {
			// 	font-size: 2.2em;
			// }

			// &:after {
			// 	content: '';
			// 	position: absolute;
			// 	height: 3px;
			// 	width: 130px;
			// 	background: $orange;
			// 	bottom: -20px;
			// 	left: -20px;
			// }

		}

		p {
			color: $white;
			font-weight: .9em;
			line-height: 1.5em;
			width: 480px;
		}
		
		@media only screen and (max-width: 1279px) {

			position: static;
			margin: 2em;
			transform: translate(0,0);
			width: 100%;
			
		}

		@media only screen and (max-width: 567px) {
			width: 100%;
			padding-right: 80px;
			padding-top: 50px;

			p {
				width: 100%;
			}
		}
		
	}

	&.relative {
		
	}
	
	@media only screen and (max-width: 1279px) {
		.godown {
			display: none;
		}
	}
	
}

// CORPO SLIDER CSS



.slider {

	
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
  position: relative;

  .main-corpo-line {
	position: absolute;
	top: 0px;
	left: 0px;
	letter-spacing: 1px;
	font-family: 'Arboria-Bold',sans-serif;
	font-size: 5em;
	line-height: .8em;
	text-align: right;  
  }

  h1:before,
  h2:before {
	position: absolute;
	top: -1px;
	left: auto;
	right: 620px;
	right: 44vw;
	font-family: 'Arboria-Bold',sans-serif;
	font-size: 2.4em;
	line-height: .8em;
	text-align: right;
	content: attr(data-title);

	@media only screen and (min-width: 1650px) {
		right: 46vw;
	}

	@media only screen and (max-width: 1535px) {
		font-size: 2em;
	}

	@media only screen and (max-width: 1279px) {
		right: 41vw;
	}

	@media only screen and (max-width: 1279px) {
		position: relative;
		text-align: left;
		display: block;
		right: auto;
		max-width: 50%;
		padding-bottom: 30px;
	}
	@media only screen and (max-width: 567px) {
		font-size: 13.5vw;
	}
  }
  
  @media only screen and (max-width: 1279px) {
	  
	width: 100vw;       
	height: 100vh;  

  }

	@media screen and (min-width:500px) and (max-width:700px) {
		height: 170vh;  
}

.slide_viewer {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.slide_group {
  height: 100%;
  position: relative;
  width: 100%;
}

.slide {
  display: none;
  height: 100%;
  position: absolute;
  width: 100%;
}

.slide:first-child {
  display: block;
}


.slide:nth-of-type(2) {
  background: #F4E4CD;
}

.slide:nth-of-type(3) {
  background: #C75534;
}

.slide:nth-of-type(4) {
  background: #D1D1D4;
}

.slide_buttons {
  left: 10px;
  position: absolute;
  bottom: 0px;
  text-align: center;
}

a.slide_btn {
  color: $white;
  font-size: 42px;
  margin: 0 0.175em;
  transition: all 0.4s ease-in-out;
}

.slide_btn.active, .slide_btn:hover {
  color: $orange;
  cursor: pointer;
}



///// SCSS FOR ACTU BLOCK

&.block-slider .content {
	@media only screen and (max-width: 1535px) {
		top: 40%;
	}
}

div.stuvdays-holder {
	position: absolute;
	bottom: 20px;
	right: 20px; 
	width: 380px; 
	height: auto;
	background: rgba($black, .8);

	@media only screen and (max-width: 1535px) {
		right: auto;
		left: 20px;
	}

	@media only screen and (max-width: 1279px) {
		right: 20px;
		left: auto; 
	}

	@media only screen and (max-width: 767px) {
		position: relative;
		width:100%;
		padding: 20px;
		margin: 0 auto;
		left: auto;
		right: auto;
		background: #494a52; 
		margin-top: -40px;
	}

	img.stuvdays-logo {
		position: relative;
		left: 0;
		right: 0;
		text-align: center;
		margin: 0 auto;
		margin-top: -50px;
		display: block;
		width: 180px;
	}

	h2,h3 {
		text-align: center; 
		color: $white;
	}

	h2 {
		margin-top: 0px;
		text-transform: uppercase;
		font-size: 1.2em;
	}

	h3 {
		margin-bottom: 0;
		font-size: 1em;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: center;

		li {
			padding: 0;
			margin: 0;
			width: 110px;
			display: inline-block;
			padding: 3px;

			@media only screen and (max-width: 767px) {
				width: 30%;
			}

			img { 
				width: 100%;
			}
		}
	}

	div.stuvdays-link {
		text-align: center;
		margin: 10px 0px 15px 0px;

		a {
			display: inline-block;
			text-align: center;
			color: $white;
			background: $orange; 
			padding: 5px 20px;
			text-transform: uppercase;
			text-decoration: none;

			@media only screen and (max-width: 767px) {
				padding: 10px 40px; 
			}
		}
	}
}

div.block-actu {
	
//  position: absolute;
//  bottom: 30px;
//  right: 30px;
//  width: 350px;
//  height: 100px;
//  background: white;
//  overflow: hidden;

//  @media only screen and (max-width: 639px) {
//      position: absolute;
//      bottom: 30px;
//      right: 0;
//      left: 0;
//      margin: 0 auto;
//      width: 86%;
//      height: 100px;
//      background: #fff;
//      overflow: hidden;
//      padding: 10px;
//
//  }

//  &:after {
//      content: '';
//      line-height: 50px;
//      font-weight: bold;
//      font-size: 15px;
//      color: $white;
//      height: 100%;
//      width: 50px;
//      background: $orange url('/sites/default/files/keyboard-right-arrow-button.svg') center center;
//      background-size: 50%;
//      background-repeat: no-repeat;
//      position: absolute;
//      top: 0;
//      right: -50px;
//      transition: .3s;
//  }
//
//
//
//  &:hover {
//      .block-actu-img {
//          margin-left: -50px;
//          transition-delay: .1s;
//      }
//
//      &:after {
//          right: 0;
//      }
//
//      .arrow, img {
//          right: 12px;
//      }
//  }

	.arrow, img {
		width: 25px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: -30px;
		transition: .3s;
		z-index: 3;

	}

	ul {
		list-style: none;
	}

	h3 {
		padding: 20px 0 10px 0;
		margin: 0;
		color: $hard-grey;
		text-transform: uppercase;
		font-size: 0.9em;
		width: 20vw; 
		float: left;
		//padding-left: 20px;

		@media only screen and (max-width: 1535px) {
			width: 220px;
		}
		
		@media only screen and (max-width: 1279px) {
			padding-top: 0;
		}
	}


	p {
		color: $hard-grey;
		//padding-left: 20px;
		float: left;
		transition: .3s;
		padding-right: 50px;

		// &:hover {
		//  color: $orange;
		// }

	}
	
   h4 {
		color: $hard-grey;
		text-decoration: none;
		text-transform: uppercase;
		padding: 10px 20px;
		border: 3px solid #ea6645;
		//background: rgba(29,29,29,.4);
		position: absolute;
		bottom: 30px;
		margin: 0;
		left: 380px;
		font-size: .9em;
		transition: .3s ease;
	   
	   @media only screen and (max-width: 1535px) {
		   left: 26.8vw;
	   }
	   
	   @media only screen and (max-width: 1279px) {
		   left: 250px;
		   border: none;
	   }
	   
	   @media only screen and (max-width: 1023px) {
		   left: 67vw;
	   }
	   
	   &:hover:after {
		   right: -60px;
	   }
		
		&:after {
			content: "\25B6";
			color: #ea6645;
			background: #ea6645;
			height: 3px;
			right: -50px;
			width: 60px;
			text-align: right;
			display: block;
			position: absolute;
			top: 50%;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			line-height: 5px;
			font-size: 12px;
			letter-spacing: -5px;
			transition: .3s;
		}
	}
	
	p {
		margin: 0;
		color: rgba($hard-grey, 1);
		font-weight: bold;
		font-size: 20px;
		width: 20vw;
	}
	
	.nopic {
		
		h3 {
			padding-top: 15px;
		}
		
		h3, p {
			margin-left: 1em;
		}
	}
}

div.padded-content {
	padding: 30px 30px 30px 0px;
	height: 100%;
}

div.block-actu-img {
	height: 100%;
	width: 200px;
	margin-right: 20px;
	overflow: hidden;
	float: left;
	transition: .3s;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	margin-left: 160px;
	
	@media only screen and (max-width: 1535px) {
		width: calc(30% - 20px);
	}
	
	@media only screen and (max-width: 1279px) {
		margin-left: 30px;
		width: calc(45% - 20px);
	}
}
}} 
// FIXED 

.interSlider {
	height: 100%;
	background: white; 
	margin: 0;
	padding: 0;
}

.interSlider li {
	  position: relative;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  overflow: hidden; 
	  background: white;
	  display: none;
	  h3{
			position: relative;
	  	&:after{
			position: absolute;
			content: "";
			height: 2.5px;
			width: 20%;
			background-color: #ea6645;
			bottom: 0;
			left: -10px;
		}

		}
	  a{
		text-decoration: none;
		color: #000;
	  }
}



.progressBar {
	position: absolute;
	top: 0;
	left: 0;
	background: $orange;
	height: 5px;
	width: 0;
	opacity: 1;
	z-index: 100;
}
.right-layout-stuvday-link{
    left: 350px;
    left: 0px;
    position: absolute;
    display: inline-block;
	width: 350px;

	
		
		@media only screen and (max-width: 567px) {
			left: 0;
			position: relative;
			width: 100%;
		}
}
div.block-actu {
	position: relative;
	display: none;
	&.block-actu-stuvday{
		display: block;
		left: 0;
		
	}
	@media only screen and (max-width: 567px) {
			display: none;
	}
	
	
	&.right-layout {
		position: absolute;
		bottom: 0;
		left: 0;
		background: #fff;
		overflow: hidden;       
		z-index: 100;
	
  
		&.right-layout-stuvday{
			transition: .3s ease-in;
			@media only screen and (max-width: 567px) {
				display: block;
				position: fixed;
				height: 51px;
				transform: rotate(-90deg);
				right: -59px;
				left: auto;
				bottom: 119px;	
			}
			&:after{
				position: absolute;
				content: "";
				right: 0;
				height: 100%;
				background-color: #ea6645;
				top: 0;
				width: 20px;
				display: inline-block;
				transition: .3s ease-in;
			}

			&:hover{
				&:after{
					width: 25px;
				}
			}

			i {
				position: absolute;
				right: 5px;
				top: 50%;
				color: white;
				z-index: 10;
				font-size: 1.7em;
				transform: translateY(-50%);
				transition: .3s ease-in;
				@media only screen and (max-width: 567px) {
					top: 41%;
				}
			}

			&.right-layout-stuvday_product{
				transition: .3s ease,width .5s;
				height: 100px;
				left: 0;
				z-index: 1000;
				top: auto;
				bottom: 0;


				@media only screen and (max-width: 567px){
					position: fixed;
					bottom: 72px;
					height: 55px;
					width: 150px;
					/* right: 0; */
					transform: rotate(-90deg);
					/* right: -59px; */
					left: auto;
					bottom: 179px;
					right: -59px;
				}


				&:hover{
					box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
				}
			}
			
		}
		&.stuvdays-block{
			transform: translateY(50%);
			bottom: 50%;

			ul li{
				i{
					padding: 10px;
				}
			}
		}
		
		div.block-actu-img {
			height: 100px;
			width: 100px;
			margin-right: 20px;
			margin-left: 0;
			overflow: hidden;
			float: left;
			transition: .3s;
			background-position: 50%;
			background-repeat: no-repeat;
			background-size: cover;
			
			@media only screen and (max-width: 567px){
				display: none;	
			}
		}
		
		p {
			transition: .3s;
			padding-right: 50px;
			margin: 0;
			color: rgba(73,74,82,.6);
			font-size: .7em;
			position: absolute;
			left: 120px;
			bottom: 10px;
		}
		
		ul.pagination{
			margin: 0;
			position: absolute;
			left: 21%;
			bottom: 10%;
			li{
				height: 4px;
				width: 4px;
				background-color: $orange;
				color: $orange;
				border-radius: 20px;
				display: inline-block;
				margin-left: 8px;
				opacity: .5;

				&.active{
					opacity: 1;
				}
			}
		} 
	}
}
.right-layout.right-layout-stuvday.block-actu-stuvday{
	display: block;
	left: 0;
	position: absolute;
	bottom: 0;
	width: 290px;
	height: 150px;
	bottom: 20px;
	left: 50px;
	background-color: #ffffffcb;

}
// .corporate {
// 	div.block-actu.right-layout.right-layout-stuvday.right-layout-stuvday_product{
// 		@media only screen and (max-width: 567px) {
// 		position: absolute;
// 		}
// 	}
// }
// div.block-actu:last-of-type{
// 	left: 350px;
// 		@media only screen and (max-width: 1535px) {
// 			left: 340px;
// 		}
// }

.inspirations{
	overflow: hidden;
	display: flex;
	height: auto;
	min-height: 370px;
	flex-wrap: wrap;
	background-color: #494a52;

	@media only screen and (min-width: 568px){
		height: 450px;
		min-height: 450px;
	}


	.slider-inspiration{
		display: none;
		height: 50px;
		overflow: hidden;
		position: relative;
		position: absolute;
		bottom: 7%;
		
		@media only screen and (min-width: 568px){
			display: block;
		}

		a {
			margin: 0 3px;
			img{
				height: 52.3px;
			}
		}
	}

	.inspirations-content{
		background-size: cover;
		display: none;
		height: 450px;

		@media only screen and (min-width: 568px){
			display: block;
		}

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.inspiration-text{
		padding: 40px !important;
		width: 100% !important;
		height: 300px;
		right: 0 !important;
		background: #494a52;
		position: absolute !important;
		
		@media only screen and (min-width: 568px){
			width: 100% !important;
			background: linear-gradient(90deg,#ffffff00,#18181896,#181818d4,#181818f5,#181818);
			height:100%;
		}

		@media only screen and (min-width: 841px){
			width: 50% !important;
		}

		@media only screen and (min-width: 1024px){
			padding: 100px !important;
		}

		p{
			color: white;
		}
	}
}

.fancybox-button svg{
	color: #ea6645;
}

.fancybox-thumbs__list a:before {
	border: 6px solid #ea6645;
}

.fancybox-image{
	border: 10px solid white;
}

.fb-caption{
	position: absolute;
	left: 0;
	right: 0;
	bottom: -30px;
	z-index: 99996;
	pointer-events: none;
	text-align: center;
	transition: opacity 200ms;
	background: none;
  }



.bloc_covid , .bloc_stuvdays{
	p{
		text-align: center;
		margin-bottom: 2em;
		font-size: 1em;
		

		@media only screen and (max-width: 1279px){
			font-size: 0.8em;
		}
		@media only screen and (max-width: 639px){
			font-size: 1em;
			padding-top: 2em;
		}
	}
	span.icon{
		background: url(../img/covid_icon.svg) no-repeat center center; 
		width: 60px;
		height: 60px;
		display: block;
		background-size: 63%;
		background-color: #fff;
		border-radius: 100px;
		box-shadow: 0 8px 24px rgba(0, 0, 0, 0.18);
		position: absolute;
		top: -32px;
		left: 50%;
		transform: translateX(-50%);
	}

	span.bloc_covid-close{	
		background: url(../img/modal_close-covid.svg) no-repeat center center; 
		width: 30px;
		height: 30px;
		background-size: 63%;
		position: absolute;
		right: 20px;
		top: 9px;

		@media only screen and (max-width: 639px){
			display: block;
		}
	}
	position: absolute;
	background-color: #ffffffde;
    bottom: 50px;
    max-width: 414px;
    padding: 1.5em;
	left: 50px;
	z-index: 100;
	
	@media only screen and (max-width: 1279px){
		max-width: 330px;
		padding: 1em;
	}
	@media only screen and (max-width: 639px){
		max-width: 100%;
		width: 100%;
		left: 0;
		bottom: 0;
		background-color: #fff;
	}
	
	div{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		width: 100%;
		margin: 0 auto;
		@media only screen and (max-width: 1279px){
			width: 100%;
		}
		
		a{
			background-color: #EA6645;
			color: #fff;
			padding: 10px 20px;
			font-size: 1em;
			@media only screen and (max-width: 1279px){
				font-size: 0.8em;
			}
		}
	}
}

.bloc_covid{
	display: none;
}

/* Stuv days */

.bloc_stuvdays{
	max-width: 350px;
	width: 350px;
	z-index: 10000;
	padding: 0;
	@media only screen and (max-width: 1279px){
		left: 0;
		bottom: 0;
	}
	@media only screen and (max-width: 1279px){
		max-width: 160px;
		/*stuvdays*/ height: 50px;
		height: 100px;
		/*stuvdays*/ padding-right: 25px;
		padding-right: 21px;
		width: 160px;
		padding-top: 0px;
		right: inherit;
		bottom: 270px;
		left: inherit;
		top: 26%;
		bottom: inherit;
		top: inherit;
		bottom: 130px;
		height: 62px;
		overflow: hidden;
		left: 0;


	}
	p{
		margin: 1em 0;
		@media only screen and (max-width: 1279px){
			display: none;
		}
	}
	div a{
		@media only screen and (max-width: 1279px){
			display: none;
		}
	}
	img{
		//width: 170px;
		margin: 0 auto;
		display: block;
		@media only screen and (max-width: 1279px){
			/*stuvdays*/width: auto;
			width: 100%;
			width: 120%;
			margin-left: -2px;
			margin-top: -3px;
		}
	}



	&:after{
		position: absolute;
		content: "";
		right: 0;
		height: 100%;
		background: url(/sites/all/themes/shared/dist/img/right-arrow.svg) no-repeat center center; 
		background-size: 80%;
		top: 0;
		background-color: #ea6645;
		top: 0;
		width: 20px;
		display: inline-block;
		transition: .3s ease-in;
		display: none;
		@media only screen and (max-width: 1279px){
			display: block;
			background-position: 40% 40%;
		}

	}
}

/* Stuvdays hacks */

div.dcAppContainer #dataceed-modal .dataceed-form-outer-radio-images .dataceed-form-image {
	height: 150%;
	overflow: visible;
	width: auto;
	transform: translate(0px, -20%);
	object-fit: cover;
}

div.dcAppContainer #dataceed-modal .dataceed-form-outer-radio-images .dataceed-form-image-wrapper {
	height: 4rem;
}

div.dcAppContainer #dataceed-modal .dataceed-modal-wrapper .dataceed-modal-container .dataceed-modal-container-content .dataceed-form #dataceed-modal-container-content-form-content .dataceed-form-outer-radio-images label.dataceed-form-wrapper {
	background-color: #2b2e2d;
	color: white;
	margin: 1em 0;
}

div.dcAppContainer #dataceed-modal .dataceed-modal-wrapper .dataceed-modal-container .dataceed-modal-container-content .dataceed-form #dataceed-modal-container-content-form-content .dataceed-form-outer-radio-images label.dataceed-form-wrapper[data-checked=true] {
	background-color: white;
	color: #2b2e2d;
}

div.dcAppContainer #dataceed-modal .dataceed-modal-wrapper .dataceed-modal-container .dataceed-modal-container-content .dataceed-form #dataceed-modal-container-content-form-content .dataceed-form-outer-radio-images label.dataceed-form-wrapper[data-checked=true] .dataceed-form-option-label:before {
	
	content: '\2713';
	position: relative;
	left: -0.5em;
	font-size: 1.2em;
}

div.dcAppContainer #dataceed-modal .dataceed-form-outer-radio-images .dataceed-form-option:first-child {
	margin-top: 1em;
}

div.dcAppContainer #dataceed-modal .dataceed-form-outer-checkbox-html .dataceed-form-wrapper {
	margin-top: 0 !important;
}

div.dcAppContainer #dataceed-modal .dataceed-modal-wrapper .dataceed-modal-container .dataceed-modal-container-content {
	min-width: auto !important;
}

div.dcAppContainer #dataceed-modal .dataceed-modal-wrapper .dataceed-modal-container .dataceed-modal-container-content .dataceed-form #dataceed-modal-container-content-form-content .dataceed-form-outer {
	margin-bottom: 0 !important;
}

.stuvdays_cta {
	display: flex;
	align-items: stretch;  /* Ensure items stretch to fill the height */
	justify-content: space-between;  /* Ensure space between elements */
	background-color: #f2f2e8;
	border-radius: 5px;
	width: 220px;  /* Set the overall width */
	position: fixed;  /* Position it fixed to the viewport */
	z-index: 250; 
	bottom: 2em;  /* 0px from the bottom of the viewport */
	right: 0;  /* 0px from the right side of the viewport */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Optional: add a shadow for better visibility */
	padding: 0;  /* Remove padding from the container */
	height: 100px;  /* Set a specific height for the container */
  text-decoration:none;
}

.stuvdays_cta img:first-child {
	width: auto;  /* Auto width to maintain aspect ratio */
	height: 100%;  /* Occupy the full height of the container */
	object-fit: cover;  /* Ensure the image covers the area without distortion */
	border-radius: 5px 0 0 5px;  /* Only round the top-left and bottom-left corners */
	margin: 0;  /* Remove any default margin */
	padding: 0;  /* Remove any default padding */
}

.stuvdays_cta div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;  /* Align text to the left */
	color: #444;
	flex-grow: 1;  /* Allow the text area to grow */
	padding-top: 15px;
	padding-left: 10px;
}

.stuvdays_cta * {
	font-family:  "ABC-Diatype", Helvetica, sans-serif;
}

.stuvdays_cta b {
	font-size: 1.8em;  /* Slightly smaller font size for the discount */
	margin-bottom: 2px;
}

.stuvdays_cta p {
	margin: 0;
	font-size: 1em;  /* Slightly smaller font size for the accessory text */
	text-indent: -0.9em;  /* Indent first line slightly less than the '+' */
	padding-left: 0.9em;  /* Align second line under the text */
	line-height: 1;  /* Adjust line height to your preference */
}

.stuvdays_cta p:before {
  content: '+ ';
}

body.new-design main .stuvdays_cta div img,
.stuvdays_cta div img {
	width: 25px;  /* Adjust the arrow size */
	height: 25px;
	align-self: flex-end;  /* Align the arrow to the right within the text container */
	margin-right: 10px;  /* Add some space between the text and the arrow */
	transform: translateY(-8px)
}