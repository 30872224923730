header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1001;
		padding: 30px;
		background: linear-gradient(to top, rgba(0,0,0,0) 0%,rgba(20, 20, 20, .8) 100%);
		background: #434343;
		padding-bottom: 0;
		padding-top: 0;
		box-shadow: 0 1px 23px 1px rgba(0,0,0,.3);
		@mixin country($country, $position:after) {
			&:#{$position} {
				content: "";
				width: 16px;
				height: 12px;
				display: none;
				@if $position == after {
				margin-left: 0.3em;
				} @else {
				margin-right: 0.3em;
				}
				top: 14px;
				display: inline-block;
				vertical-align: baseline; 
				position: absolute;
				background-size: cover;
				background-image: url('../../bower_components/flag-icon-css/flags/4x3/#{$country}.svg'); 
				display: none !important;
				@media only screen and (max-width:1200px) {
					top: 9px;
				}
			}
		} 
		
		$countries: be, fr, gb, nl, ch, de, it, lu, au, ca, us;
		.langue {
			@each $country in $countries {
				[class*="-#{$country}"] {
					@include country($country);
				} 
			}
		}
		
		.langue-selector > ul {
			@each $country in $countries {
				> li[class*="-#{$country}"].active > a {
					@include country($country);
				} 
			}
			
			@each $country in $countries {
				li.children ul li[class*="-#{$country}"] a {
					@include country($country, before);
				}
			}
			
			span {
				color: white !important;
				&:hover {
					color: $orange !important;
				}
			}
		}

		body.langue-open & {
			top: 50px;
		}
		@media only screen and (max-width:1279px) {
			#nav-icon {
			  width: 35px;
			  height: 28px;
			  margin-right: 80px;
			  position: relative;
			  float: left;
			  transform: rotate(0deg);
			  transition: .3s ease-in-out;
			  cursor: pointer;
			  top: 10px;

			  body.open & {
			  	opacity: 0;
			  }
			}

			#nav-icon span {
			  display: block;
			  position: absolute;
			  height: 3px;
			  width: 100%;
			  background: $white;
			  border-radius: 1px;
			  opacity: 1;
			  left: 0;
			  transform: rotate(0deg);
			  transition: .25s ease-in-out;
			}

			#nav-icon span:nth-child(1) {
			  top: 0px;
			}

			#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
			  top: 12px;
			}

			#nav-icon span:nth-child(4) {
			  top: 24px;
			}

			#nav-icon.open span:nth-child(1) {
			  top: 18px;
			  width: 0%;
			  left: 50%;
			}

			#nav-icon.open span:nth-child(2) {
			  transform: rotate(45deg);
			}

			#nav-icon.open span:nth-child(3) {
			  transform: rotate(-45deg);
			}

			#nav-icon.open span:nth-child(4) {
			  top: 18px;
			  width: 0%;
			  left: 50%;
			}
		}

		div.menu  {
			float: left;
		}

		a {
			img {
				float: left;
				width: 130px;
				margin-top: 2px;
			}
		}

		div.langue {
			float: right;
			padding: 5px 0;
			border-right: 1px solid grey;
			padding-right: 30px;

			a {
				color: $white;
				text-decoration: none;
				transition: .3s;

				&:hover {
					color: $orange;
				}
			}
		}

		.socials {
			float: right;
			margin-right: 0;

			li:last-of-type{
				padding-right: 0;
			}
		}

		.share {
			float: right;
			padding: 5px 0;
			border-left: 1px solid grey;
			padding-left: 30px;
			position:relative;
			z-index: 9;
			padding-right: 30px;

			a {
				color: $white;

				svg {
					fill: $white;
					height: 14px;
					transition: .3s;

					&:hover {
						fill: $orange;
					}
				}
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			padding: 5px 0;
			margin-left: 10px;
			margin-right: 10px;

			li {
				text-decoration: none;
				display: inline-block;
				padding: 0 15px;

				@media only screen and (max-width: 1535px) {
					padding: 0 10px;
				}

				a {
					color: white;
					text-decoration: none;
					transition: .3s;
					
					&.active {
						color: #FFF;
					}

					svg {
						fill: $white;
						height: 14px;
						transition: .3s;
	
						&:hover {
							fill: $orange;
						}
					}


				}

				span {
				 	position: relative;
				  	display: block;
				  	cursor: pointer;
				  	padding: 0 0px;

					&.label {
						&.flag {
							text-transform: capitalize;
						}
					}
				}

				// span:before, span:after {
				// 	content: '';
				// 	position: absolute;
				// 	width: 0%;
				// 	height: 2px;
				// 	// top: 50%;
				// 	top: 105%;
				// 	margin-top: 2px;
				// 	background: #fff;
				// }

				// span:before {
				//   	left: 0px;
				// }

				// span:after {
				// 	right: 0px;
				// 	background: $orange;
				// 	transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
				// }

				// span:hover:before, .active span:before {
				// 	background: $orange;
				// 	width: 100%;
				// 	transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
				// }

				// span:hover:after, .active span:after {
				//   	background: transparent;
				//   	width: 100%;
				//   	transition: 0s;
				// }
			}
		}

		ul.shares {
			position: absolute;
		    margin: 0;
		    padding: 0;
		    top: 0px;
		    right: -80px;
		    transition: .3s;

			li {
					margin: 0;
    			padding: 0;
    			list-style: none;
    			display: block;
    			text-align: center;
    			width: 75px;
    			height: 75px;
    			background: rgba($black, .8);

    			&.blank {
    				height: 85px;
    			}

    			a {
    				width: 100%;
    				height: 100%;
    				display: block;
    				position: relative;
    			}
			}
		}

		ul.shares li a.facebook {
		    background-color: #3b5998;
		}
		ul.shares li a.twitter {
		    background-color: #55acee;
		}
		ul.shares li a.pinterest {
		    background-color: #cc2127;
		}

		div.langue-selector {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $black;
			z-index: 3;
			z-index: 16;
			@media screen and (max-width:1200px){
				background:rgba(0, 0, 0, 0.8)
			}

			transform: translateY(-50px);
			transition: all .5s ease;

			// body.langue-open & {
			// 	transform: translateY(0);
			// }

			ul {
				text-align: right;
				clear: both;
				width: 100%;
				padding: 0 16px;

				li {
					padding-top: 16px;
					padding-bottom:16px;
					a {
						transition: .3s;
					}
					
					&.active a, a:hover{
						color: $orange;
					}
				}
			}
			
			@media only screen and (max-width: 1279px) {
				
				transform: translateX(calc(100% - 35px));
				left: auto;
				right: 0;
				top: 5px;
				
				&.with-flag {
					transform: translateX(calc(100% - 65px));
					&.active {
						transform: translateX(0%);
					}
				}
				
				ul {
					
					text-align: center;
					padding: 0;
					width: auto !important;
					
					display: flex;
					flex-direction: row;
					
					li {
						
						text-align: center !important;
						padding: 0.5em;
						font-size: auto !important;
						order: 2;
						
						@media screen and (max-width:840px){
							padding: 0.5em 1em .5em 1.2em;
						}
						
						@media screen and (max-width:360px){
						    padding: .5em 0.6em .5em 1.2em;
						}
						
						a {
							font-size: 1rem;
						}
						
						&.active {
							order: 1;
							padding-right: 20px;
							// a {
							// 	pointer-events: none;
							// }
						}
						
						&.close {
							position:absolute;
							top:0;
							// right:3vw;
							right:0;
						}
					}
				}
				
				&.active {
					transform: translateX(0%);
					width: 100vw;
					right: -20px;
					background: rgba(0,0,0,1);
				}
			}
			
			@media only screen and (max-width: 1279px) {
				&.with-flag {
					transform: translateX(calc(100% - 55px));
					top: 10px;
					background-color: transparent;
					
					&.active {
						transform: translateX(0%);
					}
				}			
			}
			@media only screen and (max-width: 1023px) {
				&.with-flag {
					transform: translateX(calc(100% - 55px));
					top: 10px;
					background-color: transparent;
					background: rgba(0,0,0,.8);
					&.active {
						transform: translateX(0%);
						width: 100vw;
						right: -20px;
						background: rgba(0,0,0,.8);
					}
				}			
			}

			
			
			li.children{
				
				position:relative;
				
				&.mobile-selected:before{
					z-index:60;
					content: "";
					position: absolute;
					border-width: 0 5px 8px 5px;
					border-style: solid;
					left: 30%;
					top: 100%;
					border-color: transparent transparent #ea6645 transparent;
					height: 0;
					width: 0;
					display: block;
				}
				
				> a{
					//pointer-events: none;
				}
				
				ul{
					display:none;
					position: absolute;
					top:50px;
					padding:16px 20px 16px 16px;
					text-align:left;
					width:auto!important;
					background:rgba(0,0,0,0.9);
					border:3px solid $orange;
					white-space: nowrap;
					margin:0;
					&.open-mobile{
						display:flex;
						position:fixed;
						flex-direction: column;
						align-items:center;
						top:2em!important;
						left:0;
						border:none;
						width:100%!important;
					}
					&:before {
						@media screen and (min-width:840px){
							content: "";
							position: absolute;
							border-width: 0 5px 8px 5px;
							border-style: solid;
							left: 50%;
							transform: translateX(-50%);
							top: -10px;
							border-color: transparent transparent #ea6645 transparent;
							height: 0;
							width: 0;
							
							// -webkit-transform: translateY(-50%);
							// transform: translateY(-50%);
							// -webkit-transition: .3s;
							// transition: .3s;
						}
					}
					li{
						padding:0;
						display:block;
						text-align: left!important;
						span {
							color: $orange;
							display:inline;
							&:before,&:after{
								display: none;
							}
							@media screen and (max-width:840px){
								//display:none;
							}
						}
						&.active-country{
							a{
								color:$orange;
							}
						}
						a{
							color: $white;
							text-decoration: none;
							transition:0;
							line-height: 1.4rem;
							&:hover{
								color:$orange;
								text-decoration: none;
							}
							@media screen and (max-width:840px){
								line-height: 2rem;
							}
						}
					}
				}
				// @media screen and (max-width:840px){
				// 	&:hover{
				// 		ul{
				// 			display:block;
				// 			position:fixed;
				// 			top:50%;
				// 			left:50%;
				// 			transform: translateX(-50%)
				// 		}
				// 	}
				// }
			}
		}
	}

div.share:hover ~ ul.shares, ul.shares:hover {
	right: 0;
}



nav.global .menu-container>ul.level-1>li.link_menu-quote {
	padding-right: 0;
	a{
		background-color: #EA6645;
		color: #fff;
		padding: 10px 15px;
		
		@media only screen and (max-width: 1023px){
			display: inline-block;
			padding: 10px 15px;
			width: auto;
		}
	}

}





header {
	div.nav-menu-secondaire_right {

		ul.socials {
			float: right;
			margin-right: 0;
			display: flex;
    		align-content: center;

			li {
				padding: 0 14px;
				display: flex;
				align-items: center;
				align-self: center;

				a {
					display: flex;
				}
			}

			li:last-of-type{
				padding-right: 0;
			}
		}
	}
}


