$openWidth: 500px;

@keyframes animationimg {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(150px);
    }

    100% {
        transform: translateX(150px) rotate(30deg);
    }
} 

nav.global {
	.menu-container{
		position: absolute;
		right: 30px;
		top: 58%;
		z-index: 2;
		flex-wrap: wrap;
		text-align: right;
		width: 45%;
		position: relative;
		left: 0;
		// max-width: 767px;

		span.close{
			display: none;
		}
		@media screen and (max-width: 1800px) {
			width: 70%;
		}
		@media screen and (max-width: 1535px) {
			width: 54%;
			z-index: 3;
		}
		// @media screen and (max-height: 1024px) and (max-width: 1535px) {
		// 	width: 80%;
		// }
		// @media screen and (max-width: 1279px) {
		// 	width: 80%;
		// 	z-index: 3;
		// }
		ul {
			justify-content: space-between;
    		display: flex;
    		text-align: center;
			
			&.start {
				justify-content: flex-start;
				li {
					margin-right: 5em;
				}
			}

    		&.level-1 > li.child.dropdown > a:before{
				opacity: 1;
				width: 100%;
				left:0;
			}

    		&.level-1{
    			margin:0;
    			position: relative;
    			> li{
    				padding-top: 10px;
					padding-bottom: 8px;
					a{
						font-size: 16px;
					}
    				&.child > a:before{
    					content: "";
    					position: absolute;
    					background-color: #F26745;
    					height: 4px;
    					width: 100%;
    					top: 100%;
    					left: 50%;
    					opacity: 0;
    					width: 10%;
    					pointer-events:none;
    					transition: .2s ease-in-out;
    					@media screen and (max-width: 1023px) {
							display: none;
						}
    				}
    			}
    			li.child > a{
					white-space: nowrap;
    				position: relative;
				    padding-bottom: 13px;
					z-index: 100;
					font-size: 16px;

					&.parent_active{
						font-weight: 600;

						&:before{
						    content: "";
						    position: absolute;
						    background-color: #f26745;
						    height: 4px;
						    width: 100%;
						    top: 100%;
						    left: 50%;
						    opacity: 1;
						    width: 100%;
						    pointer-events: none;
						    transition: .2s ease-in-out;
						    transform: translateX(-50%);
						}
					}
	    			&::after {
						content: "";
						position: absolute;
						right: -16px;
						display: inline-block;
						top: 5px;
						width: 7px;
						height: 7px;
						transform: rotate(-45deg);
						border-style: solid;
						border-width: 1px;
						border-color: transparent;
						border-bottom-color: white;
						border-left-color: white;
						transition:
							border-bottom-color .2s,
							border-left-color .2s .1s,
							transform .4s;
					}
				}
				li.child ul.level-2-img li img{
					margin-left: -120%;
					// transition: .5s ease-in;
				}
				// li.child:hover > a::before {
				// 	content: "";
				//     position: absolute;
				//     top: -10px;
				//     left: -80px;
				//     right: -20px;
				//     bottom: -4px;
				//     transform: perspective(100px) rotateX(45deg);
				//     transform-origin: 100% 50%;
				// }
				li.child:hover > a::after {
					transform: translateY(4px) rotate(135deg);
				}
    		}
			
			li {
				display: inline-block;
				font-size: 13px;
				color: #FFFFFF;
				&:nth-child(1){
					&.dropdown{
						ul.level-2 {
							column-count: 2;
							
						}
					}
				}
				&:nth-child(3){
					&.dropdown{
						ul.level-2 {
							column-count: 2;
							// min-width: 130%;
						}
					}
				}
				> a{
					color: #fff;
					opacity: .8;
					text-decoration: none;
					transition: .2s ease-in;
					@media only screen and (max-width:540px) {
						opacity: 1;
					}
				}
				&:hover{
					> a{
						opacity: 1;
						@media only screen and (max-width:1023px) {
							color: #000;
						}
					}
				}
				ul{
					display: none;
					li:hover{
						

						a{
							font-weight: 800;
							position: relative;
							&::after {
								content: "";
								position: absolute;
								right: -16px;
								display: inline-block;
								top: 5px;
								width: 7px;
								height: 7px;
								transform: rotate(-135deg);
								border-style: solid;
								border-width: 1px;
								border-color: transparent;
								border-bottom-color: white;
								border-left-color: white;
								transition:
									border-bottom-color .2s,
									border-left-color .2s .1s,
									transform .4s;
							}
						}
						
						.img_menu{
							z-index: 100;

							img{
								z-index: 100;
							}
						}
					}
				}
				
				&.dropdown{
					ul.level-2 {
						display: inline-block;
						background-color: rgba(67,67,67,.95);
						padding: 50px;
						padding-left: 0;
						position: absolute;
						width: auto;
						top: 100%;
						left: 0;
						text-align: left;
						column-count: 1;
						width: 100%;
						min-height: 254px;


						&:before{
							height: 100%;
							width: 100%;
							left: -100%;
							background-color: rgba(67,67,67,.95);
							position: absolute;
							content: "";
							top:0;
						}
						&:after{
							height: 100%;
							width: 100%;
							right: -100%;
							background-color: rgba(67,67,67,.95);
							position: absolute;
							content: "";
							top:0;
							width: 30%;
							right: -30%;
							z-index: -1;
						}
   						&-img {
   							width: 70%;

   							li:nth-child(1){
   								width: auto;
   								position: relative;
								
								&:after{
									content: "";
									width: 2px;
									background-color: #f26745;
									position: absolute;
									right: 0;
									top: 50%;
									height: calc(100% - 60px);
									transform: translateY(-50%);
								}


   								img{
   									margin-left: -40%;
								    height: 12em;
   								}
   							}
   							&-col3{
   								width: 90%;
   								@media screen and (max-width: 1279px) {
									width: 110%;
								}
   							}

   							&-col1{
   								width: 60%;
   							}
   						}
   						&> li{
   							width: 100%;
							   padding: 15px;
							   padding-bottom: 5px;
							   padding-right: 0;
							   font-size: 14px;
   							@media only screen and (max-width:1023px) {
   								padding-top: 0;
							}
							   @media only screen and (max-width:540px) {
								padding: 30px 0px;
								padding-top: 0;
							}

   						}

						li p{
							text-align: left;
							margin: 0 0 35px 0px;
							text-transform: uppercase;
							position: relative;
							font-weight: 600;
							color: #3A332D;

							&:after{
								content: "";
								position: absolute;
								height: 2px;
								width: 50px;
								background-color: #f26745;
							    left: -10px;
							    bottom: -8px;

							}
						}

				 		li ul.level-3{
				 			display: flex;
						    flex-wrap: wrap;
						    justify-content: space-between;
						    padding-bottom: 30px;
						    margin: 0;

						    li{
						    	display: inline-block;
						    	width: 100%;
    							text-align: left;
    							margin-bottom: 8px;
    							padding: 0;


    							a{
    								text-decoration: none;
    								color: #000000;

    								&:hover{
    									color: $orange;
    									font-weight: 100;
    								}

    								&.active{
										color: #f26745;
										font-weight: 600;
									}
    							}
	    						&.link_devis a{
									font-weight: 600;
									margin-top: 15px;
									display: block;
							    }
							}
				 		}

				 		li ul.list_family li{
				 			position: relative;
				 			width: 22.5%;
    						margin-bottom: 2.5%;
    						overflow: hidden;
    						transition: .3s ease-in-out;
    						padding: 0;

    						img{
    							width: 100%;
    							height: 100%;
    							transition: .3s ease-in-out;
    							position: absolute;
    							top: 50%;
    							left: 50%;
    							transform: translate(-50%,-50%);
    						}

				 			a {
				 				padding-bottom: 100%;
				 				height: 0;
				 				width: 100%;
				 				display: block;
				 				span{
				 					position: absolute;
								    color: #FFFFFF;
								    top: 50%;
								    left: 50%;
								    transform: translate(-50%, -50%);
								    width: 100%;
								    text-align: center;
								    z-index: 100;
								    font-weight: 600;

				 				}
				 			}

    						&:hover {
    							box-shadow: 0 8px 24px rgba(0, 0, 0, 0.21);

    							img{
    								transform: translate(-50%,-50%) scale(1.2);
    							}
    						}
				 		}

				 		li ul {
				 			display: block;
				 		}
				 	}
				}
			}	
		}	 		
	}
}

body {
	
	background-color: #171717;
	@media only screen and (max-width: 1023px) {
		 // background-color: #FFF;
	}
	
	& > *:not(nav) {
		transition: 0.4s;
		// opacity: .8;	
	}
		
	&.open { 
		
		overflow: hidden;
		
		& > *:not(.static) {			 
			opacity: 0.95;
			@media only screen and (max-width: 1023px) {
				opacity: .95;
			}

		}

		& > *:not(.static),
		& > *.static.active {
			transform: translateX($openWidth);
				transform: translateX(300px);
		}
	}
}

/* -----  RESPONSIVE -------*/

@media only screen and (max-width:1279px) {

nav.global {
	position: fixed;
	top: 0; 
	left: 0;
	transform: translateX(-$openWidth); 
	width: $openWidth;
	min-height: 100%;
	background: #434343;
	padding: 25px 0; 
	transition: 0.4s;
	transform: translateX(-100%);

	&.open {
		transform: translateX(0);	
		z-index: 3;	
		display: none;
	}
	
	.close {
		font-size: 2em;
		color: #26272c;
		cursor: pointer;		
		position: absolute;
		top: 20px;
		left: 20px;
		line-height: 1em;
		transition: 0.2s;
		
		&:hover {
			color: orange;
		}
	}
	
	.menu-container {
	width: 100%;
	position: initial;
	text-align: center;
		& > ul {
		
			margin: 0;
			padding: 0;
			margin-top: 45px;

    		&.level-1{
				display: block;
				margin-top: 40px;
				
    			li.child > a{
    				position: relative;
    				text-align: left;
					padding-left: 0px;
					text-align: center;
					color: #fff;
	    			&::after {
						content: "";
						position: absolute;
						right: 8%;
						display: inline-block;
						top: 1px;
						width: 7px;
						height: 7px;
						transform: rotate(-45deg);
						border-style: solid;
						border-width: 1px;
						border-color: transparent;
						border-bottom-color: #000;
						border-left-color: #000;
						transition:
							border-bottom-color .2s,
							border-left-color .2s .1s,
							transform .4s;
							@media only screen and (max-width: 1279px) {
								display: none;
							}
					}
				}
				li.child:hover > a::before {
					content: "";
				    position: absolute;
				    top: -10px;
				    left: -80px;
				    right: -20px;
				    bottom: -4px;
				    transform: perspective(100px) rotateX(45deg);
					transform-origin: 100% 50%;
					@media only screen and (max-width: 1279px) {
						display: none;
					}
					
				}
				li.child:hover > a::after {
					transform: translateY(4px) rotate(135deg);
					@media only screen and (max-width: 1279px) {
						display: none;
					}
				}

    			> li{
					padding: 20px;
					padding-bottom: 10px;
					
					a{
						text-align: center;
					}
    			}
    		}
			li {
				padding: 20px;
				border-bottom: 1px solid #bfc0c2;
				list-style: inside none;
				display: block;
				text-align: left;
				&.dropdown{
					background-color: #f26745;
					box-shadow: none;
					
					ul.level-2 {
				 		display: block;
				 		position: initial;
				 		background-color: inherit;
				 		box-shadow: none;

   						&-img {
   							width: 100%;

   							li:nth-child(1){
   								width: auto;
   								position: relative;
   								@media only screen and (max-width: 1023px) {
   									display: none;
   								}
								
								&:after{
									content: "";
									width: 2px;
									background-color: #f26745;
									position: absolute;
									right: 0;
									top: 50%;
									height: calc(100% - 60px);
									transform: translateY(-50%);
								}


   								img{
   									margin-left: -40%;
								    height: 12em;
								    display: none;
   								}
   							}
   						}

						li p{
							text-align: left;								
							text-transform: uppercase;
							position: relative;
							font-weight: 600;
							color: #3A332D;
							margin: 0;
							margin-bottom: 30px;



							&:after{
								content: "";
								position: absolute;
								height: 2px;
								width: 50px;
								background-color: #f26745;
							    left: 0;
							    bottom: -8px;

							}
						}

				 		li ul.level-3{
				 			display: block;
				 			text-align: left;
				 			padding-bottom: 0;

						    li{
						    	display: inline-block;
						    	width: 100%;
    							text-align: left;
    							margin-bottom: 20px;


    							a{
    								text-decoration: none;
    								color: #000000;
    								text-align: left;
    							}
	    						&.link_devis a{
									font-weight: 600;
									margin-top: 15px;
									display: block;
							    }
							}
				 		}

				 		li ul.list_family li{
				 			width: 32.5%;
    						margin-bottom: 0;
    						position: relative;

    						img{
    							width: 100%;
    						}

				 			a {
				 				span{
				 					position: absolute;
								    color: #FFFFFF;
								    top: 50%;
								    left: 50%;
								    transform: translate(-50%, -50%);
								    width: 100%;
								    height: auto;
								    text-align: center;
								    max-width: 90%;
									padding: 0;
									
									@media only screen and (max-width: 567px) {
										position: absolute;
										color: #fff;
										top: 50%;
										left: 50%;
										transform: translate(-50%,-50%);
										width: 100%;
										height: auto;
										text-align: center;
										/* max-width: 90%; */
										padding: 0;
										//top: inherit;
										//bottom: -19px;
										//background: rgba(29,29,29,.4);
										/* height: 30%; */
										width: 100%;
										padding: 10px;
									}
				 				}
				 			}
				 		}

				 		li ul {
				 			display: block;
				 		}
				 	}
				}
				
				a, span {
					color: #26272c;
					font-size: 1.2em;
					text-transform: uppercase;
					text-decoration: none;
					
					display: block;
					width: 100%;
					height: 100%;
					cursor: pointer;					
				}

				& > ul {
					
					margin-top: 15px;
					padding: 0;
					display: none !important;
					
					li {
						
						padding: 5px 10px;
						border-bottom: none;
						
						a {
							font-size: 1em;
							color: #26272c;
							text-transform: none;
						}
						
						&.active, &:hover {
							a {
								color: orange;
							}		
						}
					}
				}
	
				&.open {
					background: #FFF;
					
					span {
						&:after {
							transform: rotate(0deg);
						}
					}
					
					& > ul {
						display: block;
					}
				}
			}
		}	
	}
}
 

	body.open>:not(.static) {
		transform: none;
	}

	nav.global {
		background: #FFF;
	}

	nav.global .menu-container span.close {
		font-size: 2em;
    	color: #ffffff;
    	top: 20px;
    	display: block;
    	z-index: 100;
	    right: 30px;
	    text-align: right;
	    font-weight: 100;
	}

	nav.global {		
		overflow: scroll;		
		height: 100%;	
	}

	nav.global .menu-container > ul {
		
		&.start {
			justify-content: space-between;
			li {
				margin-right: 0;
			}
		}
		
		li {
			
			border-bottom: 1px solid rgba(191, 192, 194, 0.16);
			@media only screen and (max-width: 1279px) {
			
				padding: 10px;
				border: none !important;
			}
		}
	}

	nav.global .menu-container >ul li a, nav.global .menu-container >ul li span {
		text-align: left;
	    padding-left: 30px;
		font-size: 1em !important;

		@media only screen and (max-width: 1279px) {
			padding-left: 0;
			color: #fff;
			
		}


	}

	nav.global .menu-container>ul li span:after {
	    width: 1em;
	    height: 1em;
	}
	
	nav.global .menu-container > ul li.open a, nav.global .menu-container > ul li.open span {
		color: $black !important;
	}
}

.global.static.nav--principal{
    flex-wrap: wrap;
    justify-content: space-between;
	display: flex;
	
	@media only screen and (max-width: 1279px) {
		display: inline-block;
		background-color: #434343;
		z-index: 1000;
	}
	
	.nav-menu-principal_right{
		margin-top: 8px;
		// max-width: 330px;
		display: flex;
		align-items: center;

		a {
			flex-shrink: 0;
		}
		
		@media only screen and (max-width: 1279px) {
			display: block;
			margin: 0 auto;
			margin-top: 70px;
		}
		.event-get-quote{
			background-color: #f26745;
			color: #fff;
			padding: 8px 15px;
			font-weight: 600;
			display: inline-block;
			@media only screen and (max-width: 1279px) {
				display: block;
				max-width: 70%;
				text-align: center;
				margin: 0 auto;
				padding: 11px;
			}
		}
		.cta--pdv{
			font-weight: 600;
			color: #fff;
			display: inline-block;
			margin-right: 20px;
			@media only screen and (max-width: 1279px) {
				display: block;
				margin-right: 0;
				text-align: center;
				margin-bottom: 20px;
			}
		}
	}
}
.nav-menu-left{
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
	width: 430px;
	float: left;
}

.nav-menu-secondaire{
	display: flex;
    flex-wrap: wrap;
	justify-content: space-between;
	padding-top: 5px;
    padding-bottom: 5px;
	background-color: #303030;
	position: relative;
	@media only screen and (max-width: 1279px) {
		padding: 0;
		height: 0;
	}
	&:before{
		background-color: #303030;
		position: absolute;
		left: -100%;
		width: 100%;
		content: "";
		top: 0;
		height: 100%;
	}
	&:after{
		background-color: #303030;
		position: absolute;
		right: -100%;
		width: 100%;
		content: "";
		top: 0;
		height: 100%;
	}
}
.nav-menu-secondaire_right{
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
	width: 255px;
	float: right;
	@media only screen and (max-width: 1279px) {
		width: 0;
	}
}

.nav-menu-secondaire_left{
    position: absolute;
    right: 30px;
    top: 58%;
    z-index: 2;
    flex-wrap: wrap;
    text-align: right;
    width: 45%;
    position: relative;
    left: 130px;
	top: 5px;
	ul{
		margin: 0;
		padding: 0;
		li a{
			font-size: 14px;
		}
	}
}

.nav-menu-principal{
	padding: 15px 0;

	.lang--responsive{
		display: none;
		@media only screen and (max-width: 1279px) {
			display: block;
		}
	}
	
	.img_menu{
		width: auto;
		max-width: 310px;
		height: 80%;
		width: auto;
		max-width: 270px;
		min-width: 320px;
		height: 40%;
		min-height: 160px;

		position: absolute;
		right: -40%;
		// top: 50%;
		top: 145px;
		transform: translatey(-50%);
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	@media only screen and (max-width: 1279px) {
		padding: 0;
		padding-bottom: 50px;
	.logo_corporate .corporate{
		margin-right: 0;
		float: right;
		margin: 0;
	}}
}

nav.global .menu-container ul.nav--secondaire--mobile{
	display: none;
	@media only screen and (max-width: 1279px) {
		display: block;
		display: flex;
		margin-top: 30px;
		width: 90%;
		margin: 0 auto;
		border: none;
		flex-wrap: wrap;
		// justify-content: space-between;
		justify-content: center;
		margin-top: 50px;
		position: relative;

		&:before{
			content: "";
			position: absolute;
			bottom: -20px;
			left: 50%;
			transform: translateX(-50%);
			background-color: #fff;
			width: 200px;
			height: 1px;
		}

		li a{
			font-weight: 900;
		}
	}
	@media only screen and (max-width: 330px) {
		width: 95%;

		li {
			padding: 7px;
		}
	}
}

.stuv-link_responsive{
	display: none;
	
	@media only screen and (max-width: 1279px) {
		display: block;
		
		.mystuv-link{
			display: block;
			float: inherit;
			text-align: center;
			border: none;
			margin: inherit;
			margin-top: 40px;
		}
		.socials{
			display: block;
			padding-bottom: 50px;
			float: none;
			margin: 0 auto;
			margin-top: 30px;
			text-align: center;
		
			li{
				font-size: 18px;
				margin: 0 5px;
				padding: 0 10px;
			}
		}
	}
}

#map {
	height: 100%;
  }

  nav.global .menu-container>ul.start li{
	@media only screen and (max-width: 1279px) {
	  background-color: transparent;
	  color: #ffffff;
	}
  }