.posmap {
	
	height: 500px;
	width: 100%;
	background: #C9C9C9;
	display: block;
	
	.infowindow {

		span{
			text-align: left;
			display: block;
			text-transform: uppercase;
			color: #9A9A9A;
			font-size: 12px;
		}
		h4 {
			
			text-transform: uppercase;
			font-size: 16px;
			margin: 0;
			margin-top: 3px;
			text-align: left;
			
			a {
				font-family: 'Arboria-Bold';
				text-decoration: none;
				color: #464646;
				text-align: left;
			}
		}
		.more--info_windows{
			text-transform: uppercase;
			font-size: 12px;
			color: #ea6645;
			text-align: left;
			font-size: 11px;
			margin-top: 6px;
			display: block;
		}
		p{
			display: none;
			padding-top: 20px;
			border-top: 1px solid #575757;
		}
	}
} 

.tabs {
	
	text-align: center;
	margin-bottom: 3em;
	
	li {
		
		display: inline-block; 
		width: 20%;
		text-align: center;
		text-transform: uppercase;
		font-family: 'Arboria-Book';
		font-size: 1.1em;
		background: #E3E3E3;
		padding: 0.6em;
		color: $orange;
		
		//border-right: 1px solid #999;
		cursor: pointer;
		
		&:hover, &.active {
			background: $orange;
			color: white;
		}
	}
}

.search {
	
	display: block;
	width: 100%;
	text-align: center;
	
	margin: 3em 0; 
	margin-bottom: 0;
	
	select, input, button {
		display: inline-block;
		border: 1px solid #ccc;
		padding: 0.2em 0.4em;		
		margin: 0.6em;
		font-size: 1em;
		height: 2em;
	}
	
	button {
		border: none;
		background: $orange;
		color: white;
		font-size: 1em;
		line-height: 1em;
		height: auto;
	    padding: 0.4em;
	    padding-bottom: 0.5em;
	    margin: 0;
	    
	    &:hover {
		    background: white;
		    color: $orange;
	    }
	}
}

.showmodels {
	float: right;
	color: $orange;
	font-size: 1em;
	padding: 0.3em 0.5em;
	margin-bottom: 1em;
	&:hover {
		text-decoration: underline;
	}
}

.product-selector {
	
	text-align: center;
	width: 100%;
	margin-bottom: 3em;
	clear: both;
	
	&:after {
	  content: "";
	  display: table;
	  clear: both;
	}
	
	.product-single {
		
		position: relative;
		display: inline-block;
		float: left;
		width: 20%;
		height: 20vw;
		
		background-size: cover;
		background-repeat: no-repeat; 
		background-position: center center;
		
		cursor: pointer;  
		
		h2 {
			display: block;
			background: rgba($black, 0.6);
			color: white;
			font-family: 'Arboria-Light';
			width: 100%;
			display: block;
			text-align: center;
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 0.3em 0.5em;
			margin: 0;
			
			font-size: 1em;
			
			transition: 0.4s;
			min-height: 2em;
			
			// &:after {
			// 	font-family: "FontAwesome";
			// 	content: "\f00c";
			// 	display: block;
			// 	position: absolute;
			// 	left: 50%;
			// 	top: 50%;
			// 	transform: translate(-50%, -50%);
			// 	font-size: 3em;
			// 	opacity: 0;
			// 	transition: 0.4s;
			// }
		}
		
		&:hover, &.active {
			h2 {
				min-height: 100%;
				padding-top: 1em;
				&:after {
					opacity: 1;
				}
			}
		}
	} 
}

.infos-sup {
		
		display: none;
		
		background: rgba(29,29,29,1);
	    padding: 25px;
	    width: 100%; 

	    a {
	    	color: $orange;
	    }

    p {
    	margin: 0;
	    font-size: 16px;
	    color: white;
	    text-align: left; 
	    line-height: 25px; 

		.legend {
			display: none;
		}

	    span.desc {
	    	color: #ea6645;
	    	position: relative;
	    	padding-left: 20px;

	    	&:before {
	    		content: "";
			    position: absolute;
			    height: 20px;
			    width: 20px;
			    background-size: cover!important;
			    left: -6px;
			    top: -2px;
	    	}
	    	
	    	&.light_grey-d:before { background: url('../img/map/markers/light_grey-d.png') 50% 50%; };
			&.light_grey-s:before { background: url('../img/map/markers/light_grey-s.png') 50% 50%; };
			&.light_grey-g:before { background: url('../img/map/markers/light_grey-g.png') 50% 50%; };
			&.light_grey-showroom:before { background: url('../img/map/markers/light_grey-showroom.png') 50% 50%; };
			
			&.dark_grey-d:before { background: url('../img/map/markers/dark_grey-d.png') 50% 50%; };
			&.dark_grey-s:before { background: url('../img/map/markers/dark_grey-s.png') 50% 50%; };
			&.dark_grey-g:before { background: url('../img/map/markers/dark_grey-g.png') 50% 50%; };
			&.dark_grey-showroom:before { background: url('../img/map/markers/dark_grey-showroom.png') 50% 50%; };
			
			&.orange-d:before { background: url('../img/map/markers/orange-d.png') 50% 50%; };
			&.orange-s:before { background: url('../img/map/markers/orange-s.png') 50% 50%; };
			&.orange-g:before { background: url('../img/map/markers/orange-g.png') 50% 50%; };
			&.orange-showroom:before { background: url('../img/map/markers/orange-showroom.png') 50% 50%; };
	    }

    }
}

.dealers {
	
	margin-top: 2em;
	
	.dealer {
		
		margin: 1%;
		background: #E3E3E3;
		padding: 1em;
		border-bottom: 1px solid #CCC;
		padding-top: 1.2em;

		display: block;
		width: 31%;
		float: left;
		
		height: 280px;
		
		//&.classic,
		&.studio,
		&.store,
		&.gallery {
			position: relative;
			&:after {
				
				content: "Stûv classic";
				display: block;
				
				font-family: 'Arboria-Book';
				background: $orange;
				color: white;
				
				padding: 0.3em 0.5em;
				position: absolute;
				bottom: 10px;
				right: 10px;
				
				background: $black;
				color: $orange;

				display: none;
			}
		}
		
		&.studio:after {
			//background: $black;
			//color: $orange;
			content: "Stûv studio";
		}
		
		&.gallery:after {
			content: "Stûv gallery";
		}
		
		&.store:after {
			content: "Stûv store";
		}
		
		// &.outside {
		// 	position: relative;
		// 	padding-top: 3em;
		// 	&:before {
				
		// 		content: "hors département";
		// 		display: block;
		// 		width: 100%;
				
		// 		text-align: center;
		// 		font-family: 'Arboria-Book';
		// 		color: white;
		// 		background: $orange;
				
		// 		padding: 0.5em;
		// 		height: 2.2em;
				
		// 		position: absolute;
		// 		bottom: 0;
		// 		right: 0; 
		// 	}
		// }
		
		h5 {
			color: $orange;
			margin: 0;
			padding: 0;
			text-transform: uppercase;
		}
			
		h4 {
			text-transform: uppercase;
			font-size: 1.6em;
			margin: 0;
			color: $orange;
			
			a {
				
				background: $orange;
				color: white;
				padding: 0 0.3em;
				
				&:hover {
					text-decoration: none;
				}
			}
		}
		
		a {
			color: $orange;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.dealer-details {
	
	max-width: 1535px;
	margin: 5em auto;

	.informations {
		
		margin: 1%;
		background: #E3E3E3;
		padding: 1em 2em;
		border-bottom: 1px solid #CCC;
		
		&:after {
		  content: "";
		  display: table;
		  clear: both;
		}
		
		a {
			color: $orange;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		
		h3 {
			text-transform: uppercase;
			font-weight: bold;
		}
		
		.showroom {
			float: left;
			margin-right: 2em;
			img {
				width: 350px;
			}
		}
		
		.location {
			float: right;
			margin-left: 2em;
		}
		
		&.images {
			
			&:after {
			  content: "";
			  display: table;
			  clear: both;
			}
			
			.preview {
				
				width: 30%;
				height: 40vw;
				overflow: hidden;
				
				float: left;
				margin: 1em;
			
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
			
				img {
					visibility: hidden;
				}
			}			
		}
	}
}