body.inbound{
    
    header{
        background:#434343;
    }
     ul.menu_product{
            display:none;
        }
    .modal {
	    
	    position: fixed;
	    top: 0;
	    left: 0;
	    
	    width: 100%;
		height: 100%;
		
		background: rgba(#000, 0.75);
		
		z-index: 20;
		
		.content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 30%;
			background: $light-grey;
			padding: 15px 30px;
			@media screen and (max-width: 567px){
                width: 90%;
            }

             .cta_home{
                cursor: pointer;
                border: none;
                text-decoration: none;
                color: #fff;
                background: #ea6645;
                background-size: 200% 100%;
                padding: 10px 30px;
                font-weight: 600;
                font-size: 16px;
                display: block;
                transition: .3s ease;
                background-position: 0 0;
                width: 80%;
                margin: auto;
                margin-top: 2em;
                margin-bottom: 1em;
                text-align: center;
             }
            .close_modal_inbound{
                font-size: 2em;
                color: #26272c;
                cursor: pointer;
                position: absolute;
                top: 20px;
                right: 20px;
                line-height: 1em;
                transition: .2s;

                &:hover{
                    color: $orange;
                }
            }
			h2 {
				display: block;
				width: 100%;
				text-align: center;
				text-transform: uppercase;
				font-size: 1.6em;
                padding-bottom: 10px;
                position: relative;
                margin-bottom: 1.5em;
                margin-top: 0.5em;

                &:after{
                    content: "";
                    position: absolute;
                    height: 3px;
                    width: 50px;
                    background: #ea6645;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                }
			}
			
			p, li {
				
				font-size: 1em;
				
				a {
					color: $orange;
					transition: 0.3s;
				
					&:hover {
						margin-left: 5px
					}
				}
			}
		}
    }
}




.disable{
        display: none;
    }
section.type-inbound {

	background-color: $light-grey;
	padding-bottom: 3em;
	
	&:before,
	&:after {
	  content: "";
	  display: table;
	} 
	
	&:after {
	  clear: both;
	}

    .template-content{
        background:transparent;
    }
    
    .inbound-template-selector {
	    
    }
    
    .inbound-template-container {
	 	   
	    .inbound-template-menu {
		    
		    background: white;
            padding: 10px 30px;
            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.06);
            
            select, h3 {
	            display: inline-block;
	            margin-right: 1em;
            }

            h3 {
                margin-right: 0;
    
            }
    
            select {
                outline: none;
                display: inline-block;
                float: none;
                background: #fff;
                border: 1px solid #ccc;
                padding: 15px;
                height: 3em;
                -webkit-appearance: none;
                appearance: none;
                font-family: Arboria-Light,serif;
                border-radius: 0;
                margin-left: 1em;

                &:focus {
                    border: 0;
                    outline: 1px solid #ea6645;
                }
            }
            
            .choices {
	            display: flex;
	            flex-wrap: wrap;
	            justify-content: space-between;
	            
	            padding: 1em;
	            background: #FDFDFD;
	            border: 1px solid #F3F3F3;
	            
	            label {
		            font-family: 'Arboria-Book',sans-serif;
		            font-weight: normal;
	            }
            }

            strong {
                font-family: 'Arboria-Book',sans-serif;
            }
		}
	    
	    .inbound-template-list {
		    
		    display: flex;
		    flex-wrap: wrap;
		    //justify-content: space-between;
		    justify-content: flex-start;
		    
		    .inbound-template-block {
			    
			    background: white;
	            padding: 10px 30px;
	            padding-bottom: 30px;
	            // margin: 30px 0;
	            margin: 0 0;
                margin-top: 30px;
	            // margin-right: 5%;
	            // width: 30%;
	            width: 100%;
	            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.06);
	            
	            display: flex;
	            justify-content: center;
                
                &.inbound-gallery-block {
                    
                    flex-wrap: wrap;
                    
                     h3 {
                         display: block;
                         width: 100%;
                         margin-bottom: 1em;
                         font-weight: bold;
                     }
                     
                     img {
                         width: 100%;
                         height: 200px;
                         object-fit: cover;
                     } 
                     
                     a {
                         cursor: pointer;
                     }  
                }
	            
	            .image img {
		            max-width: 100px;
		            margin: 1em 1em 0 0;
	            }
	            
	            &.full {
		            width: 100%;
	            }
	            
	            h3 {
		            margin-bottom: 0;
	            }
	            
	            .image {
		            
	            }
	            
	            .article {
		            margin: 0;
		            margin-bottom: 1em;
		            padding: 0;
		            font-size: 0.7;
		            color: #999;
	            }
	            
	            //&:nth-of-type(3n),
	            &.last-column {
		           margin-right: 0; 
	            }
	            
	            ul {
		            
		            margin: 0;
		            padding: 0;
		            
		            li {
			            list-style: none;
			            margin: 0;
						padding: 0;
		            }
	            }
		    }
		    
	    }
	    
    }

    &.form{
    //    display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
        width: 100vw;
        overflow: hidden;
        // padding-bottom: 0;

        
        // h1{
        //     width: auto;
        // }
        .template-content{
            //width: 60%;
            overflow: initial;
            padding-top: 140px;
            width: 100%;
            padding-bottom: 3em;
            @media screen and (max-width: 567px){
                width: 100%;
            }
        }
        &.form_big .template-content{
            width: 100%;
        }

        .form_img{
            width: 40%;

            @media screen and (max-width: 567px){
                display: none;
            }
        }
    }

    // h1 {
	    
    //     font-family: 'Arboria-Bold', serif;
    //     margin-top: 150px;
    //     margin-bottom: 1em;
    //     text-transform: uppercase;
    //     text-align: center;
    //     position: relative;
    //     font-size: 1.5em;
    //     padding-bottom: 1em;
        
    //     @media screen and (max-width: 567px){
    //         padding:0 20px;
    //         padding-bottom: 1em;
    //     }

    //     &:after {
    //         content: '';
    //         position: absolute;
    //         height: 6px;
    //         width: 100px;
    //         background-color: #ea6645;
    //         bottom: 0px;
    //         left: 0;
    //         right: 0;
    //         margin: 0 auto;

    //          @media screen and (max-width: 567px){
    //             height: 4px;
    //             bottom: 0;
    //          }
    //     }
    // } 

    div.inbound-txt {

        p.breadcrumb {
            margin-left: 0;
            font-style:italic;
            font-size:.9rem;
            margin-bottom:50px;
        }

        p:not(.breadcrumb) {
            font-family: 'Arboria-Book';
            font-size: 18px;
        }   
                p.link {
                    text-align: center;
                }
                p.link a {
                    clear: both;
                    float: none;
                    display: inline-block;
                    font-size: 1rem;
                    text-align: center;
                    padding: 15px 45px;
                    text-transform: uppercase;
                    background: #ea6645;
                    border: 1px solid #ea6645;
                    font-family: 'Arboria-Bold', serif;
                    color: #fff;
                    transition: .3s ease;
                    text-align: center;
                    text-decoration: none;
                    margin: 40px 10px 0;
                }
    }

    ul.inbound-list, div.inbound-txt {
        max-width: 1140px;
        padding: 0 20px;
        margin: 0 auto;
        margin-top: 70px;

        .cta_download-pdf {
            width: 100%;
            display: inline-flex;
            justify-content: center;
            float: left;
            margin-bottom: 2em;
            /* height: 25vw; */

            a {
                width: inherit;
                display: contents;

                li.inbound-list-block {
                    height: 240px !important;
                    display: flex !important;
                    overflow: unset;
    
                    span {
    
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        li.inbound-list-block {
            background-color: white;
            list-style: none;
            padding: 10px;
            width: calc((100% / 3) - 30px);
            min-height: 200px;
            height: 20vh;
            float: left;
            margin: 15px;
            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.06);
            @media screen and (max-width:940px){
                width: calc((100% / 2) - 30px);            
            }
            @media screen and (max-width:620px){
                width: calc(100% - 30px);
                height: 30vh;
                  
            }

            h2 {
                background: #f9f9f9 !important;
                text-align: center;
                text-transform: uppercase;
                opacity: 1;
                color: rgba(black, .75);
                font-size: 17px;
            }
            
            img.badge {
	            width: 60px;
			    position: absolute;
			    top: 10px;
			    right: 15px;
            }

            ul {
	            
                margin: 0;
                padding: 0 40px;
                list-style: none;

                li {
	                
                    font-size: 18px;
                    margin-bottom: 0.2em;
                    position: relative;
                    font-family: 'Arboria-Light';
					
					@media screen and (max-height:1035px){
						font-size: 16px; 
            		}
            		
            		@media screen and (max-height:955px){
						font-size: 15px; 
            		}
					
                    a {
	                    
                        text-decoration: inherit;
                        color: inherit;
                        transition: .3s ease;
                        cursor: pointer;
                        position: relative;
                        left: 0;
                        
                        font-family: 'Arboria-Light';

                        &:hover {
                            color: #ea6645;
                            left: 5px;
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        height: 10px;
                        width: 10px;
                        background-image: url('../img/inbound/arrow.svg');
                        background-size: cover;
                        background-position: 50% 50%;
                        left: -15px;
                        top: 5px;
                    }
                }
            }

            span {
    
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .inbound-gallery-block {
            flex-wrap: wrap;
            justify-content: flex-start !important;
            padding: 0 !important;
            margin-left: 10vw !important;
            margin-right: 10vw !important;

            h3 {
                width: 100% !important;
                text-align: center;
                padding: 0;

                @media screen and (min-width: 415px) {
                    width: 40% !important;
                    text-align: left;
                    padding-left: 20px;
                }
            }

            a {
                display: flex;
                width: 100% !important;

                @media screen and (min-width: 415px) {
                    width: 60% !important;
                }
            }
        }
    }

    &.inner {

        div.template-content {

            h2 {
                text-align: center;
                color: #494a52;
                margin-top: 50px;
                margin-left: auto;
                margin-right: auto;
                width: 80%;
                line-height: 1.3;
                font-size: 2.2em;
                font-family: 'Arboria-Light' , sans-serif;
        
                @media only screen and (max-width: 567px) {
                    font-size: 1.6em;
                }
        
                &:after {
                    content: '';
                    position: absolute;
                    height: 3px;
                    width: 130px;
                    background: $orange;
                    bottom: -20px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
        }

        p.sub {
            margin-left: 5vw;
            margin-right: 5vw;
            text-align: center;
            font-family: 'Arboria-Light', serif;
            position: relative;
            font-size: 20px;
            margin-top: 40px;
        }

        ul.inbound-list {

            /* Animation CTA LOADING FORM */ 

            &.loading form span input[type=submit] {
        
                &.button-loading {
                    transition-property: width height padding box-shadow border-width background opacity;
                    transition-duration: 0.3s;
                    transition-timing-function: ease-in-out;
                    
                    background: transparent;
                    box-shadow: 0 0 0 #ea6645;
                    border-top: 4px solid #ea6645;
                    border-right: 4px solid #d1d2d2;
                    border-bottom: 4px solid #d1d2d2;
                    border-left: 4px solid #d1d2d2;
            
                    transform: translateZ(0);
                    animation: load8 1s infinite linear;
                    animation-delay: .2s;
                    width: 50px;
                    height: 50px;
                    padding: 0;
                    border-radius: 50%;

                    color: #f9f9f9;
              
                }

                @-webkit-keyframes load8 {
                    0% {
                      -webkit-transform: rotate(0deg);
                      transform: rotate(0deg);
                    }
                    100% {
                      -webkit-transform: rotate(360deg);
                      transform: rotate(360deg);
                    }
                  }
                  @keyframes load8 {
                    0% {
                      -webkit-transform: rotate(0deg);
                      transform: rotate(0deg);
                    }
                    100% {
                      -webkit-transform: rotate(360deg);
                      transform: rotate(360deg);
                    }
                  }   
            }

             /* Fin Animation CTA LOADING FORM */ 


             
            .modal_download-pdf{
                display: none;
                width: 100vw;
                height:100vh;
                background-color: rgba(0, 0, 0, 0.82);
                z-index: 20;
                position: fixed;
                top: 0;
                left: 0;

                div{
                    position: absolute;
                    background: linear-gradient(to bottom left, #fff, #dfdcdc 180%);
                    color: #000;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50% , -50%);
                    max-width: 40%;
                    padding: 3em;
                    text-align: center;

                    @media screen and (max-width:450px){
                         padding: 1em;
                    }

                    .close-modal{
                        background: no-repeat;
                        border: none;
                        color: #ea6645;
                        font-size: 27px;
                        position: absolute;
                        top: 3%;
                        right: 3%;
                        cursor: pointer;
                        @media screen and (max-width:450px){
                            top: 1%;
                        }
                    }
                   
                     form {
                        position: relative;
                        

                        

                        p {
                            margin-left: 15px;
                            font-family: 'Arboria-Bold', serif;
                            font-size: 20px;

                        }

                        h4{
                            padding-bottom: 1em;
                            font-size: 20px;
                        }
                        
                        label {
                            display: inline;
                            font-weight: normal;
                            font-family: 'Arboria-book', serif;
                            vertical-align: top;

                            &.label-gdpr{
                                position: absolute;
                                margin-left: 5px;

                                a{
                                    text-decoration: none;
                                    color: #ea6645;
                                }
                            }
                        }


                        input:first-child{
                                margin-left: 20px;
                        }
                        input[type=email], input[type=text], input[type=textarea] {
                            background-color: transparent;
                            border: 1px solid #CCC;
                            border-radius: 0;
                            font-family: 'Arboria-Light', serif;
                            outline: none;
                            width: 84%;
                            margin: 15px;
                            padding: 15px 20px;
                            transition: ease .3s;
                            background: white;
                            box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.05);
                            display: block;
                            margin: 0 auto;
                            margin-bottom: 1em;

                            // &:not(:nth-of-type(n+3)){
                            //     width: calc(37% - -30px);
                            //     display: inline-block;
                            //     margin-bottom: 1em;
                            // }

                            &.half {
                                width: calc(50% - 30px);
                                display: inline-block;
                            }
                            
                            @media screen and (max-width:620px){
                                width: calc(100% - 30px);
                            }      
                        }
        
                    input[type="checkbox"] {
                        //opacity: 0;
                        appearance: none;
                        background: white;
                        border: 2px solid $orange;
                        cursor: pointer;
                        position: absolute;
                        left: 8%;
                        width: 18px;
                        height: 18px;
                        outline: none;
                        @media screen and (max-width:620px){
                            left: 0;
                        } 
                        
                        &:checked {
                            
                            background-color: $orange;
                            
                            &:before { 
                                content: "";
                                display: block;
                                position: absolute;
                                top: 0;
                                left: auto;
                                right: 8px;
                                width: 8px;
                                height: 13px;
                                border-top: 2px solid transparent;
                                border-left: 2px solid transparent;
                                border-right: 2px solid #fff;
                                border-bottom: 2px solid #fff;
                                transform: rotate(37deg);
                                transform-origin: 100% 100%;
                            }   
                        }
                    
                        & + label {
                            vertical-align: top;
                            display: inherit;
                            position: inherit;
                            margin: 0 auto;
                            text-align: left;
                            margin-bottom: 1em;
                            width: 70%;

                            @media screen and (max-width:375px){
                                width: 90%;
                                margin-left: 1.7em;
                            }
                        }
                    }
                input[type=submit] {
                    clear: both;
                    float: none;
                    display: inline-block;
                    font-size: 20px;
                    text-align: center;
                    padding: 15px 45px;
                    text-transform: uppercase;
                    background: #ea6645;
                    border: 1px solid #ea6645;
                    font-family: 'Arboria-Bold', serif;
                    color: #fff;
                    transition: .3s ease;
                    text-align: center;
                    margin-top: 40px;


                }

                .cta_download{
                    clear: both;
                    float: none;
                    display: inline-block;
                    font-size: 14px;
                    padding: 15px 45px;
                    text-transform: uppercase;
                    background: #ea6645;
                    border: 1px solid #ea6645;
                    font-family: Arboria-Bold,serif;
                    color: #fff;
                    transition: .3s ease;
                    text-align: center;
                    margin-top: 40px;

                    @media screen and (max-width:375px){
                        margin-top: 0;
                    }
                }
            }
                    h4{
                        position: relative;
                        &:after{
                            content: "";
                            position: absolute;
                            height: 3px;
                            width: 50px;
                            background-color: #ea6645;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        }
                    }
                    @media screen and (max-width: 1535px) {
                        width: 50%;
                        max-width: 50%;
                        font-size: .8em;
                        top: 58%;
                    }
                    @media screen and (max-width: 1279px) {
                        width: 60%;
                    }
                    @media screen and (max-width: 639px) {
                        width: 90%;
                        max-width: 90%;
                    }
                }
            }
            .modal_download-pdf{

                &.active-modal{display: block;}
            }
            p.sub {
            margin-left: 15px;
            font-family: 'Arboria-Light', serif;
            font-size: 20px;
            text-align: center;

                span {
                    color: #ea6645;
                }
            }

            li.inbound-list-block {
                height: auto;
                overflow:hidden;
                display: flex;
                flex-direction:column;
                padding:0;
                cursor: pointer;
                min-height:0;

                h2 {
                    display: block;
                    text-align: left;
                    width: 100%;
                    margin: 0;
                    padding: 20px 50px 20px 20px;
                    background: white;
                    opacity: 1;
                    text-transform: initial;
                    position: relative;
                    font-family: 'Arboria-Bold';

                    &:after {
                        content: '';
                        position: absolute;
                        height: 18px;
                        width: 18px;
                        right: 25px;
                        top: 50%;
                        opacity: .75;
                        transform: translateY(-50%);
                        background-image: url('../img/inbound/link.svg');
                        background-size: cover;
                        background-position: 50% 50%;
                        background-color: unset;
                        left: unset;                     
                    }
                }

                span {
                    height: 150px;
                    width: 100%;
                    background-position: 50% 0%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    transition: all ease .7s;
                    transform: scale(1.01);
                    
                    @media screen and (max-width:620px){
	                	background-position: 0% 0%;
                    }

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                &:hover {

                    span {
                        transform: scale(1.07);
                        transition: all ease .5s;
                    }
                }
            }
        }
    }

	p.breadcrumb {
	    margin-left: 15px;
        font-style:italic;
        font-size:.9rem;
        
	    a {
	        color: inherit;
	        text-decoration: inherit;
	        transition: .3s ease;
	
	        &:hover {
	            color: #ea6645;
	        }
	    }
	}
	
	&.form {

	    form , &.form_mystuv{
             position: relative;
             
            .input-container {
				@media screen and (max-width:1558px){
					
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					
				}
			}
             
	        p {
	            margin-left: 15px;
	            font-family: 'Arboria-Bold', serif;
	            font-size: 20px;

	        }
	       .required-form{
                font-size: 10px;
                margin-bottom: 15px;
            }

            .tag_type{
                color: #ea6645;
            }
            .required-form-revendeur{
                font-size: 14px;
                font-size: 14px;
                font-family: 'Arboria',serif;

                 @media screen and (max-width:460px){
                    margin-top: 3em;
                 }
            }

	        h3 {
	            margin-left: 15px;
	            font-family: 'Arboria-Light', serif;
	            font-size: 1.4em;
				margin-bottom: 0.7em;
	        }
	        
	        label {
		        display: inline;
		        font-weight: normal;
		        font-family: 'Arboria-book', serif;
		        vertical-align: top;

                &.label-gdpr{
                    // margin-left: 5px;

                    a{
                        text-decoration: none;
                        color: #ea6645;
                        vertical-align: top;
                    }
                }

	        } 
	
	        input[type=email], input[type=text], input[type=textarea] {
	            background-color: transparent;
	            border: 1px solid #CCC;
	            border-radius: 0;
	            font-family: 'Arboria-Light', serif;
	            outline: none;
	            width: calc((100% / 3.03) - 30px);
	            margin: 15px;
	            padding: 15px 20px;
	            transition: ease .3s;
	            background: white;
	            box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.05);
	            
	            &.half {
		            width: calc(50% - 30px);
					display: inline-block;
	            }

                &:focus {
                    border: 1px solid #ea6645;
                }
	            
	            @media screen and (max-width:1558px){
                	width: calc(49% - 30px);
            	}
            	
            	@media screen and (max-width:620px){
                	width: calc(100% - 30px);
            	}
	            
	        }
	
	        input[type=textarea], textarea {
                outline: none;
	            width: calc(100% - 31px);
	            min-height: 150px;
	            padding-bottom: 80px;
				margin: 15px;
                margin-bottom: 0;
				padding: 15px;
				border: 1px solid #CCC;
				font-family: 'Arboria-Light', serif;
			    box-shadow: 0 0 50px 0 rgba(0,0,0,.05);
			    
                &:focus {
                    border: 0;
                    outline: 1px solid #ea6645;
                }

	        }
	
	        input[type="checkbox"] {
                //opacity: 0;
                appearance: none;
                background: white;
                border: 2px solid $orange;
                margin-left: 15px;
                margin-right: 5px;
                
                cursor: pointer;
                
                width: 18px;
                height: 18px;
                outline: none;
                
                &:checked {
	                
	               	background-color: $orange;
	               	position: relative;
	               	
	               	&:before { 
		               	content: "";
					   	display: block;
					    position: absolute;
					    top: 0;
					    left: auto;
					    right: 8px;
					    width: 8px;
					    height: 13px;
					    border-top: 2px solid transparent;
					    border-left: 2px solid transparent;
					    border-right: 2px solid #fff;
					    border-bottom: 2px solid #fff;
					    transform: rotate(37deg);
					    transform-origin: 100% 100%;
	               	}
	               	
                }
                
                & + label {
                    width: calc(100% - 50px);
                    display: inline-block;
                    @media screen and (max-width:500px){
                        position: inherit;
                    }
                }
            }
            
            select {
                outline: none;
	            display: inline-block;
			    float: none;
                width: calc(100% - 30px);
                width: calc(33.0033% - 30px);
			    background: white;
			    border: 1px solid #CCC;
			    padding: 15px;
			    margin: 0px 15px 15px 15px;
			    height: 3em;
			    appearance: none;
	            font-family: 'Arboria-Light', serif;
                border-radius: 0;

                &:focus {
                    border: 0;
                    outline: 1px solid #ea6645;
                }


                @media screen and (max-width:620px){
                    width: calc(100% - 30px);
                    margin-top: 20px;
                }
                
                @media screen and (min-width:621px) and (max-width: 1558px){
                    margin-top: 20px;
                	width: calc(49% - 30px);
            	}

                @media screen and (min-width:1559px){
                    margin-top: 0;
                	width: calc(33.00330033% - 30px);
            	}
            	
	            &.half {
		            width: calc(50% - 35px);
		            display: inline-block;
	            }
            }
	
	        span {
	            display: block;
	            width: 100%;
	            text-align: center;
                margin-top: 3em;
                 @media screen and (max-width:768px){
                    margin-top: 6em;
                }
                @media screen and (max-width:415px){
                    // margin-top: 10em;
                }
	        }
	
	        input[type=submit] {
	            clear: both;
	            float: none;
	            display: inline-block;
	            font-size: 20px;
	            text-align: center;
	            padding: 15px 45px;
	            text-transform: uppercase;
	            background: #ea6645;
	            border: 1px solid #ea6645;
	            font-family: 'Arboria-Bold', serif;
	            color: #fff;
	            transition: .3s ease;
	            text-align: center;
                margin-top: 40px;
	        }
	    }
        &.loading form span input[type=submit] {
            background: red;
        }

        .gamme-inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            label.gamme-label {
                background-color: transparent;
                border: 1px solid #CCC;
                border-radius: 0;
                font-family: 'Arboria-Light', serif;
                outline: none;
                margin: 15px;
                padding: 15px 20px;
                transition: all 0.3s ease 0s;
                background: white;
                box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.05);
                text-align: center;
                width: calc((100%) - 30px);

                @media screen and (min-width:620px){
                    width: calc(49% - 30px);
                }

                @media screen and (min-width: 1125px){
                    width: calc(25% - 30px);
                }
            }

            label.gamme-label.checked{
                border: 1px solid #ea6645;
                font-family: 'Arboria-Bold', serif;
            }

            input.gamme-input {
                display: none;
            }
        }

        .product-inputs {

            .product-input-list {
                display: flex;
                transition: .3s ease;
                flex-wrap: wrap;
                
                label.product-label {
                    height: 130px;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column-reverse;
                    padding: 0;
                    cursor: pointer;
                    min-height: 0;
                    outline: none;
                    margin: 15px;
                    padding-bottom: 11px;
                    transition: all 0.3s ease 0s;
                    background: #fff;
                    box-shadow: 0 0 20px 0 rgb(0 0 0 / 6%);
                    border: 1px solid #ccc;
                    font-family: Arboria-Bold,sans-serif;
                    width: calc((100%) - 30px);

                    @media screen and (min-width:620px){
                        width: calc(33.33% - 30px);
                    }

                    @media screen and (min-width: 1125px){
                        width: calc(25% - 30px);
                    }

                    span {
                        margin-top: 10px;
                    }
                }

                label.product-label.checked {
                    background: #ea6645;
                    border: 1px solid #ea6645;
                    font-family: 'Arboria-Bold', serif;
                    color: #fff;
                }
            }

            input.product-input {
                display: none;
            }

            img.product-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                background-position: 50% 0;
                background-size: cover;
                background-repeat: no-repeat;
                transition: all .7s ease;
                transform: scale(1.01);
            }
        }
    }
    
	.cta_download-link{
                text-align: center;
                display: block;
            }
	div.thanks {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        transform: translateY(-50%);
        text-align: center;
        max-width: 1279px;

        h2 {
            text-transform: uppercase;
        }

        p {
            max-width: 567px;
            margin: 10px auto;

            a {
                color: #ea6645;
                text-decoration: underline;

                &.link {
                    clear: both;
                    float: none;
                    display: inline-block;
                    font-size: 20px;
                    text-align: center;
                    padding: 15px 45px;
                    text-transform: uppercase;
                    background: #ea6645;
                    border: 1px solid #ea6645;
                    font-family: 'Arboria-Bold', serif;
                    color: #fff;
                    transition: .3s ease;
                    text-align: center;
                    margin-top: 40px;
                    text-decoration: none;
                    margin-right: 10px;
                }

                &.facebook {
                    background-color: #29487d;
                    clear: both;
                    float: none;
                    display: inline-block;
                    font-size: 20px;
                    text-align: center;
                    padding: 15px 22px;
                    text-transform: uppercase;
                    text-decoration: none;
                    border: 1px solid #29487d;
                    font-family: 'Arboria-Bold', serif;
                    color: #fff;
                    transition: .3s ease;
                    text-align: center;
                    margin-top: 40px;
                    color: rgba(white, 0);
                    margin-left: 10px;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        background-image: url('../img/inbound/facebook.svg');
                        background-size: cover;
                        background-position: 50% 50%;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        top: 50%;
                        transform: translateY(-50%);
                        height: 20px; 
                        width: 20px;

                    }
                }
            }
        }
    }
	
	a.return {
		
	    position: absolute;
	    left: 50px;
	    top: 135px;
	    box-shadow: 0px 0px 20px 0px rgba(50,50,50,0.1);
	    padding: 10px 20px 10px 40px;
        z-index: 9;
        background: #fff;
	    
	    color: black;
	    text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	
	    &:before {
	        content: '';
	        position: absolute;
	        left: 20px;
	        top: 50%;
	        transform: translateY(-50%);
	        background-image: url('../img/inbound/right-arrow.svg');
	        background-size: cover;
	        background-position: 50% 50%;
	        height: 15px;
	        width: 15px;
        }
        @media screen and (max-width:620px){
            display:none;
        }
	}
}
section.type-inbound.inner ul.inbound-list li.inbound-list-block_form{
    background-color: inherit;
    box-shadow: none;
    height: 28vh;
    position: relative;

    @media screen and (max-width: 1535px){
        height: 38vh;
    }

    &:hover h2:before{
        right: -62px;
    }
    &:hover h2:after{
        right: -50px;
    }

    span{
        height: 200px;
    }
    h2{
        background: inherit;
        text-decoration: none;
        text-transform: uppercase;
        padding: 10px 15px;
        border: 3px solid #ea6645;
        position: relative;
        font-size: .8em;
        text-align: center;
        width: 200px;
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        left: 51%;
        font-family: Arboria-Book,sans-serif;

        &:before{
            content: "";
            position: absolute;
            border-width: 5px 8px;
            border-style: solid;
            right: -52px;
            top: 50%;
            border-color: transparent transparent transparent #ea6645;
            height: 0;
            width: 0;
            display: block;
            transform: translateY(-50%);
            transition: .3s;
        }
        &:after{
            content: "";
            color: #ea6645;
            background: #ea6645;
            height: 3px;
            right: -40px;
            width: 50px;
            text-align: right;
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            line-height: 5px;
            font-size: 12px;
            letter-spacing: -5px;
            transition: .3s;
            left: unset;
        }

        @media screen and (max-width: 639px){
            &:after , &:before{
                display: none;
            }
        }
    }
}

body section.type-inbound.incorpored {

    div.template-content {
        padding-top: unset !important;
    }
}