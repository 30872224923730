.template-content-container.documents {
	
	.documents-list-container {
		
		ul {
			
			list-style: none; 
			margin: 1em;
			
			li {
				margin: 0.3em 0;

				a {
					display: flex;
					align-items: center;

					svg {
						height: 16px;
						fill: $orange; 
						margin-right: 0.5em;
					}
				}
				
				a:hover {
					color: darken($orange, 40%); 

					svg {
						fill: darken($orange, 40%); 
					}
				}
			}
		}
		
	}
	
} 