footer {
	z-index: 10;
	position: relative;

	@media screen and (min-width:540px){
		a.button-grey{
			max-width: 280px;
		}
	}
	.footer-content{
	    display: flex;
		flex-wrap: wrap;
		margin: 30px 0;
		font-size: 13px;
		line-height: 17px;
		line-height: 17px;
		margin-top: 0;
		> li{
			width: 24%;
    		margin-right: 1%;
			margin-bottom: 30px;
		}
	}
	.container{
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
    	align-items: center;

		@media screen and (min-width:768px){
			justify-content: space-between;
		}

		@media screen and (min-width:1280px){
			flex-direction: row;
			align-items: start;
		}
	}
	ul {
				list-style : none;
				padding: 0;
				margin: 0;
				
				// float: left;
				// padding: 15px;
				// text-align: left;
				

				li {
					a{
						opacity: .8;
						color: #ffffff;
						font-weight: 800;
						transition: .1s ease-in;
						&:hover{
							opacity: 1;
						}

						svg {
							height: 16px;
							fill: white;
							opacity: .6;

							&:hover {
								opacity: 1;
							}
						}
					}
					ul{
						li a{
							opacity: .6;
							font-weight: 400;
							&:hover {
								opacity: 1;
							}

							svg {
								height: 14px;
								fill: white;
								opacity: .6;

								&:hover {
									opacity: 1;
								}
							}
						}
					}
				}
			}

	div.footer-block {
		overflow:hidden;
		
		div.container {
			width: 1300px;
			margin: 0 auto;
			margin-top: 50px;
			@media only screen and (max-width: 1535px) {
					width: 90%;
			}
		}
		
		&:nth-child(1) {
			background: #494a52;
		}
		&:nth-child(2) {
			background: #393a40;
		}

		.logo img {
			width: 130px;
		}

		div.block-25 {
			width: 25%;
			height: auto;
			float: left;
			text-align: center;
			padding: 30px 30px;

			a {
				width: 100%;
				display: block;
				text-align: left;
				transition: .2s;

				i {
					opacity: .75;
					float: right;
					font-size: 1.3em;
				}

				&:after,&:before {
					content: none;
				}

				&:hover {
					color: $orange;
				}
			}

			p {
				color: $white;
				text-align: left;
			}

			
		}
		.block-13{
			width: 13%;
		}
		.block-15{
			width: 15%;

			.button{
				border: 3px solid grey;
				display: inline-block;
				margin-bottom: 20px;
				min-width: 180px;

				span i {
					position: absolute;
   					right: 20px;
				}
				&:before,  &:after{
					display: none;
				}
			}
			.socials{
			
				li{
					margin: 8px;
					display: inline-block;
				}
			}
		}
		.block-20{
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 20px;

			@media screen and (min-width:1280px){
				width: 20%;
				align-items: start;
				margin-right: 10px;
			}

			.sub {
				display: flex;
				width: auto;
				margin-top: 20px;
				flex-direction: column;
				width: inherit;
				align-items: center;

				@media screen and (min-width:1280px){
					align-items: start;
					width: 100%;
				}

				.button-container {
					display: flex;
					flex-direction: column;

					@media screen and (min-width:768px){
						flex-direction: row;
						column-gap: 20px;
					}

					@media screen and (min-width:1280px){
						flex-direction: column;
						column-gap: unset;
						width: 100%;
					}

					.button{
						border: 3px solid grey;
						display: inline-block;
						margin-bottom: 20px;
						text-align: center;
						min-width: 190px;
	
						@media screen and (min-width:768px){
							text-align: left;
							min-width: 230px;
						}

						@media screen and (min-width:1280px){
							min-width: auto;
						}

						span {
							display: flex;
							align-items: center;
							justify-content: space-between;

							svg {
								fill: white;
    							height: 12px;
								margin-left: 10px;
								transition: all 300ms;
							}
						}
		
						&:before,  &:after{
							display: none;
						}

						&:hover {
							border: 3px solid $orange;
							transition: all 300ms;
							
							span {
								svg {
									fill: $orange;
									transition: all 300ms;
								}
							}
						}
					}
				}

				.socials{
					display: flex;
    				flex-direction: row;
					li{
						margin: 10px;
						display: inline-block;
					}
				}
			}
		}
		.block-65{
			width: 65%;
		}

		.block-75{
			width: 75%;

			.footer-content {
				display: flex;
				flex-wrap: wrap;
				margin: 30px 0;
				font-size: 13px;
				line-height: 17px;
				margin-top: 0;
				word-break: break-word;
			}
		}

		h3 {
			text-transform: uppercase;
			color: $white;
			border-bottom: 1px solid $white;
			text-align: left;
			font-weight: 300;
			padding-top: 20px;
			font-size: 1em;

			display: inline;
			float: left;

			&.social {
				margin-top: 0;
				padding-top: 0;
			}
		}

		.footer-link {
			color: $white;
			display: block;
			float: none;
			clear: both;
			text-align: left;
			text-decoration: none;

			span {
				color: $orange;
			}
		}
	}

	div.copy {
		width: 100%;
		background: #393a40;
		display: block;
		height: 40px;
		// padding-bottom: 115px;

		@media only screen and (min-width: 415px) {
			padding-bottom: 0;
		}

		p {
			margin: 0;
			padding: 15px;
			color: rgba($white,.5);
			text-align: right;
			font-size: .7em;
			display: inline-block;

			&:first-of-type{
				float: left;
				a{
					color: rgba($white,.5);
					text-decoration: none;
					transition: .3s;
					display: inline-block;

					&:hover {
						color: $orange;
						transform: rotate(360deg);
					}
				}
			}
			&:last-of-type{
				float: right;
			}
		}
		a.adjust {
			font-family: "adjust";
			color: rgba($white,.5);
			text-decoration: none;
			transition: .3s;
			display: inline-block;

			&:hover {
				color: $orange;
				transform: rotate(360deg);
			}
		}		
	}
}


//RESPONSIVE

@media only screen and (max-width: 1279px) {

	footer div.footer-block div.container {
		//width: 100%;
	}

	footer div.footer-block div.block-25 {
		width: calc(100% / 3);
	}

}


// RESPONSIVE FOR <600px


@media only screen and (max-width: 1023px) {
	
	footer div.footer-block div.block-25 {
		width: 100%;
		padding-bottom: 0;
	}

	footer div.footer-block h3 {
		padding-top: 10px;
		margin-top: 6px;
	}
}



@media only screen and (max-width: 767px) {
	
	footer div.footer-block div.block-65 {
		width: 85%;
		margin: 0 auto;
		margin-top: 50px;
	}
	footer div.footer-block div.block-15 {
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	footer div.footer-block div.block-13 {
		width: 50%;
		margin: 0 auto;
		text-align: center;
	}
		
	footer div.footer-block .footer-content>li{
		width: 100%;
		padding-right: 20px;
		margin-top: 20px;
		text-align: center;
		padding: 0;
	}
	footer ul.socials{
		text-align: center;
	}
}




















