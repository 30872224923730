body.p10 {

    section {

        &.small-section {

        }

        &.text-overlay {
            flex-direction: row;
            position: relative;

            .block {
                
                &.double {
                    width: 100%;
                    min-height: 400px;
                    height: 500px;
                    box-shadow: none;
                    display: flex;
                    align-items: center;

                    &:before {
                        background: linear-gradient(270deg, transparent, hsla(0, 0%, 8%, .35));
                        mix-blend-mode: luminosity;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }

                    @media all and (max-width: 840px) {
                        height: 400px;
                    }

                    .left,
                    .right {
                        max-width: 90%;
                        position: relative;
                        z-index: 2;
                        float: left;
                        padding: 0;

                        @media only screen and (max-width: 1200px) {
                            padding-left: 50px;
                        }
                    
                        @media only screen and (max-width: 1023px) {
                            padding: 0;
                        }
                    
                        @media only screen and (max-width: 639px) {
                        }
                    }

                    h2, p {
                        color: white !important;
                    }
                }

                &.hide {
                    display: none;
                }

            }
        }

        &#block {
            //PERFORMANCES
            &5647,
            &5658,
            &5659,
            &5660,
            &5661,
            &5662,
            //COMBUSTION PARFAITE
            &1000,
            &2216,
            &2225,
            &2234,
            &2243,
            &2252,
            &2261
             {
                 img {
                     object-fit: contain;
                 }
            }

            //RedDot
            &1005,
            &2227,
            &2218,
            &2236,
            &2245,
            &2254,
            &2263 {
                h2 {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: flex-end;

                    &:before {
                        content: '';
                        background-image: url('../img/reddot_award_logo.jpg');
                        height: 70px;
                        width: 220px;
                        background-repeat: no-repeat;
                        background-size: contain;

                        @media only screen and (max-width: 1279px) {
                            width: 300px;
                        }

                        @media only screen and (max-width: 1200px) {
                            width: 220px;
                        }
                    
                        @media only screen and (max-width: 639px) {
                            height: 70px;
                        }
                    }
                }
            }
        }
        
    }
}