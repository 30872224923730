div.contact {

	.close {
		position: absolute;
		right: 1em;
		top: 0.5em;
		line-height: 1em;
		font-size: 3em;
		color: $white;
		cursor: pointer;
		
		@media only screen and (max-width: 480px) {
			right: 0.2em;
			top: -0.1em;
		}		
	}
	
	position: fixed;
	background: #FFF;
	width: 100vw;
	height: 100vh;
	z-index: 7000;
	overflow: scroll;
	
	transform: translate3d(0,100%,0);
	transition: 0.4s;
	
	&.active {
		transform: translate3d(0,0,0);	
		display: none;
	}
	
	
	.intro {
		padding-top: 150px;
		padding-bottom: 4em; 
		background: #494a52;

		@media only screen and (max-width: 1023px) {
			padding-top: 80px;
		}
		
		@media only screen and (max-width: 480px) {
			margin-top: 0;
			padding: 1em;
			padding-top: 1.5em;
		}
		
		width: 100%;
		
		h2, p, .title {
			display: block;
			text-align: center;

			@media only screen and (max-width: 1023px) {
				width: 100%;
				padding: 20px;
			}
		}
		
		h2, .title {
			
			color: $white;
			text-transform: uppercase;
			font-family: "Arboria-Bold";
			font-size: 2em;
			margin: 0;
			position: relative;
			
			&:after {
				content: "";
				display: block;
				width: 75px;
				height: 5px;
				background: $orange;
				
				position: absolute;
				bottom: -0.75em;
				left: 50%;
				
				transform: translateX(-50%);

				@media only screen and (max-width: 567px) {
					display: none;
				}
			}
		}
		
		p{
			color: $hard-grey;
			font-family: "Arboria-Book";
			font-size: 1em;
			margin: 3em 1em;
			padding: 0 20%;

			@media only screen and (max-width: 639px) {
				margin: 0;
    			padding: 20px;
    			margin-top: 30px;
			}
		}
	}
	
	@at-root {
	
		.form-contact {
			
			max-width: 1279px;
			margin: 0 auto;
			// background: #F7F7F7;
			padding: 1em;
			padding-top: 35px;
			
			input, select, textarea {
				font-family: "Arboria-Book";
				&.warning {
					
					border-bottom-color: tomato !important;
	
					&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
					  color: tomato;
					}
					&::-moz-placeholder { /* Firefox 19+ */
					  color: tomato;
					  opacity: 1;
					}
					&:-ms-input-placeholder { /* IE 10+ */
					  color: tomato;
					}
					&:-moz-placeholder { /* Firefox 18- */
					  color: tomato;
					}
				}
			}
			
			textarea {
				padding: 0.5em;
				height: 200px;
			}
			
			select {
				background: transparent;
				color: $placeholder-text-color;
				border: none;
				border-bottom: 1px solid $input-border-color;
				display: block !important;
				border-radius: 0px !important;
				padding-left: 0 !important;
				appearance: none;
				&:focus {
					outline: none;
				}
			}
			
			input[type=submit] {
				
				display: block;
				width: 100%;
				text-align: center;
				padding: 0.5em;
				text-transform: uppercase;
				background: $orange;
				border: 1px solid $orange;
				font-size: 1.2em;
				color: #FFF;
				transition: 0.4s;
				
				&:hover {
					color: $orange;
					background: white;
				}
			}
		}
	
	}
	
	.material_special_select {
		
		padding-left: 0 !important;
		padding-right: 0 !important;
		
		margin-top: auto !important;
		
		select[multiple] {
			position: absolute;
			width: 0px;
			height: 0px;
			transform: translateX(-100vw);
		}
		
		@media only screen and (min-width: 480px) {
		
			.select-wrapper span.caret {
				right: 1.5em;
			}
			
			input.select-dropdown {
				box-sizing: border-box;
				padding: 20px;
			}
		
		}
	}
		
}

div.contact-thanks {
	
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;

	top: 0;
	left: 0;
	z-index: 200;
	
	background: rgba(#000, 0.9);
	
	.inner-content {
		
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		
		padding: 1em;
		background: white;
		
		.close {
			color: $hard-grey;
			font-size: 1.3em;
			cursor: pointer;
			
			position: absolute;
			right: 0.5em;
			top: 0.25em;
			line-height: 1em;
			
			cursor: pointer;
		}
		
		p {
			font-family: "Arboria-Book";
			font-size: 1.2em;
			text-align: center;
			color: $hard-grey;
		}
	}
	
}











