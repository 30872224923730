$white: white;
$orange: #ea6645;
$black: #1D1D1D;
$emerald: #1abc9c;
$light-grey: #f9f9f9;
$hard-grey: #494a52;
$blue: #007ec6;
$grey: #AFAFAF;

$shadow: 0px 0px 70px 0px rgba(0,0,0,0.1);

$transition-more: .8s;

$primary-color: $grey;
$secondary-color: $orange;

$headerHeightSmall: 90px;
$headerHeightLarge: 114px;