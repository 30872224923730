html {
  box-sizing: border-box;
  scroll-padding-top: 90px;
scroll-behavior: smooth;

	@media all and (min-width: 1280px) {
		scroll-padding-top: 115px;
	}
}

*, *:before, *:after {
  box-sizing: inherit;
}

.fancybox-loading {
	animation-name: fancybox-rotate !important;
}

@keyframes fancybox-rotate {
	100% {
		transform: rotate(360deg);
	}
}

body {
	
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: .5s;
	
	&.noscroll {
		overflow: hidden;
	}
	
	a {
		cursor: pointer;
		color: #ea6645;
		text-decoration: none;
	}

	/*.small_section {
		
		display: inline-block;
		
		&:after {
		  content: "";
		  display: table;
		  clear: both;
		}
	}*/
	
	*[data-animation] {
		transition: background-image 0.5s linear;
	}
	
	.cta-full-page {
		
		width: 250px;
		height: 200px; 
		
		display: block;
		background-size: contain;
		background-position: center center;
		//background-repeat: no-repeat; 
		
		position: absolute;
		left: 1em;
		bottom: 1em;
		
		z-index: 10;
		
		@media only screen and (max-width: 1279px) {
			left: auto;
			right: 1em;		
			width: 120px;
			height: 150px;
		}
		
		@media only screen and (max-width: 1023px) {
			display: none;
		}
	}


	// h1, h2, h4, h5 {
	// 	word-break: break-word;
	// 	font-family: 'Arboria-Bold', sans-serif;
	// 	color: $black;
	// }

	p, li/*, h3*/ {
		font-family: 'Arboria-Book', sans-serif;
		font-weight: 300;
	}
	 
	 
	.dark, .light {
		.button {
			// background: none !important;
		}
	}
	
	.dark {
		background: #494a52;
		
		h2, p, a, li {
			color: #FFF !important;
		}
		
		
		.debug_id a {
			color: #000 !important;
		}
		
	}
	
	.light {
		
		background: #FFF;
		
		h2,h3, p, a, li { 
			color: #1D1D1D !important;
		}

		a.button{
			background-color: inherit;
		}


		
	}
	
	.hidden {
		opacity: 0 !important;
	}
	
	.clearfix {
		&:before,
		&:after {
		    content: "";
		    display: table;
		} 
		&:after {
		    clear: both;
		}
		& {
		    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
		}
	}

	.mobile_godown {
		display: none;
	}
	
	.debug_id {
		
		position: absolute;
		z-index: 30;
		
		bottom: 1em;
		left: 1em;
		
		padding: 0.5em 1em;
		font-size: 1.3em;
		
		background: #FFF;
		color: #000;
		
		border: 1px solid #000;
		
		a {
			color: #000 !important;
			text-decoration: none !important;
			&:hover {
				text-decoration: underline;
			}
		}
		
		.prio {
			position: absolute;
			display: block;
			
			bottom: 2px;
			right: 2px;
			
			font-size: 0.5em;
			color: #000;
		}
		
		&:before {
			content: "ID";
			position: absolute;
			display: block;
			
			top: 2px;
			left: 2px;
			
			font-size: 0.5em;
			color: #000;
		}
	}

	a {
		font-family: 'Arboria-Book', sans-serif;
		font-weight: 300;

		span.stuvdays{
			color: #ea6645;
		}


			&.button {
				color: $white;
				text-decoration: none;
				text-transform: uppercase;
				padding: 10px 12px;
				border: 3px solid $orange;
				background: rgba($black, .4);
				position: relative;
				font-size: .8em;
				display: table;
				
				//margin-top: 50px;
                
                &:before {
                    content: "";
                    position: absolute;
                    border-width: 5px 8px;
                    border-style: solid;
                    right: -62px;
                    top: 50%;
                    border-color: transparent transparent transparent #ea6645;
                    height: 0;
                    width: 0;
                    display: block;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    -webkit-transition: .3s;
                    transition: .3s;
                    
					@media only screen and (max-width: 1023px) {
						display: none;
					}
                }

				&:after {
				    content: '';
				    color: $orange;
				    background: $orange;
				    height: 3px;
				    right: -50px;
				    width: 60px;
				    text-align: right;
				    display: block;
				    position: absolute;
				    top: 50%;
				    transform: translateY(-50%);
				    line-height: 5px;
    				font-size: 12px;
    				letter-spacing: -5px;
    				transition: .3s;
				}
                
                &:hover:before {
                    right: -72px;
                }

				&:hover:after {
					right: -60px;
				}
			}

			&.button-grey {
				border: 3px solid grey;

				&:hover {
					border: 3px solid $orange;
				}

				&:after {
					color: grey;
					background: grey;
				}
			}
	}
	
	.cta-holder {
		
		    position: absolute;
		    bottom: 20px;
		    right: 20px;
		    height: auto;
		    background: rgba(29,29,29,.8);
		    z-index: 90;
		    
		    @media only screen and (max-width: 767px) {
				top: 65vh;
				bottom: auto;
				width: calc(100% - 40px);
				text-align: center;
			}
		    
		    &.white {
			    background: white;
		    }
		    
		    &.left {
			    right: auto;
			    left: 20px;
		    }
		    
		    .link {
			    
			    display: block;
			    width: 100%;
			    text-transform: uppercase;
			    color: $hard-grey;
				font-size: 1.2em;
				text-align: center;
				
				padding: 0 1.2em;
				padding-bottom: 1.2em;
				
				font-weight: bold;
				
				text-decoration: none;
				transition: 0.4s;
				
				span {
					color: $orange;
				}
		    }
		    
		    &:hover {
			    
				.link {
					color: $orange;
				}
		    }
		    
	}
	
	div.video-bg {

		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%; 
		overflow: hidden;

		// iframe {
		// 	position: absolute;
		// 	top: -10%;
		// 	left: -10%;

		// }

		video, iframe {
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
	}


	section.full {
		// height: 2200px;
		height: 101vh;
		width: 100%;
		overflow: hidden;
		position: relative;
		// background-position: fixed !important;
		background-position: center center;
		background-size: cover;
		background-repeat: none;


		picture {
			height: inherit;
			object-fit: contain;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;

			img {
				width: 100%;
				object-fit: cover;

				@media only screen and (min-width: 680px) {
					height: 50vh;
				}

				@media only screen and (min-width: 1200px) {
					height: 100vh;
				}
			}
		}
		
		&.header-page {
			height: 100vh;
			background-position: top left;

			picture {
				height: inherit;
				object-fit: contain;
				display: flex;
				justify-content: start;
				align-items: center;
				overflow: hidden;
	
				@media only screen and (min-width: 1330px) {
					justify-content: center;
				}
	
				img {
					width: 100%;
					height: 100vh;
					object-fit: cover;
	
					@media only screen and (max-width: 1023px) {
						// height: 100vh;
						// max-width: none;
						// transform: translateX(-20%);
						// position: relative;
						// width: 100%;
						// max-height: inherit;
					}

					@media only screen and (max-width: 639px) {
						height: inherit;
					}
				}
			}
			
			&.entreprise {
				//height: 65vh;

				div.infos.first-home {
					background: linear-gradient(-90deg,transparent 30%,hsla(0,0%,8%,.9));

					.content{
						position: absolute;
						top: 10%;
						left: 0;

						@media only screen and (min-width: 1024px) {
							top: 50%;
						}

						@media only screen and (min-width: 1280px) {
							margin-left: 50%!important;
							width: 50%;
							top: 10%;
						}
					}
				}
				
				.godown {
					//display: none !important; 
				} 
				
				h1.title {
					display: none;
				}
			}
		}
		
		div.infos {
			
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0; 
			left: 0;
			z-index: 9;
		
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;

			div.content {
				position: absolute;
				z-index: 99;
	
				top: 50%;	    		
	    		transform: translateY(-50%);
	
	    		a {
	    			//top: 25px;
	    			display: inline-block
	    		}
	
				h1, h2 {
					color: $white;
					// font-family: 'Arboria-Light' , sans-serif;
					// text-transform: uppercase;
					// font-size: 2.3vw;
					// font-weight: 300;
					// line-height: 1em;
					// position: relative;
					// margin-top: 0 !important;
 	
					// @media only screen and (max-width: 1535px) {
					// 	font-size: 2.2em;
					// }
					// @media only screen and (max-width: 567px) {
					// 	font-size: 2.2em;
					// 	font-size: 2em;
					// 	padding-left: 0;
					// 	margin-bottom: 1.5em;
					// }
	
					// &:after {
					// 	content: '';
					// 	position: absolute;
					// 	height: 3px;
					// 	width: 130px;
					// 	background: $orange;
					// 	bottom: -20px;
					// 	left: -20px;
					// }
	
				}

				h1 {
					// font-family: 'Arboria-bold' , sans-serif;
					// font-size: 2.5vw;

					// @media only screen and (max-width: 1535px) {
					// 	font-size: 2.2em;
					// 	margin-bottom: 1em;
					// }
					// @media only screen and (max-width: 567px) {
					// 	font-size: 2em;
					// 	margin-bottom: 1em;
					// }
				}

				strong {
					color: white;
    				text-transform: uppercase;
    				font-weight: 600;
					font-family: Arboria-Book,sans-serif;
					font-size: 1.17em;
				}

				.sitename_small {
					display: block;
					font-size: 3.2em;
					font-family: Arboria-Bold,sans-serif;
					letter-spacing: -.2vw;
					padding-left: 0;

					@media only screen and (min-width: 1201px) {
						display: none;
					}

				}

				h3 {
					color: white;
    				text-transform: uppercase;
    				// font-weight: 600;
				}
	
				p, li {
					color: $white;
					font-weight: .9em;
					line-height: 1.5em;
					padding-right: 7vw;
	
				}

				ul {
					padding-left: 15px;
				}

				li {
					padding-right: 0;
					line-height: 1.6em;
				}
	
				a {
					color: $white;
					text-decoration: none;
					text-transform: uppercase;
					padding: 10px 20px;
					border: 3px solid $orange;
					
					font-size: .9em;
	
				}
			}
			
			&.right {
				background: linear-gradient(to right, rgba(0,0,0,0) 50%,rgba(20, 20, 20, .9) 100%);
				div.content {
					margin-left: 50%;
					width: 40%;
					padding-right: 20px;
					
					@media only screen and (max-width: 1535px) {
						margin-left: 50% !important;
						width: 50%;	
					}

				}
			}
				
			&.left {
				background: linear-gradient(to left, rgba(0,0,0,0) 20%,rgba(20, 20, 20, .9) 100%);
				div.content {
					padding-left: 10%;
					max-width: 45%;
					
					p, li {
						padding-right: 0; 
						@media only screen and (max-width: 1279px) {
							padding-right: 2em;
						}
					} 					
				}
			}
			
			&.light {
				background: none;
			}
			
			
			&.darken-video {
				background: linear-gradient(320deg,transparent 75%,hsla(0,0%,8%,.9)); 
                //background: none;
			}
						
			&.first-home {
				background: none;
				background: linear-gradient(to right, rgba(0,0,0,0) 50%,rgba(20, 20, 20, .9) 100%);
				background-position: top left;
				
				div.content {
					
					position: relative;
					z-index: 10;
					padding-top: 280px;
					padding-top: 19.5vw;
					padding-top: 31.5vh;
					margin-top: 0;
					
					top: auto;	    		
					transform: translateY(0%);
		
				}
			}

		}

		.line {
		  // position: fixed;
		  bottom: 0;
		  height: 20px;
		  // transform: rotate(90deg);
		  width: 0px;
		  background-color: white;
		  left: 0px;
		  z-index: 9999;
		}

		.header-infos-1, .header-infos-2, .header-infos-3, .header-infos-4{
			display: initial;
			// position: fixed;
			width: 100%;
			z-index: 9;
		}

		.header-infos-1 {
			display: block;
		}
		.header-infos-2, .header-infos-3, .header-infos-4 {
			display: none;
		}

		div.scroll-down {
			width: 45px;
			height: 45px;
			background: url(../img/cartouche-bas.svg) no-repeat center center; 
			background-size: cover;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 9;
			transition: .3s;
			cursor: pointer;
			display: none;

			&:hover {
				bottom: -5px;
			}
		}

		div.super-scroll {
			width: 45px;
			height: 45px;
			background: url(../img/cartouche-bas.svg) no-repeat center center; 
			background-size: cover;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 9;
			transition: .3s;
			cursor: pointer;
			z-index: 2;
			display: none;

			&:hover {
				bottom: -5px;
			}
		}

		div.background {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;

			// pointer-events: none
		}

		/*
		& > .sitename, 
		& > h1 {
			
			// position: relative; // removed
			position: absolute;
			z-index: 100;
			text-transform: uppercase;
			color: rgba($white,.95);
			font-size: 7.5vw;
			
			font-family: Arboria-Bold,sans-serif;
			
			margin: 0;
			padding-top: 245px;
			padding-top: 17.5vw;
			padding-top: 28vh;
			padding-left: 240px;
			padding-left: 17vw;
			float: left;
			padding-right: 20px;

			letter-spacing: -0.4vw;

			@media only screen and (max-width:1285px) {
				font-size: 6.4vw;
			}
			// position: fixed;
			@media only screen and (min-width: 1275px) and (max-width:1285px) {
				padding-top: 28vh;
    			padding-left: 14vw;
			}
		}	
		*/

		strong.sitename{
			display: none;

			@media only screen and (min-width: 1201px) {
				display: block;
				position: absolute;
				z-index: 100;
				text-transform: uppercase;
				color: rgba($white,.95);
				font-size: 7.5vw;
				font-family: Arboria-Bold,sans-serif;
				margin: 0;
				padding-top: 31vh;
				padding-left: 100px;
				padding-right: 20px;
				float: left;
				letter-spacing: -0.4vw;
			}

			@media only screen and (min-width: 1280px) {
				padding-left: 17vw;
				padding-top: 28vh;
			}
		}
	}
	section.entreprise strong.sitename{
		@media only screen and (max-width: 414px) {
			display: none;
		}
	}
	section.block-360, section.block-demo { 
		height: 100vh;
		width: 100%;
		position: relative;
		z-index: 10; 
		overflow: hidden;

		.turnaround .turnaround-slider {
			z-index: 1000; 
		}
		
	}
	
	div.block {
		
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		min-height: 330px;
		height: 50vh;
		float: left;
		
		position: relative;
		@media only screen and (max-width: 1535px) {
			min-height: 440px;
		}

		h2 {
			font-family: 'Arboria-Light',sans-serif;
			color: $white;
			text-transform: uppercase;
			font-weight: 300;
			position: relative;
			margin-top: 0;
			    // margin-top: 2vw;

			@media only screen and (max-width: 1535px) {
				font-size: 2.2em;
			}
			@media only screen and (max-width: 567px) {
				font-size: 2.2em;
				font-size: 2em;
			}

			&:after {
				content: '';
				position: absolute;
				height: 3px;
				width: 130px;
				background: $orange;
				bottom: -12px;
				left: -20px;
			}
		}

		
		p {
			@media only screen and (max-width: 1535px) {
				font-size: .9em;
			}
		}
		
		.button {
			top: 1.6em;
			display: inline-block;
			line-height: 1.6em;
		}
		
		&.half {
			
			width: 25%;
			&.double { width: 50%; }
			padding: 50px;
			
			h2 {
				// font-size: 2.3vw;
			}
			
		}
		
		&.double {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.4);
			
			@extend .clearfix;
			
			&.left {
				background-position: right center;
			}
			
			&.right {
				background-position: left center;
			}
			
			.right {
				float: right;
			}
			
			.left {
				float: left;
			}
		}
		
		&.full {
			width: 50%;
			&.double { width: 100%; }
			padding: 100px;
			
			@media only screen and (max-width: 1535px) {
				padding: 40px;
			}
			
			h2 {
				// font-size: 2.3vw;
				@media only screen and (max-width: 1023px) {
					// overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			
			p {
				line-height: 1.6em;
			}
		}

	}
	
	div.video {
		
		position: relative;
		
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		
		padding: 0 !important;
		
		img.play-video {
			width: 15%;
			top: 50%;
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			transform: translateY(-50%);
			text-align: center;
			display: block;
			opacity: .8;
			z-index: 99;
			cursor: pointer;
		} 

		iframe.video-iframe {
			opacity: 0;
		
		}

		iframe.video-on {
			opacity: 1;
			z-index: 999;
			position: relative;
		}
	}

	section.block-demo {
		height: 100vh;
		width: 100%;
		position: relative;
		z-index: 10;
		overflow: hidden;
		background: $white;

		img.demo-block-img {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: auto;
			z-index: 10;
			// box-shadow: 0px 0px 155px 1px rgba(0,0,0,0.1);
		}

		div.content-center {
			position: absolute;
			margin: 0 auto;
			top: 50%;
			left: 0;
			right: 0;
			text-align: center;
			transform: translateY(-50%);

			a {
			color: $white;
				text-decoration: none;
				text-transform: uppercase;
				padding: 10px 20px;
				border: 3px solid $orange;
				background: rgba($black, .4);
			}
		}

		div.no-overlay {
				background:none;
			}

		.infos {

			p {
				padding-right: 50px !important;
				margin-bottom: 0px !important;

			}
		}



		div.block-50, div.content-list {
			width: 50%;
			height: 50%;
			float: left;
			padding: 100px;
			position: relative;
			background: $white;

			@media only screen and (max-width: 1535px) {
				padding: 80px;
			}

			img.play-video {
				width: 15%;
				top: 50%;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				transform: translateY(-50%);
				text-align: center;
				display: block;
				opacity: .8;
				z-index: 99;
				cursor: pointer;
			}

			iframe.video-iframe {
				opacity: 0;
			
			}

			iframe.video-on {
				opacity: 1;
				z-index: 999;
				position: relative;
			}

			h2 {
				color: $black;
				font-family: 'Arboria-Light' , sans-serif;
				text-transform: uppercase;
				font-size: 2.3vw;
				font-weight: 300;
				line-height: 1em;
				margin-top: 0;
				position: relative;


				@media only screen and (max-width: 1535px) {
					font-size: 2.2em;
				}

				&:after {
					content: '';
					position: absolute;
					height: 3px;
					width: 130px;
					background: $orange;
					bottom: -20px;
					left: -20px;
				}
			}
			p {
				color: $black;
				font-weight: .9em;
				line-height: 1.5em;

			}

			&.video {
				padding: 0;
			}

			a {
				color: $black;
				margin-top: 30px;
				background: none;
				top: 20px;
				transition: .3s;

			&:hover {
				background: rgba($black, .05),
				}
			}
		}

		div.content-list {
			position: absolute;
			top: 50%;
    		transform: translateY(-50%);
    		padding: 0;
    		padding-left: 100px;
    		height: auto;

    		h2 {
    			margin-top: 0;
    		}

    		ul {
				margin: 0;
  				padding: 0;
  				list-style-type: none;

    			li {
    				counter-increment: step-counter;
  					margin-bottom: 20px;
  					// margin-left: 20px;
  					@media only screen and (max-width: 1535px) {
						margin-bottom: 5px;

					}

  					p {
  						padding-left: 40px;

						@media only screen and (max-width: 1535px) {
							margin-top: 5px;
							padding-right: 0 !important;
						}
  					}
  					
  					&:before {
						  content: counter(step-counter);
						  margin-right: 5px;
						  // margin-bottom: 50px;
						  font-size: 80%;
						  background-color: $black;
						  color: white;
						  font-weight: bold;
						  padding: 4px 8px;
						  float: left;
						  
  					} 
    			}
    		}
		}
	}

	section.block-demo-half {
		height: 50vh;

		div.block-25 {
			height: 100%;

			a {
				//top: 25px;
			}
		}
	}

	section.block-demo-page {

		ul {

			li {

				p {
					padding-left: 0px !important;
				}

				&:before {
					content: none;
					display: none;
				}
			}
		}

	}


	section.gamme {

		position: fixed;
		z-index: 999999;
		top: 0;
		left: 0;
		transform: translateY(-100vh);
		transition: .5s;		
		background: #26262b;
		opacity: 1;

		ul {
			padding: 0;
			margin: 0;
			width: 100%;
			height: 100%;
			list-style: none;
			background: rgba(29,29,29,0);
			overflow-y: scroll;

			li {
				width: calc(100% / 3);
				height: calc(100% / 3);
				float: left;
				overflow: hidden;
				transition: 3s;
				position: relative;
				overflow: hidden;

				&:hover .background-gamme{
					transform: scale(1.5);
					transition: 15s;
				}

				div {
					background-position: 50% 50% !important;
					background-repeat: no-repeat !important;
					background-size: cover !important;
					height: 100%;
					width: 100%;
					position: absolute;

					picture {
						height: 100%;
						width: 100%;

						img {
							object-fit: cover;
							height: 100%;
							width: 100%;
						}
					}

					&.fix-position {
						background-position: 50% 75% !important;
					}


					&:hover {
						transform: scale(1.5);
					}
				}

				
				a {
					
					height: 5vw;
					width: 100%;
					display: block;
					transition: .3s;
					background: rgba($black, .6);
					text-decoration: none;
					position: absolute;
					bottom: 0;
					@media only screen and (max-width: 414px) {
					    height: 12vh;
					}


					p {
						color: $white;
						padding: 0;
						margin: 0 auto;
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
						transform: translateY(-50%);
						text-align: center;
						text-transform: uppercase;
						font-size: 1.4vw;
					}
				}

				&:hover a {
					height: 100%;
				}

				&:hover {
					// background-position: 40% 50% !important;
					// background-size: 100% !important;
				}
			}

			.close-gamme {


				position: absolute;
			    top: 20px;
			    right: 30px;
			    width: 65px;
			    height: 65px;
			    z-index: 9;
			    padding: 10px;
			    transition: .4s;

			    &:hover {
			    	background: rgba(black, .4);
			    }

				img {
					width: 100%;
					transition: .3s;

					&:hover {
						transform: rotate(90deg);
					}
				}
				
				// cursor: pointer;

				// a {
				// 	height: 100%;
				// 	width: 100%;
				// 	display: block;
				// 	opacity: 1;
				// 	transform: translateY(0);
				// 	transition: .3s;
				// 	background: rgba($black, 0);
				// 	text-decoration: none;
				// 	position: relative;
				// 	color: $white;
				// 	text-transform: uppercase;
				// 	font-size: 1.4em;
				// 	transition: .3s;

				// 		&:hover {
				// 			// color: $orange;
				// 		}

				// 	p {
				// 		top: 50%;
				// 		transform: translateY(-50%);
				// 		bottom: initial;
				// 		color: inherit;

				// 	}

				// span {
				//     position: absolute;
				//     cursor: pointer;
				    
				//     text-align: center;
				//     margin: 0 auto;
				//     transform: translate(-50%, -50%);
				// }

				// span:before, span:after {
				// 	content: '';
				// 	position: absolute;
				// 	width: 0%;
				// 	height: 2px;
				// 	bottom: 0;
				// 	margin-top: 0px;
				// 	background: #fff;
				// }

				// span:before {
				//   	left: 0px;
				// }

				// span:after {
				// 	right: 0px;
				// 	background: $orange;
				// 	transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
				// }

				// span:hover:before {
				// 	background: $orange;
				// 	width: 100%;
				// 	transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
				// }

				// span:hover:after {
				//   	background: transparent;
				//   	width: 100%;
				//   	transition: 0s;
				// }
				

					 

						// img {
						// 	width: 50px;
						// 	display: block;
						// 	margin-left: 50%;
						// 	margin-bottom: 20px;
   	 	// 					transform: translateX(-50%);
   	 	// 					transition: .3s;

   	 	// 					// &:hover {
   	 	// 					// 	transform: rotate(45deg);
   	 	// 					// }
						// }
					
				//}
			}
		}
	}
}


body.open-gamme > section.block-demo.gamme {
	transform: translateY(0);
}

body.open> section.block-demo.gamme {
	transform: translateY(-100vh);
}

#perfomances {
	background-size: auto 100% !important;
}



section.third {
	
	width: 100%;
	height: 320px;
	position: relative;
	clear: both;

	div.block_background {
		width: inherit;
    	height: inherit;

		picture {
			width: inherit;
    		height: inherit;
			background-size: cover;

			img {
				width: inherit;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&.left {
		div.content {
			left: 0;
		}
	}
	
	&.right {
		div.content {
			right: 40px;

			@media only screen and (max-width: 1279px) {
				right: unset;
			}
		}
	}
	
	&.clear {
		height: 0px;
		background: $hard-grey;
	}

	div.infos {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0; 
			left: 0;

			div.content {
			position: absolute;
			z-index: 99;
			padding: 0 0 0 40px;
			top: 50%;
			margin-left: 0 !important;
    		transform: translateY(-50%);

    		a {
    			
    			display: inline-block
    		}

			h2 {
				color: $white;
				font-family: 'Arboria-Light' , sans-serif;
				text-transform: uppercase;
				// font-size: 2.3vw;
				font-weight: 300;
				line-height: 1em;
				position: relative;
				margin-top: 0 !important;

				@media only screen and (max-width: 1535px) {
					font-size: 2.2em;
				}

				&:after {
					content: '';
					position: absolute;
					height: 3px;
					width: 130px;
					background: $orange;
					bottom: -20px;
					left: -20px;
				}

			}

			p {
				color: $white;
				font-weight: .9em;
				line-height: 1.5em;
				margin-bottom: 20px;
				 padding-right: 11vw;

			}

			a {
				color: $white;
				text-decoration: none;
				text-transform: uppercase;
				padding: 10px 20px;
				border: 3px solid $orange;
				font-size: .8em;

			}
		}
	}
}



div.scroll-down {
			width: 45px;
			height: 45px;
			background: url(../img/cartouche-bas.svg) no-repeat center center; 
			background-size: cover;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 9;
			transition: .3s;
			cursor: pointer;

			&:hover {
				bottom: -5px;
			}
		}

// SPECIAL ID RULES


.mobile_background {
	display: none;
}




// RESPONSIVE FOR <1200px

@media only screen and (max-width: 1279px) {

	nav.global {
		width: 430px !important;
	}

	body.open>:not(nav) {
		// transform: translateX(300px) !important;
	}

	body section.full h1 {
		z-index: 10;
	}
	body section.full div.background {
		z-index: 11;
		pointer-events: none;
	}

	body section.full div.infos.right div.content {
		margin-left: 50%;
		width: 48%;
		width: auto;
	}

	body section.full div.infos.left div.content {
		width: auto;
		padding-left: 0;
	}

	body section.full div.infos.right {
		background: linear-gradient(90deg,transparent 0%,hsla(0,0%,8%,.9));
	}

	body section.full div.infos.light {
		background: none;
	}

	// body div.block .button {
	// 	font-size: .8em;
	// }

	// body div.block .button {
	// 	display: inline-block;
	// }

	body section.full {
		background-position: 50% 0;
	}

	body section.full div.infos div.content p {
		padding-right: 5px;
	}

	body section.full div.infos {
		z-index: 12;
	}

}


// RESPONSIVE FOR <1024px


@media only screen and (max-width: 1279px) {
	body div.block.half {
		width: 50%;
		height: auto;
		min-height: 300px;
	}

	body div.block.full {
		width: 100%;
		height: auto;
		padding: 40px;
		min-height: 400px;

		&.video {
			min-height: 400px;
		}
	}

	body section.full div.infos.left div.content,
	body section.full div.infos.right div.content {
		margin-left: 0 !important;
		width: 100%;
		padding: 100px;
	} 

	body section.full div.infos.first-home div.content {
		position: absolute;
    	top: 50%;
    	transform: translateY(-50%);
	}

	body section.full div.background {
		display: none;
	}

	body section.full h1 {
		//display: none;
	}

	body div.block.full h2 {
		    // font-size: 2.2em;
	}

	header #nav-icon {
		margin-right: 30px;
	}

	header ul li {
		padding: 0 15px;
	}

	header .socials , .mystuv-link {
		display: none;
	}

	header ul.shares {
		display: none;
	}

	header .share {
		display: none;
	}

	header div.langue {
	    float: right;
	    width: auto;
	    display: inline-block;
	    border: none;
		padding: 5px 0;
		display: none;
	}

	body section.gamme ul li {
		width: 50%;
		height: calc(100% / 4);
	}

	body section.full div.infos.left div.content {
		max-width: 90%;
	}

	body section.full {
		min-height: 50vh;
		height: auto;
	}

	body section.full div.infos.right {
		background: linear-gradient(270deg,transparent,hsla(0,0%,8%,.9));
	}

    body section.full div.infos.light {
		background: none;
	}

	.section_light {
		background: white !important;
	}

	body section.full div.infos div.content p {
		padding-right: 0;
	}
}

@media only screen and (max-width: 567px) {
body div.block.full{
	padding: 20px;
	min-height: 300px;
}
body div.block.half{
	width: 100%;
}
body section.full div.infos.first-home div.content {
		padding: 20px;
	    left: 10%;
    	top: 50%;
	}
}
// RESPONSIVE FOR <600px


@media only screen and (max-width: 1023px) {
	section.third {
		overflow: hidden;
	}

	body section.full div.infos.right div.content, 
	body div.block.full, 
	body section.full div.infos.left div.content,
	body div.block.half,
	section.third div.infos div.content {
		padding: 40px;
	}


	body section.full div.infos.right, body section.full div.infos.left {
		position: static;
	}

	body section.full div.infos.right div.content, .body section.full div.infos.left div.content, body section.full div.infos.left div.content {
		position: relative;
    	transform: none;
	}

	body div.block.half {
		width: 100%;
	}

	body div.block.half h2 {
		font-size: 2.2em;
	}
	section.third div.infos div.content a /*, body div.block .button*/ {
		max-width: 80%;
	}

	body section.full div.infos.first-home div.content {
    top: auto;
    // padding-top: 150px;
    padding-bottom: 100px;
    position: static;
	}

	body section.full.header-page {
		height: auto;
	}

	body section:first-of-type  div.infos.right div.content {
		// padding-top: 140px;
	}


	//nav
	

	header ul {
		margin: 0;
    	text-align: center;
    	width: 100%;
    	padding-top: 15px;

    	display: flex;
    	flex-grow: 1;
	} 
	header ul li a.active {
			color: $orange;
		}

	header ul li {
		//font-size: 3.9vw;
		font-size: 3vw;
		padding: 0;
		text-align: center;



		&:nth-child(1) {
			text-align: left;
		}

		&:last-child {
			text-align: right;
		}
	}

	header div.langue {
		position: absolute;
		right: 30px;
		display: none;
	}

	header ul li .active span:before, header ul li span:hover:before, header ul li .active span:after, header ul li span:hover:after {
		content: none;
	}

	nav.global {
		width: 100% !important;
	}

	nav.global>ul li a, nav.global>ul li span {
		font-size: 1em !important;
	}


	//First Full block 

	body section:first-of-type .mobile_background {
		height: 100vh;
		max-width: initial;
		position: relative;
		
		@media only screen and (max-width: 1023px) {
			width: 100%;
			object-fit: cover;
			// max-height: 35vh;
			max-height: 50vh;
		}

		&.mobile_background-product{
			height: 100%;
			position: absolute;
		}
	}
	body section:first-of-type .block_background{
		@media only screen and (max-width: 567px) {
			&:after{
				content: "";
				position: absolute;
				height: 100%;
				width: 100%;
				background-image: -webkit-gradient(linear, left top, right top, color-stop(20%, rgba(0, 0, 0, 0.42)), to(rgba(0, 0, 0, 0)));
				background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 20%, rgba(0, 0, 0, 0) 100%);
				top: 0;
				left: 0;
			}
		}
	}

	//Full block
	@media only screen and (max-width: 567px) {
		body section.full div.infos.left div.content {
			max-width: 100%;

			p{
				padding-right: 0;
			}
		}
	}


	body section.full {
		background: $hard-grey !important;
		min-height: auto;
		display: flex;
		flex-direction: column;
	}

	body section.full div.infos.right, body section.full div.infos.left {
		background: none;
	}

	.mobile_background {
		display: block;
		max-width: 100%;

	}

	//Full block 360 

	body section.block-360 {
		display: none !important;

		//ZAAAaaaAApp!
	}

	//Full block Video 

	body div.video-bg {
		display: block;
	    // position: relative;
	    top: initial;
	    bottom: initial;
	    width: 100%;
	    height: auto;
	    overflow: hidden;

	}

	body div.video-bg video {
		    width: 100%;
    		// position: relative;
    		display: block; 
    		transform: none;
    		top: initial;
    		left: initial;
	}


	body a.button:after {
		content: none;
	}

	body section.full div.infos div.content a, 
	body div.block .button, 
	section.third div.infos div.content a  {
		text-align: center;
		font-size: .8em;
		max-width: 100%;
	}

	//small section order type 

	.small_section {
		width: 100%;
		display: flex;
		flex-direction: column;

		.block.full.light, .block.full.dark {
			order: 2;
			padding-bottom: 60px;
		}

		.block.full.video {
			order: 1;
		}
	}

	body div.block.full.video {
		min-height: 300px;
	}

	section.full.header-page .mobile_background {
		// height: 100vh;
		// max-width: initial;
		// transform: translateX(-20%);
		// position: relative;
		// width: initial;
   		// max-height: inherit;
		
		// @media screen and (max-width: 767px) {
		// 	width: 100%;
		// 	transform: none;
		// 	max-height: 35vh;
		// 	object-fit: cover;
		// }
		
		// @at-root { 
		// 	.p10 & {
		// 		transform: translateX(25%);
		// 	}
		// }
	}

	body section.full div.infos.light {
    	background: #fff!important;
	}

	// GAMME 

	body section.gamme ul li a {
		opacity: 1;
		transform: none;
		background: rgba(29, 29, 29, 0.4);

		&:hover {
			opacity: 1;
			transform: none;
			padding: 10px;
		}
	}

	body section.gamme ul li a p {
		font-size: 1em;
		padding: 20px;
		top: 32;

		@media only screen and (max-width: 414px) {
		    padding: 10px;
		    top: 50%;
		}
	}

	body section.gamme ul .close-gamme {
	    position: absolute;
	    top: 20px;
	    right: 20px;
	    width: 55px;
	    height: 65px;
	    z-index: 4;
	    padding: 10px;
	    -webkit-transition: .4s;
	    transition: .4s;
	}

	body section.full div.scroll-down {
		display: none;
	}

	body .mobile_godown {
		display: block;
	}

	.godown {
		display: none;
	}

	body section.full:first-of-type  div.scroll-down {
		display: block;
		top: 100vh;
    	margin-top: -45px
	}


	//nav menu 

	header a img {
		//float: right;
		margin-right: -4px;
	}

	header.fixed_scroll {
		background: rgba($hard-grey, 1);
	}

	// third 
}

header {
	padding: 20px;
	padding-bottom: 20px;

	@media only screen and (min-width: 1279px) {
		padding: unset;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: unset;
	}
}

section.third div.infos {
	background: linear-gradient(270deg,transparent,hsla(0,0%,8%,.9));

	@media only screen and (min-width: 1279px) {
		background: linear-gradient(90deg,transparent 0%,hsla(0,0%,8%,.9));
	}
}

.cc_container .cc_btn, .cc_container .cc_btn:visited {
	background-color: $orange !important;
	color: $white !important; 
	border-radius: initial !important;
}

section.blog-type {
	div.template-content {
		div.template-txt {
			h2, h3 {
				text-transform: uppercase;
    			color: #494a52;
    			font-weight: 800;
    			clear: both;
    			margin-top: 3em;
			}
		}
	}
}
.media_embed {
	text-align: center;
	iframe{

		@media only screen and (max-width: 414px) {
			width: 100%;
		}
	}
}

.ipad_responsive{
	display: none;
	
	@media screen and (max-width: 1023px) and (orientation: portrait) {
		display: block;
		height: 100vh;
	    width: 100vw;
	    background-image: url('../img/tablet-rotate.svg');
	    background-repeat: no-repeat;
	    background-color: #ffffff;
	    position: fixed;
	    z-index: 10;
	    background-size: 150px;
	    background-position: 50%;
	    z-index: 10000;
	}
	// @media screen and (max-width: 850px) and (max-height: 1224px) {
	// 	display: block;
	// 	height: 100vh;
	//     width: 100vw;
	//     background-image: url('../img/tablet-rotate.svg');
	//     background-repeat: no-repeat;
	//     background-color: #ffffff;
	//     position: fixed;
	//     z-index: 10;
	//     background-size: 150px;
	//     background-position: 50%;
	//     z-index: 10000;
	// }

	// @media screen and (max-width: 1023px) and (min-height: 1024px) {
	// 	display: block;
	// 	height: 100vh;
	//     width: 100vw;
	//     background-image: url('../img/tablet-rotate.svg');
	//     background-repeat: no-repeat;
	//     background-color: #ffffff;
	//     position: fixed;
	//     z-index: 10;
	//     background-size: 150px;
	//     background-position: 50%;
	//     z-index: 10000;
	// }

	@media screen and (max-width: 767px) {
		display: none;
	}

	@media screen and (max-height: 480px) and (orientation: landscape){
		display: block;
		height: 100vh;
	    width: 100vw;
	    background-image: url('../img/mobile-rotate.svg');
	    background-repeat: no-repeat;
	    background-color: #ffffff;
	    position: fixed;
	    z-index: 10;
	    background-size: 150px;
	    background-position: 50%;
	    z-index: 10000;
	}

}


h3.contact-partner {
		
		text-align: center;
		text-transform: uppercase;
		color: $hard-grey;
		font-weight: 800;
		margin-top: 50px;
		
		width: 800px;
		margin-left: auto;
		margin-right: auto;
		
		position: relative;
		@media only screen and (max-width: 1023px){
			width: auto;
		}
		@media only screen and (max-width: 567px) {
			margin-top: 70px;
			padding: 1em;
			width: auto;
		}

		&:after {
			content: '';
			width: 100px;
			height: 5px;
			background: $orange;
			position: absolute;
			bottom: -30px;
			left: 0;
			right: 0;
			margin: 0 auto;

		}
	}

	section.partner {
		h3{
		    margin-bottom: 0.5em;
			&:after{
			display: none;
			}
		}
		.subtite_partner{
			text-align: center;
			position: relative;

			&:after{
			content: "";
		    width: 100px;
		    height: 5px;
		    background: #ea6645;
		    position: absolute;
		    bottom: -30px;
		    left: 0;
		    right: 0;
		    margin: 0 auto;
			}
		}
	}



/* MODAL GPDR */

.modal-gdpr{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0,0,0,.8);
	display: none;
	z-index: 10000;

	h4{
		font-size: 2rem;
		margin-top: .5rem;
		position: relative;
    	padding-bottom: 1.5rem;

		&:after{
			content: "";
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    height: 3px;
		    width: 90px;
		    background: #ea6645;
		}

	}
	.text-intro{
		font-size: 1rem;
    	margin-bottom: 3rem;
	}
}

.modal-dialog{
	
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	background: #fff;
	max-height: 90vh;
	width: 75%;
	max-width: 1279px;
	padding: 2em;
	overflow-y: auto;

	 @media screen and (max-width: 1279px) {
		width: 90%;
	}

	.modal-content .modal-header{
		button.close{
		    border: none;
		    font-size: 48px;
		    color: #ea6645;
		    padding-right: 0;
		    position: absolute;
		    right: 3%;
		    top: 4%;
		    cursor: pointer;
		    z-index: 30;
		    background-color: transparent;
		    transition: .3s ease;

		    .sr-only{
		    	order: 0;
			    clip: rect(0,0,0,0);
			    height: 1px;
			    margin: -1px;
			    overflow: hidden;
			    padding: 0;
			    position: absolute;
			    width: 1px;
		    }

		    &:hover{
		    	transform: scale(0.8);
		    }
		}
	}
	.modal-footer button.btn-save{
		float: right;
		background: #ea6645;
		background-size: 200% 100%;
		color: #FFF;
		border: none;
		margin-top: 4rem;
		padding: 15px 30px;
		font-weight: 600;
    	font-style: italic;
    	cursor: pointer;
    	background-position: 0 0;
    	transition: .3s ease;

    	&:hover{
    		background-position: 100% 0;
    	}
	}
}
.category-container{
	margin-left: -15px;
}
.category{
	width: 25%;
	float: left;
	padding-left: 15px;
	padding-right: 15px;

	&.active{

		.category-title .select-item-btn{
			button.btn-yes{
				background: #ea6645;
				color: #FFF;
				border: none;
			}
			button.btn-no{
				background: transparent;
				color: #333;
				border: 1px solid #ccc;
			}
		}
	}

	@media screen and (max-width: 767px) {
		width: 100%;
	}

	.select-item-btn{
		
		cursor: pointer;
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 100px;
		display: flex;
		flex-wrap: wrap;
		float: right;
		width: 63px;
		margin-left: 20px;
		
		.btn{
			display: inline-block;
			margin-bottom: 0;
			font-weight: 400;
			text-align: center;
			vertical-align: middle;
			cursor: pointer;
			background-image: none;
			white-space: nowrap;
			padding: 3px 6px;
			line-height: 1.428571429;
			user-select: none;
			border: 1px solid #ccc;
			width: 50%;
			margin-left: -1px;
			font-size: 10px;
			height: 25px;
		}
		.btn-yes{

			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			margin-left: -5px;
			border-right: 1px solid transparent;
		}
		.btn-no{
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			border-left: 1px solid transparent;
			background: #ea6645;
			color: #FFF;
			border: none;
		}
	}

	.category-title{
		margin-bottom: 2rem;
		font-size: 13px;
		p{
			font-weight: 700;
			margin-bottom: 1.5rem;
			display: inline;
		}
	}

	.category-description{
		font-size: 11px;
		text-align: justify;

		@media screen and (max-width: 767px) {
			margin-bottom: 1rem;
		}   
	}
}

.cookie-bar{
	position: fixed;
	width: 100%;
    padding: 25px;
	left: 0;
	z-index: 2500;
	background: rgba(0,0,0,.8);
	bottom: 0;

	p{
		color: #FFF;
		font-size: 16px;
		margin: 0 auto;
		text-align: center;
		margin: 10px 50px;
	}
	div{
		text-align: center;
		display: block;
		margin: 0 auto;
		a{
			color: #FFF;
			padding-bottom: 5px;
			font-weight: 700;
			margin-top: 10px;
			font-size: 12px;
			display: inline-block;
			
			&.accept_cookie {
				cursor: pointer;
			    border: none;
			    text-decoration: none;
			    color: #fff;
			    background: #ea6645;
			    background-size: 200% 100%;
			    padding: 10px 30px;
			    font-style: italic;
			    font-weight: 600;
			    font-size: 16px;
			    display: inline-block;
			    transition: .3s ease;
			    background-position: 0 0;

		    	&:hover{
		    		background-position: 100% 0;
		    	}
			}

			&:first-child{
				margin-right: 50px;
				@media screen and (max-width: 414px) {
					margin-right: 0px;
				} 
			}
		}
	}
}
.show{	display: block;
}
.noshow{
	display: none;
}
.clearfix::after {
	content: "";
	clear: both;
	display: table;
}


#block5158 , #block5811 , #block5823 , #block5824{

	.title.sitename{
		display: none;
	}

	.first-home .compute-margin{
		left: 0%;

		@media screen and (max-width: 480px) {
			left: 0;
		} 
	}
}


div.modal_devis{
	display: block;
	position: fixed;
	top: 88vh;
    right: 0;
	z-index: 100;
	background-color: #fff;
	padding: 1.5em 200px 1.5em 2em;
	box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
	transition: .3s ease-in;
	opacity: 0;
	pointer-events: none;
	
	@media screen and (max-width: 567px) {
		padding: 1em 100px 1em 1em;
	} 
	&:hover{
		box-shadow: 0 8px 24px rgba(0,0,0,0.30);
		padding: 1.5em 210px 1.5em 2em;
	}
	p {
		color: #494A52;
		font-size: 18px;
		position: relative;
		margin: 0;
		@media screen and (max-width: 567px) {
			font-size: 16px;
		}
		&:after{
			content: "";
			position: absolute;
			height: 3px;
			width: 70px;
			background: #ea6645;
			bottom: -10px;
			left: -15px;
			@media screen and (max-width: 567px) {
				height: 2px;
				width: 54px;
				background: #ea6645;
				bottom: -5px;
				left: -9px;
			}

			
		}
	}
	
	&:after{
		background: url(../img/modal-devis.png) no-repeat center center; 
		position: absolute;
		right: 0;
		content: "";
		top: 0;
		height: 68px;
		width: 90%;
		background-size: 55%;
		background-position: 100% 50%;

		@media screen and (max-width: 567px) {
			height: 50px;
			width: 85%;
			background-size: 45%;
			background-position: 100% 50%;
		} 
	}


	&.scroll_active{

		opacity: 1;
		pointer-events: visible;
		cursor: pointer;
	}
}

div.modal-devis-enabled {
	display: block;
}



/* ---- INSPIRATION -----*/

	
	
	
.inspirations{
	display: flex;
	flex-wrap: wrap;
	position: relative;

	@media only screen and (max-width: 567px){
		height: auto;
	}

	.slider-inspiration{
		height: 140px;
		overflow: hidden;
		position: relative;

		&:before{
			height: 140px;
			width: 50%;
			position: absolute;
			content: "";
			right: 0;
			top: 0;
			//background: linear-gradient(90deg,#ffffff00,#18181896,#181818d4,#181818f5,#181818);
			display: none;
			z-index: -1;
			@media only screen and (max-width: 567px){
				display: none;
			}
		}
		a {
			img{
				height: 140px;
			}
		}
		@media only screen and (max-width: 567px){
			background-size: cover;
			//height: auto;
			height: 30vh;
			background-image: url('/sites/all/themes/shared/dist/img/slider_inspiration/inspirations-big.jpg');
			width: 100%;
			a{
				display: none;
			}
		}
	}

	.inspirations-content{
    	
		background-image: url('/sites/all/themes/shared/dist/img/slider_inspiration/inspirations-big.jpg');
		// height: 50vh;
		width: 100%;
		background-size: cover;

		@media only screen and (max-width: 567px){
			background-size: cover;
			height: auto;
		}


		div{
			float: right;
			width: 50%;
			padding: 100px;
			float: right;
			background: linear-gradient(90deg,#ffffff00,#18181896,#181818d4,#181818f5,#181818);
			position: relative;
			
			@media only screen and (max-width: 567px){
				background: #494a52;
			}

			p{
				color: white;
			}
		}
	}
}

.fancybox-button svg{
	color: #ea6645;
}

.fancybox-thumbs__list a:before {
	border: 6px solid #ea6645;
}


.fb-caption{
	position: absolute;
	left: 0;
	right: 0;
	bottom: -30px;
	z-index: 99996;
	pointer-events: none;
	text-align: center;
	transition: opacity 200ms;
	background: none;
  }

  div.content_checkbox--dealers{
	text-align: left;
    margin-bottom: 50px;
	margin-left: 16px;
	
	p{
		text-align: left !important;
	}
	label{
		font-size: 13px !important;
	}
  }
  .breadcrumb{
	color: #fff;
	position: absolute;
	bottom: 4%;
	left: 3%;
	font-size: 13px;
	z-index: 14;
	display: none;
	
	@media only screen and (max-width: 1023px){
		top: inherit;
		left: 40px;
		font-size: 11px;
	}

	a{
		color: #fff;
		&:last-child{
			font-weight: 900;
		}
	}
}
section:first-of-type{
	.breadcrumb{
		display: block;
	}
	

}


.breadcrumb.breadcrumb_inbound{
	color: #494a52;
	position: absolute;
    bottom: 4%;
    left: 3%;
	font-size: 13px;
	z-index: 14;
	@media only screen and (max-width: 1023px){
		top: inherit;
		left: 40px;
		font-size: 11px;
	}

	a{
		color: #494a52;
		&:last-child{
			font-weight: 900;
		}
	}
}

.block_background{
	@media only screen and (max-width: 1023px){
		position: relative;
		.breadcrumb{
			top: inherit;
		}
	}
}

.order-1, .order-2 {
	order: unset;
}

@media only screen and (min-width: 841px) {
	.order-1 {
		order: 1;
	}
	
	.order-2 {
		order: 2;
	}
}

body div.block.full {
	width: 100%; 

	@media only screen and (min-width: 841px){
		width: 50%;
	}

	

}

body section.full.flex-full {
	display: flex;
	min-height: inherit;

	@media only screen and (min-width: 841px){
		min-height: 50vh;
		// height: fit-content;
	}
}

body section#block19.full.flex-full, 
body section#block43.full.flex-full,
body section#block843.full.flex-full,
body section#block852.full.flex-full,
body section#block858.full.flex-full,
body section#block898.full.flex-full,
body section#block908.full.flex-full {
	@media only screen and (min-width: 841px){
		height: fit-content;
	}

	div.block_background {

		picture {

			img {

				@media screen and (min-width: 1024px) {
					object-position: right top;
				}
			}
		}
	}
}

body section.flex-full-half {
	display: flex;
	min-height: inherit;
    width: 100%;
    height: 100%;

	@media only screen and (min-width: 841px){
		display: flex;
		min-height: 50vh;
		min-height: 500px;
    	width: 100%;
	}

	@media only screen and (min-width: 1201px){
		display: inline-flex;
		width: 50%;
		float: left;
	}

	.image {
		height: auto;
		overflow: hidden;
		padding: 0;
		background-size: cover;
		min-height: 230px;
    	height: 230px;

		@media only screen and (min-width: 515px) and (max-width: 767px) {
			min-height: 400px;
    		height: 400px;
		}

		@media only screen and (min-width: 681px) {
			min-height: inherit;
			height: 50vh;
		}

		picture {
			height: 100%;
			width: 100%;
		}

		img {
			object-fit: cover;
			height: auto;
			width: auto;
			height: 100%;
    		width: 100%;
		}
	}

	.order-1 {
		order: 1;
	}

	.order-2 {
		order: 2;
	}

	.half {
		// width: 100%;

		h2 {
			font-size: 2.3vw;

			@media only screen and (max-width: 1279px) {
				font-size: 2.2em;
			}

			&:after {
				content: '';
				position: absolute;
				height: 3px;
				width: 130px;
				background: $orange;
				bottom: -20px;
				left: -20px;
			}
		}

		p, ul, li {
			line-height: 1.6em;
		}

		@media only screen and (min-width: 841px){
			// width: 100%;
		}

		@media only screen and (min-width: 1201px){
			width: 50%;
		}

	}
}

body section.flex-full-full {
	display: flex;
	min-height: inherit;

	@media only screen and (min-width: 841px){
		display: flex;
		flex-direction: row;
		min-height: 50vh;
		min-height: 500px;
    	width: 100%;
	}

	.full {
		display: flex;
		align-items: center;
		height: auto;
		min-height: 300px;
		padding-bottom: 80px;
		padding-top: 40px;

		h2 {

			&:after {
				content: '';
				position: absolute;
				height: 3px;
				width: 130px;
				background: $orange;
				bottom: -20px;
				left: -20px;
			}
		}

		p, ul, li {
			line-height: 1.6em;
		}
	}

	.full.light {
		padding: 50px;

		@media only screen and (min-width: 1536px){
			padding: 100px;
		}
	}

	.image {
		height: auto;
		overflow: hidden;
		padding: 0;
		background-size: cover;
		min-height: 230px;
    	height: 230px;

		@media only screen and (min-width: 515px) and (max-width: 767px) {
			min-height: 400px;
    		height: 400px;
		}

		@media only screen and (min-width: 681px) {
			min-height: inherit;
			height: auto;
		}

		picture {
			height: 100%;
			width: 100%;
		}

		img {
			object-fit: cover;
			height: auto;
			width: auto;
			height: 100%;
    		width: 100%;
		}
	}

	.swiper-container {
		height: 50vh;
	}
}

body section.full {

	div.infos.first-home {
		
		div.content {
	
			@media only screen and (min-width: 1280px) {
				margin-left: 50%!important;
				width: 50%;
			}

			// @media only screen and (min-width: 1536px) {
			// 	margin-left: 50%!important;
			// 	width: 50%;
			// }

			.sitename_small {
				display: block;
				font-size: 3.2em;
				font-family: Arboria-Bold,sans-serif;
				letter-spacing: -.2vw;
				padding-left: 0;

				@media only screen and (min-width: 1024px) {
					font-size: 6vw;
				}

				@media only screen and (min-width: 1280px) {
					display: none;
				}
			}

			h1 {
				
				&:before {
					content: '';

					@media only screen and (min-width: 1280px) {
						word-break: initial;
						position: absolute;
						top: -1px;
						left: auto;
						right: 105%;
						font-family: 'Arboria-Bold',sans-serif;
						font-size: 7.5vw;
						line-height: .8em;
						text-align: right;
						content: attr(data-title);
						width: 100vw;
					}
				}
			}

			h1.micro {

				&:before {
					@media only screen and (min-width: 1280px) {
						text-transform: initial;
					}
				}
			}
		}
	}

	.block_background {
		overflow: hidden;
		width: 100%;
		height: 50vh;
		background-size: cover;

		@media screen and (min-width: 1024px) {
			height: inherit;
		}

		img {
			display: block;
			height: 100%;
			object-fit: cover;
			width: 100%;

			@media screen and (min-width: 1024px) {
				object-position: center top;
				object-position: top left;
			}
		}
	}
}

body section.third.left {
	
	div.content {

		@media screen and (min-width: 1559px) {
			padding-left: 10%;
		}
	}
}

body section.full {
	.heading {
		color: white;
		display: flex;
		flex-direction: column;
		position: static;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		padding: 40px;
		background: none;

		@media screen and (min-width: 1024px) {
			position: absolute;
			height: 100%;
			top: 0;
			padding: 100px;
			padding-top: 25vh;
			color: white;
			background: linear-gradient(270deg,transparent,hsla(0,0%,8%,.9));
		}

		@media screen and (min-width: 1280px) {
			flex-direction: row;
			position: absolute;
			height: 100%;
			top: 0;
			padding: unset;
			padding-top: 35vh;
			color: white;
			background: linear-gradient(90deg,transparent 20%,hsla(0,0%,8%,.9));
		}

		h1.sitename_small {
			font-family: 'Arboria-Bold';
			font-size: 3.2em;
			padding: 0;
    		text-align: left;
			width: auto;
			margin: 0;

			@media screen and (min-width: 1024px) {
				font-size: 6vw;
			}

			@media screen and (min-width: 1280px) {
				padding-right: 30px;
				text-align: end;
				width: 50%;
				// font-size: 7.5vw;
				margin-bottom: 0.9em;
				line-height: .8;
			}

			span {
				text-transform: lowercase;
			}
		}
	
		h1.sitename_small::after {
			content: '';
			display: none;
		}

		.heading-content {
			z-index: 1;
			padding-top: 10px;
			width: auto;
			padding-right: unset;

			@media screen and (min-width: 1024px) {
				
			}

			@media screen and (min-width: 1280px) {
				padding-top: 10px;
				width: 50%;
				padding-right: 100px;
				padding-right: 15%;
			}

			h3 {
				text-transform: uppercase;
			}

			p {
				line-height: 1.5em;
			}
		}
	}
}

body section.section_light {
	background-color: white !important;
	flex-direction: column;

	@media screen and (min-width: 1024px) {
		height: 500px;
	}

	@media screen and (min-width: 1280px) {
		
	}

	div.block_background {
		width: 100%;
		display: flex;
		justify-content: center;
		min-height: 230px;
		height: 230px;

		@media screen and (min-width: 515px) {
			min-height: 400px;
			height: 400px;
		}

		@media screen and (min-width: 768px) {
			min-height: 50vh;
			height: auto;
		}

		@media screen and (min-width: 1024px) {
			width: 50%;
		}
	
		@media screen and (min-width: 1280px) {
			
		}
	}

	div.infos {
		position: relative;
		align-self: center;
   		justify-content: center;
	}

	div.infos.right.light, 
	div.infos.left.light {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		width: 100%;
		padding: 40px;

		@media screen and (min-width: 1024px) {
			width: 50%;
		}
	
		@media screen and (min-width: 1280px) {
			padding: 100px;
		}

		ol {
			line-height: 1.6em;
			font-size: .9em;
		}
	}

	div.order-1 {
		order: 1;
	}

	div.order-2{
		order: 2;
	}
}



body section.section_light.right {

	@media screen and (min-width: 1024px) {
		flex-direction: row;
	}
}

body section.section_light.left {

	@media screen and (min-width: 1024px) {
		flex-direction: row-reverse;
	}
}

body section.full.notfound {
	min-height: 100vh;
}

body section div.template-header div.default-background {
	height: 100%;
	width: 100%;
	background: linear-gradient(205deg, rgba(73,74,82,1) 0%, rgba(234,102,69,1) 100%);
	min-height: 150px;
	display: flex;
    justify-content: center;
    align-content: center;
	padding-top: 135px;

	@media screen and (min-width:568px) {
		padding-top: 200px;
	}

	img {
		height: 50%;
	}
}

body section div.template-content div.template-content-container div.blog div.blog-block div.default-background {
	height: 100%;
	width: 100%;
	background: linear-gradient(205deg, rgba(73,74,82,1) 0%, rgba(234,102,69,1) 100%);
	min-height: 150px;
	display: flex;
    justify-content: center;
    align-content: center;

	img {
		width: 50%;
	}
}

body section.start-section.full {
	@media all and (min-width: 1024px) and (max-width: 1279px) {
		height: fit-content;
	}
}