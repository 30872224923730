.seo {
	background: $white;
	overflow: hidden;
	
	h3 {
				font-family: 'Arboria-Bold'; 
			}
	
	display: none;

	li a {
		color: $orange;   
	}

	&.wood, &.pellets {
		display: block;
	}

	.seo-split {
		background: #F1F1F1;
		

		.container {
			// width: 800px;
			// margin: 0 auto; 
			font-size: 14px;
	    	line-height: 22px;
	    	//column-count: 1 !important;
	    	text-align: left;

			@media only screen and (min-width: 671px) {
				text-align: justify;
			}
	    	
	    	div.txt {
				//column-count: 2 !important;
	    		//column-gap: 30px;
	    		// width: 50%;
	    		// padding-right: 15px;
	    		// float: left;
	    		padding-bottom: 30px;

	    		p:first-of-type {
	    			margin-top: 0;
	    		}
	    	}

	    	img {
	    		width: 100%;
	    		// padding-left: 15px;
	    	}

			ol, ul {
				padding-left: 15px;
			}

			h2 {
				text-align: left;
			}


		}

		.button {
			background: none;
			color: $black;
		}
	}

	.container {
		// width: 800px;
		// margin: 0 auto;
		//column-count: 2 !important;
	    //column-gap: 30px;
		font-size: 14px;
    	line-height: 22px;
    	padding-top: 50px;
    	padding-bottom: 50px;
    	text-align: left;
    	
    	@media only screen and (max-width: 767px) {
		    width: auto !important;
			padding: 40px;
	    }

		@media only screen and (min-width: 671px) {
			text-align: justify;
	    }

    	img {
			width: 100%;
			height: auto;
    	}

		ol, ul {
			padding-left: 15px;
		}

		h2 {
			text-transform: uppercase;
			opacity: .9;
			text-align: left;
			margin-top: 0;
			line-height: 1em;
		}
	}

	.seo-split, .container {
		p {
			strong {
				font-weight: bold;
			}
		}
	}
}

.close-scrolling {
	
	position: fixed;
	z-index: 30000;
	background: white;
	
	$size: 52px;
	width: $size;
	height: $size;
	
	// border-radius: 50%;
	text-align: center;
	
	line-height: 43px; 
	font-size: 3em;
	color: $hard-grey;
	cursor: pointer;
	
	right: 0.75em;
	top: 0.25em;
	
	&.hide {
		display: none;
	} 
}

.product-chooser {



	div.holder-inline {

		div.material_special_select {

			div.holder-selector {

				width: auto;
				display: block;
				margin-bottom: 40px;

				label {
				    width: 50%;
				    display: inline;
				    margin: 0 20px;
				    /* background: #ea6645; */
				    padding: 20px 30px;
				  
				    /* color: white; */
				    line-height: 7em;
				    border: solid orange 2px;
				    border: 3px solid #ea6645;
					transition: all .3s;

				    &:after, &:before {
						opacity: 0 !important;
					}
				}

					[type=checkbox].filled-in:checked + label {
						background: $orange;
						color: $white;
						transition: all .3s;
				    }

				

				h2 {
				    color: #494a52;
					text-align: center;
				    margin-left: auto;
				    margin-right: auto;
				    margin-top: 0;
				    margin-bottom: 40px;
					font-size: 2.2em;

					@media only screen and (max-width: 767px) {
						width: auto;
	    			}

				    &:after {
					    left: 0;
					    right: 0;
					    margin: 0 auto;
				    }
				}

				h3 {
					    text-align: center;
					    text-transform: uppercase;
					    color: #494a52;
					    font-weight: 800;
					    margin-top: 50px;
					    width: 800px;
					    margin: 0 auto 70px;
					    position: relative;
					    font-size: 1.5em;
				
						@media only screen and (max-width: 767px) {
							width: auto;
	    				}
				}
			}
		}
	}

	.close {
		position: absolute;
		right: 1em;
		top: 0.5em;
		line-height: 1em;
		font-size: 3em;
		color: $hard-grey;
		cursor: pointer;
		z-index: 5;
	}
	
	.nothing {
		
		font-family: "Arboria-Bold";
		font-size: 1.3em;
		display: block;
		text-align: center;
		
		padding: 2em;
	}
	
	.results {
		clear: both;
		margin: 0;
		padding-top: 1em;
		
		font-family: "Arboria-Bold";
		font-size: 0.9em;
		display: block;	
		text-align: center;	
	} 
	
	div.material_special_select .select-wrapper input.select-dropdown {
	
	    box-sizing: border-box;
		padding: 0.75em 1.2em; 
    	padding-right: 2em;
    	text-overflow: ellipsis;
    	height: 100%;    
    }
    
    div.material_special_select .select-wrapper span.caret {
	    right: 1em;
	    @media only screen and (max-width: 639px) {
		    right: 2em;
	    }
    }
	
	position: fixed;
	background: #FFF;
	width: 100vw;
	height: 100vh;
	z-index: 7000;
	overflow: scroll;
	
	transform: translate3d(0,100%,0);
	transition: 0.4s;
	
	&.in-product-page {
		position: static;
		height: auto;
		width: auto;
		z-index: auto;
		overflow: auto;
		transform: translate3d(0,0,0);
		padding-top: 100px;
		
		div.material_special_select {
			margin-top: 0 !important;
		}
		
		@media only screen and (max-width: 767px) {
			
			padding-top: 0;
			
			.products {
				margin-top: 0;
			}
			
			.holder-inline {
				display: none;
			}			
		}
	}
	
	&.active {
		transform: translate3d(0,0,0);	
	}
	
	.intro {
		margin-top: 50px;
		margin-bottom: 4em; 

		@media only screen and (max-width: 1023px) {
			margin-top: 80px;
		}
		
		width: 100%;
		
		h2, p {
			display: block;
			text-align: center;

			@media only screen and (max-width: 1023px) {
				width: 100%;
				padding: 20px;
			}
		}
		
		h2 {
			
			color: $hard-grey;
			text-transform: uppercase;
			font-family: "Arboria-Bold";
			font-size: 2em;
			margin: 0;
			position: relative;
			
			&:after {
				content: "";
				display: block;
				width: 75px;
				height: 5px;
				background: $orange;
				
				position: absolute;
				bottom: -0.75em;
				left: 50%;
				
				transform: translateX(-50%);
			}
		}
		
		p{
			color: $hard-grey;
			font-family: "Arboria-Book";
			font-size: 1em;
			margin: 3em 1em;
			padding: 0 20%;

			@media only screen and (max-width: 639px) {
				margin: 0;
    			padding: 20px;
    			margin-top: 30px;
			}
		}
	}
	
	.deploy {
		
		position: relative;
		height: 70px;
		overflow: visible !important;
		z-index: 10;
		
		
		&:after {
		  content: "";
		  display: table;
		  clear: both;
		}
		
		.filters {
			
			position: absolute;
			left: 50%;
			padding: 1em;
			
			transform: translateX(-50%);
			transition: 0.4s;

			&:after {
			  content: "";
			  display: table;
			  clear: both;
			}
			
			select {
				
			    height: 2.42857rem;
			    padding: .5rem;
			    border: 1px solid #cacaca;
			    margin: 0 0 1rem;
			    font-size: 1rem;
			    font-family: inherit;
			    line-height: normal;
			    color: #0a0a0a;
			    
			    //text-align-last:center;
				font-family: "Arboria-Book";
			    
			    background-color: #fefefe;
			    border-radius: 0;
			    appearance: none;
			    
				// background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill:#{$orange}"></polygon></svg>');
			 //    background-size: 9px 6px;
			    background-position: right -1rem center;
			    background-origin: content-box;
			    background-repeat: no-repeat;
			    
			    padding-right: 1.5rem;
			    min-width: 10vw;
    
    		}

			.filter-list {
					
				display: none;					
				margin: 0;
				margin-top: 1em;
				
				&.active {
					display: block;
				}						
				
				li {
					
					list-style: none;
					margin: 0;
					font-family: "Arboria-Book";							
					
					ul {
						margin-left: 1em;
						li label{
							color: darken($grey, 30%);
						}
					}
					
					input[type=checkbox] {
						margin-right: 0.3em;
					}
				}
				  
			}	
						
		}

	}

	.products {
		margin-top: 2em;
		
		
		.item {
			
			position: relative;
			overflow: hidden;

			picture {
				width: 100%;
				height: 100%;
				display: block;
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
			
			.hover-wrapper {
				position: relative;
				width: 100%;
				height: 100%;
				overflow: hidden;
				position: absolute;
				top: 0;
			}
			
			display: block;
			float: left;
			width: 25%;
			height: 18vw;
			
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			
			$lowerSize: 4vw;
			//margin-bottom: $lowerSize;

			@media only screen and (max-width: 1023px) {
				width: 50%;
				height: 40vw;
			}

			@media only screen and (max-width: 639px) {
				width: 100%;
				height: 62.5vw;
			}
			
			h4 {
				position: absolute;
				bottom: -$lowerSize;
				height: $lowerSize;
				padding: 1.5vw;
				color: $hard-grey;
				font-style: italic;
				font-family: "Arboria-Book";
				text-transform: uppercase;
				font-size: 1.4vw;
			}
			
			transition: 0.4s;
			
			&:after {
				
				content: ' ';
				position: absolute;
				z-index: 1;
				
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				
				background-color: #000;		
				opacity: 0;	
				transition: 0.3s;		
			}
			
			a.hover {

				height: 5vh;
				height: 10vh;
				min-height: 0%;
				
				width: 100%;
				position: absolute;					
				z-index: 2;
				bottom: 0em;
				
				background: rgba(#000, 0.5);
				
				transition: 0.3s ease-in;
				//transform: translateY(100%);

				@media only screen and (max-width: 639px) {
					height: 25vw;
				}
				
				img {
					width: 60%;
					//margin: 1em 20%;
					margin: 0.5em 20% 0.2em 20%;
				}
				
				p {
					padding: 1em;
					width: 100%;
					margin-top: 0;
					font-family: "Arboria-Book"; 
					color: #FFFFFF;
					font-size: 1.4vw;
					text-align: center;
					line-height: 1.2em;
					
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					@media only screen and (max-width: 1023px) {
						font-size: 3vw;
					}
					
					@media only screen and (max-width: 639px) {
						font-size: 4.4vw;
					}
					
				}
				
			}
			
			&:hover a.hover {
				transform: translateY(0%);
				min-height: 100%;
				bottom: 0;
			}
			
		}
	}
}












