.a2a_menu a {
	color: #171717 !important;
	font-family: 'Arboria-Book',sans-serif !important;
} 

.a2a_menu {
	padding: 20px !important;
}

.a2a_wide a {
    text-align: left !important;
    padding: 9px !important;
}

section.blog-main {
	
	// .template-header {
	// 	height: 200px;
	// }

	div.template-content {

		div.blog {
			margin-top: 30px;
			.no-search {
				margin: 0 1.5em;
			}
		}

		div.template-content-container {
			width: 100%;
			max-width: 1279px;
			margin: 0 auto;
			height: auto;
			overflow: hidden;
			padding-bottom: 40px;

			div.selector { 
			    width: auto;
			    text-align: left;
			    //position: absolute;
			    padding: 8px;
			    overflow: initial; 
			    z-index: 1;
			    margin-top: 30px; 

			    @media only screen and (max-width: 767px) {
					//display: none;
					@at-root {
						.blog-main .selector div.input-field {
							width: 98% !important;
						}
					}
				}
				
				h4 {
					font-size: 1em;
					margin-bottom: 0.5em; 
				}
			}

			.blog-block {
				
				display: block;
				width: calc((100% / 3) - 40px);
				margin: 20px;
				margin-top: 50px;
				float: left;
				background: $white;
				box-shadow: 0 0 20px 0 rgba(0,0,0,.1);

				transition: .3s ease;

				//cursor: pointer;

				&:hover {
					box-shadow: 0 0 20px 0 rgba(0,0,0,.3);
				}

				@media only screen and (max-width: 1023px) {
					width: calc((100% / 2) - 40px);
				}

				@media only screen and (max-width: 767px) {
					width: calc(100% - 40px);
						float: none;

				}
				.intro-block-news{
					p{
						display: none;
					}
					p:nth-child(1){
						display: block;
					}
				}
				.news-card{
					width: 100%;
					height: 150px;
					display: block;
					overflow: hidden;

					picture {
						img {
							object-fit: cover;
							width: 100%;
							height: 100%;
						}
					}
				}

				div.blog-block-bg {
					width: 100%;
					height: 175px;
					background-color: $orange;
					background-size: cover !important;
				}

				div.blog-block-content {
					padding: 20px;
					height: 340px;
					color: #494a52;
					overflow: hidden;
					position: relative;

					span {
						opacity: .5;
						font-family: 'Arboria-Book',sans-serif;
						color: inherit;
					}

					p, li {
						font-size: 15px; 
						line-height: 19px;
						color: inherit;

						overflow: hidden;
					    display: -webkit-box;
					    max-height: 95px !important;
					    // -webkit-line-clamp: 5;
					    // -webkit-box-orient: vertical;

					    display: none;

					    &:first-of-type {
					    	display: block;
					    }

						strong {
							font-weight: inherit;
						}
					}

					h2 {
						font-size: 16px;
					}

					&.news-block-content {
						text-align: left;
						p{
							display: block;	
							text-align: left;

							a{
								pointer-events: none;

								&.link, &.a2a_dd{
									pointer-events: visible;
								}
							}
						}
					}
					a.link {
						position: absolute;
						bottom: 0;
						left: 0;
						color: $orange;
						font-family: 'Arboria-Bold';
						font-size: 15px;
						text-decoration: none;
						padding: 20px;

					}

					a.a2a_dd {
						position: absolute;
						right: 20px;
						bottom: 20px;
						width: 18px;
						height: 18px;
						background: url('../img/share-blog.svg');   
						background-size: cover;
					}
				}
			}
		}
	}
	
}

.autres-articles .container {
	a {
		display: inline-block;
	}
}
