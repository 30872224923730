

section.template-container {
	width: 100%; 		
}

div.template-header {
	height: 430px;

	background: grey;
	background-repeat: no-repeat;
	background-position: 50% 20%;
	background-size: cover;	

	@media only screen and (max-width: 567px) {
		height: 250px;
	}

	picture {
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
}

div.back-button {
	position: absolute;
    top: 130px;
    left: 40px;
	color: white;
	font-weight: 600;
	font-size: 1.2em; 
	z-index: 100;

	@media screen and (min-width: 568px) {
		font-size: .9em;
	}

	// @media only screen and (max-width: 1023px) {
	// 	position: absolute;
	// 	bottom: auto;
	// 	top: 30px;
	// 	right: 30px;
	// }

	// @media only screen and (max-width: 567px) {
	    
	//     position: absolute;
	//     top: 15px;
	//     right: auto;
	//     left: 20px;
	//     color: #494a52;
	//     font-weight: 600;
	//     font-size: 1.5em;
	    
	//     a {
		    
	// 	    display: block;
	// 	    width: 1px;
 	// 	    white-space: nowrap;
	// 	    text-indent: -1000px;
		    			
	// 		 &:after {
	// 		    content: "\00d7";
	// 		    text-indent: 0;
	// 		    float: right;
	// 		    margin-right: -20px;
	// 		    font-size: 1.6em;
	// 	    }	    
	//     }
	// }

	a {
		color: inherit;
		text-decoration: inherit;
		font-weight: inherit;
		transition: .3s;

		&:hover {
			color: $orange;
		}
	}
	
	&.in-scroll {
		position: fixed !important;
		right: 0 !important;
		left: auto !important;
		top: 100px !important;
		
		background: white !important;
		padding: 1em !important;
		
		display: none !important;
		
		&.visible {
			display: block !important;
		}
	}
}

div.template-content {
	background: $white;
	overflow: hidden;
	position: relative;

	h1 {
		text-align: center;
		color: #494a52;
		margin-top: 50px;
		margin-left: auto;
		margin-right: auto;
		width: 80%;
		line-height: 1.3;

		@media only screen and (max-width: 567px) {
			font-size: 1.6em;
		}

		&:after {
			content: "";
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}

	h2 {
		text-transform: uppercase;
		font-size: 2.2em;
		line-height: 1.3;
		margin-top: 10px;
		font-family: 'Arboria-Bold', sans-serif;

		@media only screen and (max-width: 567px) {
			font-size: 1.6em;
		}

		&:after {
			content: none;
		}
	}
	
	h3.contact-partner {
		
		text-align: center;
		text-transform: uppercase;
		color: $hard-grey;
		font-weight: 800;
		margin-top: 50px;
		
		width: 800px;
		margin-left: auto;
		margin-right: auto;
		
		position: relative;
		
	}

	div.template-txt {
		//column-count: 2;
		column-count: 1;
		column-gap: 40px;
		text-align: justify;
		
		width: 800px;
		margin: 0 auto;
		padding-top: 50px;
		padding-bottom: 80px;
		
		&.template-txt-news{
			padding-top: 0;
			padding-bottom: 20px;
		}

		@media only screen and (max-width: 1023px) {
			width: 100%;
			padding-left: 25px;
			padding-right: 25px;
		}
		
		//&.blog-txt {
			.date {
				font-family: 'Arboria-Bold' , sans-serif;
				color: $orange;
				font-size: 1em;
			}
		//}
		h2{
			@media only screen and (max-width: 414px) {
				text-align: left;
			}
		}
		p, li {
			font-size: 14px;
			line-height: 22px;
			@media only screen and (max-width: 414px) {
				text-align: left;
			}

			&:nth-child(1){
				margin-top: 0;
			}
		}

		p {
			img {
				width: 100%;
			}
		}
	}
}

div.template-50 {
	width: 100%;
	height: 400px;
	background: purple;
	position: relative;

	.content {
		padding: 150px 100px;

	}

	h2 {
		color: $white;
		font-family: 'Arboria-Light' , sans-serif;
		text-transform: uppercase;
		font-size: 2.8em;
		font-weight: 300;
		line-height: 1em;
		position: relative;
		margin-top: 0;

		&:after {
			content: '';
			position: absolute;
			height: 3px;
			width: 130px;
			background: $orange;
			bottom: -20px;
			left: -20px;
		}
	}

	p {
		color: white;
	}
} 

.autres-articles {
	background: $white;
	margin: 0 auto;
	text-align: center;
	position: relative;
	padding-top: 40px;
	padding-bottom: 40px;
	background: #EFEFEF;
	width: 100%;

	&.list-news{
		background-color: #fff;
	}

	// &:before {
	// 	content: '';
	// 	height: 5px;
	// 	background: $orange;
	// 	width: 800px;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;
	// 	margin: 0 auto;
	// }

	.container {
		width: 100%;
		max-width: 1535px;
		margin: 0 auto;
	}
	
	h3 {
		padding: 20px 10px 10px 0;
	    margin: 0;
	    color: #494a52;
	    text-transform: uppercase;
	    font-size: .8em;
	    width: 230px;
	    float: left;
	}

	.flex {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		margin-right: 5%;
	}
	

	.block-actu {		
		position: relative;
		display: inline-block;
		margin: 20px;
		box-shadow: $shadow;
	    width: 350px;
	    height: 100px;
	    background: #fff;
	    overflow: hidden;
	    
	    .block-actu-img {
	        height: 100px;
		    width: 100px;
		    margin-right: 20px;
		    overflow: hidden;
		    float: left;
		    transition: .3s;
		    background-position: 50%;
		    background-repeat: no-repeat;
		    background-size: cover;
		    margin-left: auto;

			picture {
				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
					position: unset;
					top: unset;
					transform: unset;
					right: unset;
					transition: unset;
					z-index: unset;
				}
			}
		   
	    }
	    
	    p {
			font-size: 1em !important; 
			padding-top: 5px;
		}

		@media only screen and (max-width: 567px) {
			padding: 0;
		}

		@media only screen and (max-width: 414px) {
			margin: 15px auto;
			left: 50%;
			transform: translateX(-50%)
		}


		h3 {
			text-transform: uppercase;
			padding-right: 10px;

			@media only screen and (max-width: 567px) {
				font-size: 13px;
				padding-right: 2px;
			}
		}

		h3,p {
			text-align: left;
		}

		&.active:before {
			content: '';
			height: 5px;
			width: 100%;
			top: 0;
			left: 0;
			background: $orange;
			position: absolute;
		}
	}
}

.blog-txt img {
	width: 100%;
	
	&.blog-img-right,
	&.blog-img-left {
		width: 400px; 
		padding: 30px;
		padding-top: 0;
		padding-bottom: 5em !important;
	}
	
	&.blog-img-right {
		float: right;
		padding-right: 0;
	}

	&.blog-img-left {
		float: left;
		padding-left: 0; 
	}
}

@media screen and (max-width: 767px) {
	.blog-txt img.blog-img-right, 
	.blog-txt img.blog-img-left {
		float: none;
		width: 100%;
		padding: 0;
	}
}

.list-link_news{
	
	text-align: center;
	margin-bottom: 4em;
	
	.link-content_news{
		clear: both;
		float: none;
		display: inline-block;
		font-size: 1rem;
		padding: 15px 45px;
		text-transform: uppercase;
		background: #ea6645;
		border: 1px solid #ea6645;
		font-family: Arboria-Bold,serif;
		color: #fff;
		transition: .3s ease;
		text-align: center;
		text-decoration: none;
		margin: 40px 10px 0;
	}
}