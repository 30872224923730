.hslider {
    
    .video-parent {
        width: 100vw;
        height: 100vh;
        position: relative;
        margin: 0 auto 0;
        overflow: hidden;
    }
    .video-container {
        width: 100%;
        margin: 0 auto;
        padding: 0 0 calc((100% / 16) * 9);
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        background: rgb(15,14,16);
    }
    
    .video-container > * {
        position: absolute;
        width: 100%; height: 100vh;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
    }
    
    video {
        opacity: 0;
        transition: .75s;
    }
    
    .versions {
        
        ul {
            margin: 0;
            padding: 0;
        }
        
        li {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
            margin: 0;
            padding: 0;
            list-style: none;
            
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            
            opacity: 0;
            transition: .8s; 
            
            &.active {
                opacity: 1;
            }
        }
    }
    
    .text-list > li {
        opacity: 0;
        pointer-events: none;
        transition: .35s;
        list-style: none;
    }
     
    .text-list > li.active {
        opacity: 1;
        pointer-events: auto;
        transition: .75s;
    }
    .text-list .step-label {
        
        color: white;
        font-size: 1.6rem;
        position: absolute;
        max-width: 567px;
        padding-top: 150px;
        
        h3 {
            font-size: 2.2rem;
            margin-bottom: 0.6em;
            margin-top: 0;
        }
        
        ul.options {
            
            margin: 0;
            padding: 0;
            
            width: 100%;
            display: flex;
            flex-shrink: 0;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;
            
            li {
                
                list-style: none;
                display: inline-block;   
                flex: 1;             
                
                img {
                    width: 90%;
                    margin-right: 10%;
                }
            }
        }
        
        @media (max-width: 1535px) {
            font-size: 1.4rem;
            max-width: 400px;
            h3 {
                font-size: 2rem;
            }
        }
        
        @media (max-width: 1279px) {
            font-size: 1rem;
            max-width: 300px;
            h3 {
                font-size: 1.3rem;
            }
        }
        
        
    }
    
    .link-list {
        width: 75%;
        min-width: 720px;
        display: flex;
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
    }
    .link-list li {
        width: 100%;
        margin: 0.5rem;
        list-style: none;
        
        h4 {
            color: white;
            display: block;
            text-align: center;
            vertical-align: top;
            font-size: 1em;
            padding-bottom: 1em;
            text-transform: uppercase;
            font-size: 14px;
            opacity: 0;
            transition: .4s; 
        }
    }
    
    .link-list a {
        display: block;
        width: 100%;
        height: auto;
        position: relative;
    }
    .link-list a .progress-bar {
        width: 0;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(-50%);
        background-color: #ea6645;
        z-index: 5;
        transition-duration: .35s;
        transition-property: width;
        transition-timing-function: linear;
    }
    .link-list a::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 1px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        transform: translateY(-50%);
        background-color: grey;
        transition: calc(1s / 2);
    }
    .link-list .active a::before {
        background-color: white;
    }
    
    .link-list .active h4 {
        opacity: 1;    
    }
    
    .link-list a:hover::before {
       // height: 2px;
       background-color: white;
    }
    .link-list a:hover .progress-bar {
        //height: 2px;
    }
    
    .link-list a:hover h4 {
        opacity: 1;
    }
    
    /* Blocs */
    
    .text-list > li:nth-child(1) .step-label {
        top: 15%;
        left: 6%;
        @media (max-width: 1279px) {
            left: 6%;
        }
    }
    .text-list > li:nth-child(2) .step-label {
        top: 0;
        left: 6%;
    }
    .text-list > li:nth-child(3) .step-label {
        top: 10%;
        left: 6%;
    }
    .text-list > li:nth-child(4) .step-label {
        top: 20%;
        left: 6%;
    }
    .text-list > li:nth-child(5) .step-label {
        top: 20%;
        left: 6%;
    }
    .text-list > li:nth-child(6) .step-label {
        top: 20%;
        right: 6%;
    }
    .text-list > li:nth-child(7) .step-label {
        top: 15%;
        left: 6%;
        
        /*@media (max-width: 1535px) {
            .options img {
                width: 60%;
            }
        }
        
        @media (max-width: 1279px) {
            left: 6%;
            .options img {
                width: 40%;
            }
        }*/
    }
    
}