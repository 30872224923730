body.case-studies {
	
	width: 100vw;
	overflow-x: hidden;
	
	@media only screen and (max-width: 1279px) {

		// section.full h1 {
		// 	padding-left: 0;
		// }
		
		section.full div.infos.right div.content {
			width: 90%;
			padding-left: 7vw;
		}

		section.third.right div.infos div.content {
			right: 100px; 
		}
	}

	@media only screen and (max-width: 767px) {
		section.full div.infos.right div.content {
			top: initial;
			transform: none;
		}

		// section.full div.infos.first-home div.content h1 {
		// 	font-size: 2em;
		// }

		section.full div.infos div.content a {
			text-align: left;
		}

		section.third div.infos div.content {
		    left: 0;
		    right: 0;
		    padding: 20px;
		    width: 100%;
		}
	}
}