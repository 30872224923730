body {
  &.splash {
    overflow:hidden;
    div.langue,div#nav-icon,div.langue-selector,footer {
      display: none;
    }
  }
  section.splash {
      z-index:2000;
      position:fixed;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.8);
    // div.mobile-bg {
    //   height: 100vh;
    //   width:100%;
    //   position: fixed;
    //   top:0;
    //   left:0;
    //   // height:35vh;
    //   @media screen and (min-width: 840px) {
    //     display:none
    //   }
    // }
    div.flex-center {
      height: 100%;
      @media screen and (min-width:840px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      background: transparent;
      @media screen and (max-width: 1023px) {
        padding-top:20vh;
        padding-bottom: 40px;
      }
      button.do-search{
        opacity:1!important;
      }
      div.selector {
        width: 100%;
        max-width: 900px;
        display: flex;
        justify-content: center;
        .hidden {
          display: none !important;
        }
        form {
          width: 100%;
          display: flex;
          justify-content: center;
          @media screen and (max-width: 1023px) {
            width: 100%;
            flex-direction: column;
          }
        }
        div.selector-block {
          width: 272px!important;
          min-height: 258px;
          background: rgba(#e8e8e8, .9);
          float: none;
          opacity: 1;
          transition: all 0.3s ease;
          @media screen and (min-width: 840px) {
            &:first-of-type {
              transform: translateX(54%);
              z-index: 10;
              &.animate-left {
                transform: translateX(0);
              }
            }
          }
          &.invisible {
            @media screen and (max-width: 1023px) {
              display:none;
            }
            @media screen and (min-width:840px) {
              opacity: 0;
              visibility: hidden;
              transition: all 0.3s ease;
            }
          }
          @media screen and (max-width: 1023px) {
            width: calc(100% - 20px)!important;
            min-height: 0;
          }
        }
      }
    }
  }
}
