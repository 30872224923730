section.product {
	
	.product-slider {
		
		position: relative;
		height: 100vh;
		overflow: hidden;
		background-color: #494a52!important;
		display: flex;
		flex-direction: column;

		@media screen and (max-width: 1023px) {
			height: auto;
		}

		@media screen and (max-width: 414px) {
			height: calc(130vh - 40vw);
		}

		div.dark-overlay {
			    background: linear-gradient(90deg,transparent 50%,hsla(0,0%,8%,.9));
			    position: absolute;
			    height: inherit;
				max-height: inherit;
			    width: 100%;
		}

		div.images_container {
			@media only screen and (max-width: 1023px) {
				height: 50vh;
				max-height: 50vh;
			}
		}

		.template-content-content {
			background: none;
			overflow: unset;
			margin-left: 60%;
		    width: 40%;
		    padding-right: 12%;
		    text-align: left;
			position: absolute;
		    z-index: 99;
		    top: 50%;
		    transform: translateY(-50%);
			
			// @media only screen and (max-width: 1535px) {
			// 	background: none;
			// 	margin-left: 50%;
			// 	width: 50%;
			// 	padding-right: 5%;
			// 	text-align: left;
			// 	position: absolute;
			// 	overflow: unset;
			// 	z-index: 99;
			// 	height: 100%;
			// 	top: 35vh;
			// 	transform: translateY(0);
		    // }
			
			@media only screen and (max-width: 1279px) {
			    margin-left: auto;
			    width: 100%;
			    transform: translateY(0%);
			    position: static;
				padding: 100px;
				padding-top: 35vh;
		    }
			@media only screen and (max-width: 1023px) {
			    margin-left: auto;
				width: 100%;
				transform: translateY(0);
				position: relative;
				transform: none;
				padding: 40px;
				height: 100%;
				top: auto;
				// top: 35vh;
			}

			@media screen and (max-width: 414px) {
				height: unset;
			}
			

			h1 {
				color: $white;
				text-align: unset;
				width: auto;
				margin-top: unset;
				// width: auto;
				// text-align: left;
				// font-family: Arboria-Light,sans-serif;
			    // text-transform: uppercase;
			    // font-size: 2.8em;
			    // font-weight: 300;
			    // line-height: 1em;
			    // position: relative;
			    // margin-top: 0!important;

				// @media only screen and (max-width: 1535px){
				//     font-size: 2.3em;
			    // }

			    // @media only screen and (max-width: 1023px){
				//     margin: 0 50px 30px;
			    // }


			    &:before {
			    	// content: '';
				    // position: absolute;
				    // height: 3px;
				    // width: 130px; 
				    // background: #ea6645; 
				    // bottom: -20px;
				    // left: -20px;
			    }

				&:after {
					content: "";
					left: -20px;
					right: auto;
					margin: 0;
				}
			    
			    // @media only screen and (max-width: 767px) {
				    
				//     padding: 0 1em;
				//     margin-bottom: 0 !important;
				//     margin-top: 50px !important;
				    
				//     font-size: 1.4em;
				    
				// 	&:before {
				// 		content: '';
				// 		position: absolute;
				// 		height: 3px;
				// 		width: 130px; 
				// 		background: #ea6645; 
				// 		bottom: -20px;
				// 		left: -20px;
				// 	}
			    // }

			    // @media only screen and (max-width: 567px) {
				//     margin-top: 0px!important;
				//     margin-left: 0;
			    // }
			}

			div.template-txt {
				color: $white;
				width: auto;
				text-align: left;
				padding: 0;

				p, li {
					line-height: 1.5em;
					font-size: initial;
					margin-bottom: 60px;
					
					@media only screen and (max-width: 767px) {
						margin-bottom: 10px;
					}
					@media only screen and (max-width: 567px) {
    					font-size: 14px;
					}
				}

				a {
					color: $white !important;
					text-decoration: none;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
		
		ul {
			margin: 0;
			padding: 0;
		}
		
		li, .slide_group {
			
			position: absolute;
			width: 100%;
			height: 100%;
			
			top: 0;
			left: 0;
			
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			
			display: block;
			list-style: none;
			
			transition: background-image 1s linear;
		}

		.slide_group.slide_group-mobile{
			z-index: 11;
			max-height: 50vh;
			display: block !important;
			background-size: cover;
			background-position-y: 70%;

			@media only screen and (min-width: 1024px) {
				display: none !important;
			}
		}

		.slide_group.slide_group-full{
			height: 50vh;

			@media only screen and (min-width: 1024px) {
				z-index: 10;
				height: 100%;
				display: block !important;
				background-size: cover;
    			background-position-y: 70%;
			}
		}
	}
	
	&.left {
		
		.product-slider div.dark-overlay {
			background: linear-gradient(-90deg,transparent 50%,hsla(0,0%,8%,.9));
		}
		
		.product-slider .template-content-content {
			// margin-left: 10%;

			@media only screen and (max-width: 1023px) {
				margin-left: 0%;
			}
		}
		
	}
	
	.template-txt {
	
		a {
			color: $orange !important;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		
	}
	
}
ul.menu_product {
	list-style: none;
	padding: 0;
	margin: 0;
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 1000;
	
	@media only screen and (max-width: 567px) {
		padding: 26px;
		display: flex;
		// flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		background-color: #fff;
		// position: fixed;
		height: 70px;
	}

	li {
		text-decoration: none;
		display: inline-block;
		padding: 10px 30px 30px 30px;
		width: 200px;
		max-width: 260px;
		background-color: rgba(255, 255, 255, 0.75);
		border-right: 2px solid #BCBBBB;
		transition: .3s ease-in-out;

		@media screen and (max-height: 1024px) and (max-width: 1023px) {
			display: block;
		}
		@media only screen and (max-width: 567px) {
			text-align: center;
			width: auto;
			flex-wrap: wrap;
			justify-content: space-between;
			background-color: transparent;
			padding: 0;
			border-right: none;
		}

		&:hover{
			background-color: #FFF;

			span:after{
				// content: "";
				// position: absolute;
				// border-width: 5px 8px;
				// border-style: solid;
				// left: 30%;
				// bottom: -24px;
				// border-color: transparent transparent transparent #ea6645;
				// height: 0;
				// width: 0;
				// display: block;
				// transform: translateY(-50%);
				// transition: .6s;
				@media only screen and (max-width: 567px) {
					background-color:transparent;
					display: none;
				}
			}
		}
		&.active {
			background-color: #FFF;
			border: none;
			transition: .3s ease-in-out;
			a{
				font-weight: 600;
				i:hover {
					color: $orange;
				}
				span{
					transition: .3s ease-in-out;

					&:before{
						content: '';
						position: absolute;
						width: 30%;
						height: 2px;
						// top: 50%;
						bottom: -15px;
						margin-top: 2px;
						background: #ea6645;
						
						@media only screen and (max-width: 567px) {
							background-color:transparent;
						}
					}
				}
				span:hover:before {
					// content: '';
					// position: absolute;
					// width: 30%;
					// height: 2px;
					// bottom: -15px;
					// margin-top: 2px;
					// background: $orange;
						@media only screen and (max-width: 567px) {
						top: 1.5em;
						width: 40%;
						transform: translateX(-50%);
						left: 50%;
					}
				}
				// span:after{
				// 	content: "";
				//     position: absolute;
				//     border-width: 5px 8px;
				//     border-style: solid;
				//    	left: 30%;
				//     bottom: -24px;
				//     border-color: transparent transparent transparent #ea6645;
				//     height: 0;
				//     width: 0;
				//     display: block;
				//     transform: translateY(-50%);
				//     transition: .6s;
				//     @media only screen and (max-width: 567px) {
				// 		border: none;
				// 	}
				// }
			}
		}

		a {
			color: #000;
			text-decoration: none;
			transition: .3s;


			i {
				transition: .3s;
			}

		}

		a span {
			position: relative;
			display: block;
			cursor: pointer;
			padding: 0;
			font-size: 0.9em;
		}

		a span:before {
			content: '';
			position: absolute;
			width: 30%;
			height: 2px;
			// top: 50%;
			bottom: -15px;
			margin-top: 2px;
			background: transparent;
			
			@media only screen and (max-width: 567px) {
				background-color:transparent;
			}
		}

		span:before {
			left: 0px;
		}


		// span:after {
		// 	right: 0px;
		// 	background: $orange;
		// 	transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
		// }

		span:hover:before, .active a span:before {
			background: $orange;
			width: 30%;
			transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
		}

		// span:hover:after, .active a span:after {
		//    	content: "";
		//     position: absolute;
		//     border-width: 5px 8px;
		//     border-style: solid;
		//    	left: 30%;
		//     bottom: -24px;
		//     border-color: transparent transparent transparent #ea6645;
		//     height: 0;
		//     width: 0;
		//     display: block;
		//     transform: translateY(-50%);
		//     transition: .3s;
		//     @media only screen and (max-width: 567px) {
		// 		display: none;
		// 	}
		// }
	}
}


