.fullvideo-type {
	
	display: block;
	height: 60vw;
	width: 100%;
	padding: 100px 20% 0 20%;
	background: #171717;
	
	&:before {
		
		content: " ";
		position: absolute;
		display: block;
		width: 100%;
		height: 60vw;
		top: 0;
		left: 0;
		
		background-image: url('https://www.stuv.com/sites/default/files/stuv-22110i-close-up-mdf-noir2447_0_1_0.jpg');
		background-size:cover;
		
		opacity: 0.4;
		z-index: 0; 
	}
	
	h1 {
		display: block;
		width: 100%;
		text-align: center;
		color: white;
		font-size: 3em;
		text-transform: uppercase;
		position: relative;
		z-index: 1;
	}
	
	.scale-video {
		
		width: 100%;
		padding-top: 56.25%;
		position: relative;
		
		iframe {
		  position: absolute;
		  top: 0;
		  left: 0;
		  width: 100%;
		  height: 100%;
		}
		
	}
	
	@media only screen and (max-width: 1023px) {
		padding: 100px 15px 0 15px;
		height: 100vh;
	}
}