.container--member{
	max-width: 80%;
	margin: 0 auto;
	@media only screen and (max-width:1330px) {
		max-width: 95%;
	}
}


body.page--member{
	background-color: #fff;
	font-family: 'Arboria-Book', sans-serif;

	.contact.static{
		display: none;
	}
	p{
		line-height: 1.3;
	}
}
.template-member{
    width: 100%;
    height: 80vh;
	background-color: #fff;
	background-image: url('../img/member/bgc_member.jpg');
	background-size: cover;
	@media only screen and (max-width:1330px) {
		height: 90vh;
	}
	@media only screen and (max-width:750px) {
		max-height: 620px;
	}
	// &:after{
	// 	content: "";
	// 	position: absolute;
	// 	height: 100%;
	// 	width: 100%;
	// 	background-image: -webkit-gradient(linear,left top,right top,color-stop(20%,rgba(0,0,0,0.42)),to(rgba(0,0,0,0)));
	// 	background-image: linear-gradient(to right,rgba(0,0,0,0.42) 20%,rgba(0,0,0,0) 100%);
	// 	top: 0;
	// 	left: 0;
	// }
	.template-content{
		background: transparent;
	}
	.member--intro{
		text-align: center;
		max-width: 767px;
		margin: 0 auto;
		color: #fff;
		margin-top: 210px;
		@media only screen and (max-width:1330px) {
			margin-top: 170px;
		}
		
		h1{
			color: #fff;
			// width: auto;
			// margin-bottom: 2em;
			// @media only screen and (max-width:750px) {
			// 	font-size: 1.6em;
			// }
		}
		p{
			line-height: 1.3;
			@media only screen and (max-width:750px) {
				max-width: 90%;
				margin: 0 auto;
			}
		}

		a{
			background-color: #ea6645;
			color: #ffffff;
			padding: 10px 30px;
			display: inline-block;
			margin-top: 30px;
		}
	}
}

.tabs--member{
	margin-bottom: 140px;
	@media only screen and (max-width:750px) {
		margin-bottom: 40px;
	}
	.content_tabs{
		background-color: #fff;
		box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
		z-index: 10;
		margin-top: -15vh;
		padding: 2em;

		@media only screen and (max-width:1400px) {
			margin-top: -100px;
		}

		@media only screen and (max-width:970px) {
			margin-top: -50px;
		}
		
		.tab_container{
			padding: 1em 3em;
			@media only screen and (max-width:750px) {
				padding: 0;
			}
			
		}
		ul.tabs{
			margin-top: 0;
			width: fit-content !important;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			border-bottom: 4px solid #ededee;
			margin-left: 3em;
			padding-left: 0;
			text-align: left;
			@media only screen and (max-width:970px) {
				border-bottom: none;
				margin-left: 0;
			}
			li{
				background: inherit;
				width: auto;
				font-size: 20px;
				color: #000000;
				opacity: .6;
				font-weight: 900;
				text-transform: inherit;
				padding-right: 5em;
				
				@media only screen and (max-width:1430px) {
					padding-right: 3em;
				}
				@media only screen and (max-width:1000px) {
					width: 50%;
					margin-bottom: 22px;
					padding-right: 0;
					opacity: .2;
				}
				@media only screen and (max-width:750px) {
					width: 100%;
					padding: 0;
					font-size: 16px;
				}
				&:last-of-type{
					padding-right: 0;
					&.active{
					
						&:after{
							position: absolute;
							content: "";
							height: 5px;
							width: 100%;
							background-color: #f26745;
							bottom: -4px;
							left: 0;
							@media only screen and (max-width:970px) {
								display: none;
							}
						}
					}
				}
				&.active{
					color: #F26745;
					opacity: 1;
					background: inherit;
					position: relative;
					&:after{
						position: absolute;
						content: "";
						height: 5px;
						width: 46%;
						background-color: #f26745;
						bottom: -4px;
						left: 0;
						
						@media only screen and (max-width:1330px) {
							width: 80%;
						}
						@media only screen and (max-width:970px) {
							display: none;
						}
					}
				}
			}
		}
	}
}

.cta--partner{
	background-color: #f26745;
    color: #fff;
    padding: 8px 15px;
    font-weight: 600;
    display: inline-block;
}

.tab_content{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	
	.tab_content-info{
		width: 60%;
		@media only screen and (max-width:970px) {
			width: 100%;
		}
		ul{
			list-style: none;
			padding: 0;
			margin-top: 50px;
			li{
				
				span{
					font-weight: 900;
					position: absolute;
					color: #f16844;
					@media only screen and (max-width:750px) {
						position: relative;
						margin-top: 30px;
					}
				}
				p{
					padding-left: 120px;
					   
					@media only screen and (max-width:750px) {
						padding-left: 0;
					}
				}
			}
		}
	}
	.tab_content-img{
		width: 30%;
		@media only screen and (max-width:970px) {
			width: 100%;
			margin-top: 30px;
		}
		img{
			display: block;
			width: 100%;
			@media only screen and (max-width:970px) {
				width: 100%;
				object-fit: cover;
				height: 350px;
			}
			@media only screen and (max-width:750px) {
				height: 178px;
			}
		}
	}
	h3{
		color: #3D3D3D;
		margin-top: 0;
		font-size: 27px;
		font-weight: 900;
	}
}
.who--contractor{
    margin-bottom: 140px;

	.who--contractor--content{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		flex-direction: row-reverse;
		.who--contractor--content_intro{
			width: 40%;
			@media only screen and (max-width:1430px) {
				width: 30%;
			}

			@media only screen and (max-width:750px) {
				width: 76%;
				margin: 0 auto;
			}
			
			h2{
				font-size: 48px;
				color: #3D3D3D;
				max-width: 80%;
				@media only screen and (max-width:1430px) {
					font-size: 38px;
				}
				@media only screen and (max-width:970px) {
					font-size: 28px;
				}

			}
			p{
				max-width: 50%;
				@media only screen and (max-width:1430px) {
					max-width: 100%;
				}
			}
			img{
				max-height: 265px;
				display: block;
				margin-right: 0;
				text-align: right;
				margin-left: 50%;
				@media only screen and (max-width:1430px) {
					margin-left: 0%;
				}
				@media only screen and (max-width:970px) {
					margin-left: 70px;
					max-height: 175px;
				}
				@media only screen and (max-width:750px) {
					display: none;
				}
			}

			.contractorSwiper{
				.swiper-slide{
					opacity: 0 !important;

					&.swiper-slide-active{
						opacity: 1 !important;
					}
				}
			}
		}
		.who--contractor--content_slider{
		    width: 56%;
			height: 490px;
			margin-left: 0;
			padding-top: 2em;
			/* overflow-y: visible; */
			padding-left: 30px;
			padding-right: 30px;

			@media only screen and (max-width:1430px) {
				width: 66%;
				margin-left: 0%;
				padding-left: 0;
			}
		

			@media only screen and (max-width:970px) {
				width: 69%;
			}
			@media only screen and (max-width:750px) {
				width: 100%;
				padding: 0;
				margin: 0 auto;
				padding: 10px;
				padding: 10px 38px;
			
			}
			.swiper-slide{
				width: 33%;
				padding: 2em;
				text-align: center;
				box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
				background-color: #fff;
				height: auto!important;
				max-height: 150px;

				@media only screen and (max-width:970px) {
					width: 40%;
					padding: 1em;
				}
				@media only screen and (max-width:750px) {
					width: 80%;
					margin: 0 auto !important;
					margin-bottom: 10px !important;
					max-height: 180px;
				}

				p{
					@media only screen and (max-width:1430px) {
						font-size: 15px;
					}
					@media only screen and (max-width:750px) {
						font-size: 13px;
					}
				}
				span{
					font-size: 18px;
					font-weight: bold;
					padding-top: 30px;
					display: inline-block;
					color: #3D3D3D;
					font-family: "Arboria-Bold";
					&:before{
						content: "";
						background-image: url('../img/member/icon_a.svg');
						height: 30px;
						width: 30px;
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						top: 24px;
						content: "";
						background-size: contain;
						background-repeat: no-repeat;
						@media only screen and (max-width:970px) {
							height: 25px;
							width: 23px;		
						}
					}
				}

				&:nth-child(2){
					span{
						
						&:before{
							content: "";
							background-image: url('../img/member/icon_b.svg');
							
						}
					}
				}
				&:nth-child(3){
					span{
						
						&:before{
							content: "";
							background-image: url('../img/member/icon_c.svg');
							
						}
					}
				}
				&:nth-child(4){
					span{
						
						&:before{
							content: "";
							background-image: url('../img/member/icon_d.svg');
							
						}
					}
				}
				&:nth-child(5){
					span{
						
						&:before{
							content: "";
							background-image: url('../img/member/icon_e.svg');
							
						}
					}
				}
				&:nth-child(6){
					span{
						
						&:before{
							content: "";
							background-image: url('../img/member/icon_f.svg');
							
						}
					}
				}
			}
			.swiper-button-next, .swiper-button-prev{
				background-position: center;
				background-repeat: no-repeat;
				background-color: #F26745;
				color: #fff;
				width: 36px;
				height: 36px;
				font-size: 2px;
				padding: 20px;
				border-radius: 200px;
				background-size: contain;
				background-image: none !important;
				top: 76%;
				right: 30px!important;
				z-index: 100000000;
				transform: rotate(90deg);
				@media only screen and (max-width:1330px) {
					right: 3px!important;
				}
				@media only screen and (max-width:970px) {
					width: 26px;
					height: 26px;
				}
				@media only screen and (max-width:750px) {
					width: 16px;
					height: 16px;
					padding: 15px;
					right: 12px!important;
				}
				&:after{
					font-size: 13px;
					font-weight: 900;
				}
			}
			.swiper-button-prev{
				top: 63%;
				left: inherit;
			}
			.swiper-button-disabled{
				background-color: #9b9999;
			}
		}
	}
}

.swiper-grid-column>.swiper-wrapper{
	flex-direction: inherit;
	@media only screen and (max-width:750px) {
		width: 90%;
		height: auto !important;
	}
	
}

section.experience{
	background-color: #14181A;
	padding-bottom: 100px;
	.content_tabs , .content_tabs_exp{
		padding-top: 51px;
	}
	ul.tabs , ul.tabs_exp{
		margin-top: 0;
		width: fit-content !important;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		border-bottom: 4px solid #6E7072;
		margin-left: 3em;
		padding-left: 0;
		text-align: left;
		margin: 0 auto;
		margin-bottom: 70px;
		@media only screen and (max-width:750px) {
			border-bottom: 2px solid #474747;
			margin-bottom: 20px;
			padding-bottom: 28px;

		}
		li{
			background: inherit;
			width: auto;
			font-size: 20px;
			color: #6E7072;
			opacity: .6;
			font-weight: 900;
			text-transform: inherit;
			padding: 0.6em;
			list-style: none;
			padding-right: 7em;
			cursor: pointer;
			@media only screen and (max-width:970px) {
				padding-right: 9px;
			}
			@media only screen and (max-width:750px) {
				width: 100%;
			}
			&:last-of-type{
				padding-right: 0;
				&.active{
				
					&:after{
						position: absolute;
						content: "";
						height: 5px;
						width: 100%;
						background-color: #f26745;
						bottom: -4px;
						left: 0;
						@media only screen and (max-width:750px) {
							display: none;
						}
					}
				}
			}
			&.active{
				color: #F26745;
				opacity: 1;
				background: inherit;
				position: relative;
				&:after{
					position: absolute;
					content: "";
					height: 5px;
					width: 68%;
					background-color: #f26745;
					bottom: -4px;
					left: 0;
					@media only screen and (max-width:750px) {
						display: none;
					}
				}
			}
		}
	}
	.tab_contentexperience , .tab_contentparcours{
		color: #fff;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		position: relative;
		margin-left: 10%;
		@media only screen and (max-width:970px) {
			margin-left: 3%;
		}
		span{
			position: absolute;
			transform: rotate(-90deg);
			font-size: 35px;
			font-weight: 900;
			opacity: .3;
			text-align: center;
			top: 30%;
			left: -30%;
			@media only screen and (max-width:970px) {
				display: none;
			}
		}

		.tab_content-info{
			width: 50%;
			max-width: 30%;
			@media only screen and (max-width:1330px) {
				max-width: 40%;
			}
			@media only screen and (max-width:970px) {
				width: 100%;
				max-width: 100%;
			}

			@media only screen and (max-width:750px) {
				width: 90%;
				max-width: 100%;
				margin: 0 auto;
				margin-bottom: 50px;
			}

			h3{
				font-weight: 900;
				font-size: 23px;
			}

			ul{
				list-style: none;
				padding: 0;
				margin-top: 50px;
				list-style: inherit;
				font-size: 19px;
				li{
					margin-bottom: 20px;
				
					span{
						font-weight: 900;
						position: absolute;
						color: #f16844;
					}
					p{
						   padding-left: 120px;
					}
				}
			}
		}
		.tab_content-img{
			width: 45%;
			position: relative;
			@media only screen and (max-width:970px) {
				width: 100%;
				height: 290px;
			}
			@media only screen and (max-width:750px) {
				height: 210px;
			}
			img{
				display: block;
				width: 100%;

				@media only screen and (max-width:970px) {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

				&:after{
					content: "";
					position: absolute;
					height: 100%;
					width: 50%;
					background-image: linear-gradient(to right,rgba(21,24,26,1.2) 10%,rgba(0,0,0,0) 100%);
					
					top: 0;
					left: 0;
				}
				&:before{
					content: "";
					position: absolute;
					height: 10%;
					width: 100%;
					background-image: linear-gradient(to bottom,rgba(21,24,26,1.2) 10%,rgba(0,0,0,0) 100%);
					top: 0;
					left: 0;
				}
		}
	}

	div.tab_contentparcours {

		div.tab_content-info{
			ul{
				margin-top: 20px;
				list-style: inherit;
				font-size: 15px;
	
				li{
					margin-bottom: 10px;
				}
			}
		}
		.tab_content-img{
			&:after{
				display: none;
			}
			&:before{
				display: none;
			}
		}
	}

}

.bigSwiper{
	margin-top: 140px;
	margin-bottom: 140px;
	@media only screen and (max-width:750px) {
		margin-bottom: 40px;
		margin-top: 40px;
	}
	.swiper-wrapper{
		margin-bottom: 30px;

		.swiper-slide{
			.content_text{
				max-width: 45%;
				background-color: #fff;
			    padding: 2em;
				margin-top: -100px;
				display: inline-block;
				@media only screen and (max-width:970px) {
					max-width: 75%;
    				margin-top: -40px;
				}
				@media only screen and (max-width:750px) {
					padding-top: 55px;
				}
				h4{
					font-size: 20px;
					margin-top: 0;
				}
			}
			.content_img{
				width: 100%;
				height: 460px;
				@media only screen and (max-width:970px) {
					max-width: 100%;
				}
				@media only screen and (max-width:750px) {
					height: 240px;
				}
				img{
					object-fit: cover;
					height: 100%;
					width: 100%;
				}
			}
		}
	}
	.swiper-pagination{
		left: 0;
		width: 100%;
		text-align: left;
		padding-left: 2em;
		@media only screen and (max-width:750px) {
			top: 212px;
			bottom: inherit;
		}
		.swiper-pagination-bullet{
			width: 50px;
			border-radius: 8px;
			height: 6px;
			@media only screen and (max-width:750px) {
			    width: 23px;
			}
			&.swiper-pagination-bullet-active{
				background-color: #3D3D3D;
			}
		}
	}
}
.select2-container--default span.select2-selection--single{
	box-sizing: border-box;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    height: auto;
    line-height: initial;
    width: 100%;
    padding: 6.7px 20px;
    display: block;
    border: 1px solid #cacaca !important;
	min-height: 10px;
	font-family: 'Arboria-Book', sans-serif;
	border-radius: 0;
	text-align: left;
&:after{
	content: "▼";
	color: #f26745;
	font-size: 10px;
	position: absolute;
    right: 10px;
    top: 34%;
}
	.select2-search__field{
		font-family: 'Arboria-Book', sans-serif;
		margin: 0;	
		height: 24px !important;
	}
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable{
	background-color: #f26745 !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
	display: none;
}
.form_member{
	background: #F9F9F9;
	padding: 70px 0;
	.container{
		color: #3D3D3D;
		h3{
			text-align: center;
			font-weight: 900;
			font-size: 24px;
		}
		p{
			text-align: center;

			font-size: 21px;

		}

		form{
			input{
				border: 1px solid #ccc;
				border-radius: 0;
				font-family: Arboria-Light,serif;
				outline: none;
				width: calc(24% - 30px);
				margin: 15px;
				padding: 15px 20px;
				transition: .3s ease;
				background: #fff;
				@media only screen and (max-width:970px) {
					width: calc(32% - 30px);
				}
				@media only screen and (max-width:750px) {
					width: 90%;
				}
			}
			select{
				display: inline-block;
				float: none;
				width: calc(24% - 30px);
				background: #fff;
				border: 1px solid #ccc;
				padding: 15px;
				margin: 0 15px 15px;
				height: 3em;
				appearance: none;
				font-family: Arboria-Light,serif;
				border-radius: 0;
				@media only screen and (max-width:970px) {
					width: calc(32% - 30px);
				}
				@media only screen and (max-width:750px) {
					width: 90%;
				}
			}
			input[type=checkbox]+label{
				width: calc(100% - 50px);
				display: inline-block;
				
			}
			input[type=checkbox]{
				appearance: none;
				background: #fff;
				border: 2px solid #ea6645;
				margin-left: 15px;
				margin-right: 5px;
				cursor: pointer;
				width: 18px;
				height: 18px;
				outline: none;
				padding: 0;
				@media only screen and (max-width:970px) {
					margin-top: 0;
					margin-bottom: 30px;
				}
			}

			input[type=checkbox]:checked{
				background-color: #ea6645;
				position: relative;
			}

			input[type=submit]{
				clear: both;
				float: none;
				display: inline-block;
				font-size: 20px;
				padding: 15px 45px;
				background: #ea6645;
				border: 1px solid #ea6645;
				font-family: Arboria-Bold,serif;
				color: #fff;
				transition: .3s ease;
				text-align: center;
				max-width: 200px;
				margin: 0 auto;
				display: block;
				margin-top: 60px;
			}
			.required-form{
				font-size: 10px;
				margin-bottom: 15px;
				text-align: left;
				margin-left: 20px;
				font-weight: 900;
				margin-bottom: 0;
			}
		}
	}
}