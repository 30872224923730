@font-face {
    font-family: 'Arboria-Bold';
    src: url('../fonts/arboria_bold-webfont-webfont.eot');
    src: url('../fonts/arboria_bold-webfont-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/arboria_bold-webfont-webfont.woff') format('woff'),
         url('../fonts/arboria_bold-webfont-webfont.ttf') format('truetype'),
         url('../fonts/arboria_bold-webfont-webfont.svg#arboriabold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arboria-Book';
    src: url('../fonts/arboria_book-webfont-webfont.eot');
    src: url('../fonts/arboria_book-webfont-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/arboria_book-webfont-webfont.woff') format('woff'),
         url('../fonts/arboria_book-webfont-webfont.ttf') format('truetype'),
         url('../fonts/arboria_book-webfont-webfont.svg#arboriabook') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arboria-Light';
    src: url('../fonts/arboria_light-webfont-webfont.eot');
    src: url('../fonts/arboria_light-webfont-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/arboria_light-webfont-webfont.woff') format('woff'),
         url('../fonts/arboria_light-webfont-webfont.ttf') format('truetype'),
         url('../fonts/arboria_light-webfont-webfont.svg#arborialight') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: 'adjust';
	src:url('../fonts/adjust-font.eot');
	src:url('../fonts/adjust-font.eot?#iefix') format('embedded-opentype'),
		url('../fonts/adjust-font.woff') format('woff'),
		url('../fonts/adjust-font.ttf') format('truetype'),
		url('../fonts/adjust-font.svg') format('svg');
	font-weight: normal;
	font-style: normal;
    font-display: swap;
}
