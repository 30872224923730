
.language-selector {
  padding: 0;
  margin: 0;
  background-color: black;
  height: 2.5em;
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: nowrap;
  position: absolute;
  top:4px;
  right: calc(-100% + 30px);
  transition: all 250ms ease-in-out;

  @media screen and (min-width: 415px) {
    width: auto;
    justify-content: center;
    right: -360px;
  }

  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: end;
    top: -50px;
    right: -30px;
    height: 50px;
    width: 100vw;
    transition: .5s;
  }

  li, li a {
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    font-size: 1rem;
    color: white;
    cursor: pointer;
  }

  a:hover{
    color: $orange;
    transition: all 200ms;

    span {
        color: $orange;
        transition: all 200ms;
    }
  }

  .langue-selector-ul-parent {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: start;
    width: auto;

    @media screen and (min-width: 415px) {
        justify-content: center;
        width: unset;
    }

    .langue-selector-li-parent {
        order: 2;
        padding: 0;
        margin: 0;
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 8px;
        padding-left: 8px;

        @media screen and (min-width: 375px) {
            min-width: 40px;
        }

        @media screen and (min-width: 415px) {
            padding-right: 15px;
            padding-left: 15px;
            min-width: unset;
        }

        // &:first-child {
        //     padding-left: 20px;

        //     @media screen and (min-width: 415px) {
        //         padding-left: 25px;
        //     }
            
        //     a {
        //         color: #ea6645;
        //     }
        // }

        &:last-child {
            padding-right: 25px;
        }

        .langue-selector-ul-child {
            display: none;
            transition: all 250ms ease-in-out;
        }

        .active {
            display: flex;
            text-align: center;
            padding: 15px;
            flex-direction: column;
            top: 2.8em;
            position: absolute;
            background: rgba(0,0,0,.8);
            border: 3px solid #ea6645;
            width: 75%;
            left: 13%;
            transition: all 250ms ease-in-out;

            @media screen and (min-width: 568px) {
                position: absolute;
                top: 2.8em;
                margin: 0;
                padding: 16px 20px 16px 16px;
                width: auto!important;
                background: rgba(0,0,0,.8);
                border: 3px solid #ea6645;
                white-space: nowrap;
                left: unset;
            }

            &:before {
                @media screen and (min-width: 568px) {
                    content: "";
                    position: absolute;
                    border-width: 0 5px 8px;
                    border-style: solid;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -10px;
                    border-color: transparent transparent #ea6645;
                    height: 0;
                    width: 0;
                }
            }

            li {
                padding-bottom: 7px;
                text-align: center;

                a {
                    color: white;
                }
            }
        }
    }

    .current {
        order:1;
        padding-left: 20px;

        @media screen and (min-width: 415px) {
            padding-left: 25px;
        }
        
        a {
            color: #ea6645;
            font-weight: 900;
        }
    }

  }
}

.opened {
    right: -20px;
    justify-content: center;
    width: 100vw;
    min-width: unset;

    @media screen and (min-width: 415px) {
        right: -20px;
        width: auto;
        justify-content: center;
    }

    @media screen and (min-width: 1280px) {
        display: flex;
        justify-content: end;
        top: -50px;
        right: -30px;
        height: 50px;
        width: 100vw;
        transition: .5s;
    }

    .langue-selector-ul-parent {
        justify-content: center;
        
        @media screen and (min-width: 415px) {
            justify-content: center;
        }

        .langue-selector-li-parent {
            min-width: unset;
           
        }
    }
}
