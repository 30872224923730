body.open {
	.pos-product-selector {
		display: none !important;
	}
}

.template-container.pos{
	// &:before{
	// 	background-image: url('../img/pos_bg-img.png');
	// 	height: 500px;
	// 	width: 20%;
	// 	position: absolute;
	// 	right: -30px;
	// 	top: 150px;
	// 	content: "";
	// 	z-index: 10;
	// 	background-size: contain;
	// 	background-repeat: no-repeat;
	// 	@media only screen and (max-width:1200px) {
	// 		max-width: inherit;
	// 	}
	// }
}

body.page--dealers_house , body.page--pos{
	.contact.static{
		display: none;
	}
}
div.loader {
	
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000000, 0.75);
	z-index: 999999;
	
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
	}
}

div.fixed-selector {
	position: fixed;
	top: 0;
	left: -100%;
	z-index: 9;
	width: 100%;
	padding: 15px 50px;
	background: white;
	transition: .3s ease;

	span{
		@media only screen and (max-width: 414px) {
			display: block;
			margin: 2em auto;

		}
	}
}

.dropdown ul.select-dropdown {
	max-height: 170px !important;
}

.appointment-thanks, .redirection.modal { 
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	background: rgba(#171717, 0.8);
	z-index: 10;
	
	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #FFF;
		padding: 2em;
		
		h2 , h3{
			font-family: 'Arboria-Book';
			color: $orange;
			font-size: 2em;
		}
		
		h3 {
			font-size: 1.4em;
		}
		
		p {
			font-family: 'Arboria-Book';
			color: $black;
		}
		
		a {
			margin-top: 0.5em;
			background: $orange;
			display: inline-block;
			font-weight: bold;
		}
	}
	
	.close {
		position: absolute;
		top: 0.5em;
		right: 0.5em;
		font-size: 1.6em;
		color: $black;
		
		cursor: pointer;
	}
}

a.appointment {
	
	display: block;
	float: right;
	background: $orange;
	color: white;
	text-transform: uppercase;
	padding: 0.6em 1em;
	border: 2px solid transparent;
	transition: 0.4s ease-out;
	text-decoration: none;
	margin: 1em;
	margin-top: 3em;
	
	&:hover {
		background: white;
		color: $orange;
		border-color: $orange;
	}
	
	@media only screen and (max-width: 567px) {
		margin: 0 1em;
		margin-top: 1em;
		float: inherit;
		position: absolute;
		top: 130px;
		left: 50%;
		transform: translateX(-60%);
		width: 224px;
		text-align: center;
	}
}

.form-appointment {
	
	padding: 2em;
	
	h2 {
		display: block;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
	}
	
	p {
		display: block;
		width: 100%;
		text-align: center !important;
		margin-bottom: 3em !important;
	}
	
	input, label {
		font-family: 'Arboria-Book';
	}
	
	label {
		margin: 1em 0;
		font-size: 1em;
		margin-top: 0;
		display: inline-block;
		margin-bottom: 20px;
		color: #82848d;
	}
	
	textarea {
		height: 10rem;
		padding: 0.5em;
		font-size: 0.9em;
	}
		
	.button {
		
		display: block;
		width: 100%;
		text-align: center;
		
		background: $orange;
		color: white;
		text-transform: uppercase;
		padding: 0.6em 1em;
		border: 2px solid transparent;
		transition: 0.4s ease-out;
		text-decoration: none;
		
		&:hover {
			background: white;
			color: $orange;
			border-color: $orange;
		}
		
	}
	
}

section.pos {
	&::after{
		background-image: url('../img/bg_pdv2022.png');
		height: 500px;
		width: 270px;
		position: absolute;
		right: 0px;
		top: 160px;
		content: "";
		background-size: contain;
		background-repeat: no-repeat;

	
		@media only screen and (max-width: 1535px) {
			height: 350px;
			width: 230px;
			position: absolute;
		}
		@media only screen and (max-width: 767px) {
			display: none;
		}
	}
	&.block_pdv-pos:after{
		@media only screen and (max-width: 767px) {
			display: none;
		}
	}
	&.corporate {
		@media only screen and (max-width: 1535px) {
			height: 500px; 
			
			div.infos div.content {
				
				top: 0;
				transform: translateY(0);
				margin-top: 5em;					
			}			
		}
		
		@media only screen and (max-width: 1023px) {
			height: 100%;
		}
	}

	a {
		color: $orange;
	}

	.product-selector-toggle {
		&:after, &:before {
			display: none;
		}

		span {
			position: absolute !important;
		    right: 15px;
		    font-weight: 800;
		    font-size: 20px;
		    transition: .2s ease;

		}

		&:hover {
			span {
				color: $orange;
			}
		}
	}

	.see-more {
		background: $orange;
		padding: 10px 20px;
		color: $white;
		text-decoration: none;
		overflow: hidden;
    	display: block;
    	margin-top: 20px;

    	transition: ease .3s;
    	opacity: 1;

    	&:hover {
    		opacity: .8;
    	}
    	
    	& + .see-more {
	    	margin-top: 10px;
    	}
	}

	div.template-header {
		height: 250px;
	}

	div.template-content {

		overflow: initial;
		padding-top: 140px;

		h1 {
			margin-bottom: 2em;
		}
		// h1 {
		// 	font-size: 2em;
		// 	margin-top: 0;
		// 	padding-top: 50px;

		// 	@media only screen and (max-width: 767px) {
		// 		padding-top: 20px;
		// 		padding-bottom: 10px;
		// 		margin-bottom: 0;
		// 		position: relative;
		// 		&:before{
		// 			content: "";
		// 				background-image: url('../img/member/icon-map.svg');
		// 				height: 40px;
		// 				width: 40px;
		// 				position: absolute;
		// 				left: 50%;
		// 				transform: translateX(-50%);
		// 				top: -35px;
		// 				content: "";
		// 				background-size: contain;
		// 				background-repeat: no-repeat;
		// 		}
		// 	}
		// }

		// h2 {
		// 	text-align: center;
		// 	font-family: 'Arboria-Light';
		// 	margin-top: 60px;
			
		// 	font-size: 1.3em;
		// 	font-weight: 300;
		// }

		//MODELS
		
		@at-root {
		
			div.pos-product-selector {
			
			position: absolute; 
			width: 100%;
			height: auto;
			min-height: 100%;
			
			top: 0;
			left: 0;
			background: rgba($white, .9);
			z-index: 1000;
			
			opacity: 0;
			
			//transform: translateX(-100%);
			left: -100%;
			transition: opacity 0.4s;

			.uncheck {
				background: red;
			}



			a.close {

			    position: absolute;
			    right: 50px;
			    top: 40px;
			    font-size: 1.4em;
			    color: black;
			    transition: .3s;

			    @media only screen and (max-width:1024px) {
			    	font-size: 1.2em;
			    }

			    @media only screen and (max-width:670px) {
			    	font-size: 1em;
			    	top: 10px;
			    	right: 10px;
			    }

			    &:hover {
			    	color: $orange;
			    }
			}
			
			&.active {
				opacity: 1;
				//transform: translateX(0);
				left: 0;
				transition: 0.4s;

				div.fixed-selector {
					left: 0;
				}
				
				&.fixed {
					position: fixed;
				}
				
				&.scrollable {
					position: fixed;
					height: 100%;
					overflow: scroll;
					z-index: 1000000;
				}
			} 

			div.holder {
				width: 100%;
				margin: 0 auto;
				padding: 50px;
				padding-top: 115px;
				
				&:after {
				  content: "";
				  display: table;
				  clear: both;
				}

				div.product-single {
					
					width: calc(100% / 4);
					height: calc(100vw / 6);
					position: relative;
					
					&.fixed {
						position: absolute;
					}
					
					float: left;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;

					@media only screen and (max-width:1024px) {
						width: calc(100% / 2);
						height: calc(100vw / 3);
					}
					
					@media only screen and (max-width:670px) {
						width: calc(100% / 1);
						height: calc(100vw / 1.5);
					}

					&.hidden {
						display: none;
					}
					
					div.label-block {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 100%;

						&:before {
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							padding: 35px;
							background: rgba($black, .8);
						}
						 

						label {
							width: 100%;
							height: 100%;
							position: absolute;
							bottom: 0;
							left: 0;


							&:after {
								top: initial;
								left: initial;
								right: 20px;
								bottom: 20px; 
								border: 2px solid #f0f0f0;
							}

							&:before {
								top: initial !important;
								bottom: 24px !important;
								right: 31px !important;
								left: initial !important;
							}

							p {
								position: absolute;
								bottom: 20px;
								left: 20px;
								margin: 0;
								color: $white;
								font-family: "Arboria-Light";
								font-size: 1em;
								padding-right: 25%;
							}
						}
					}
				}

				p {
					font-size: 1.2em;
					text-align: left;

					@media only screen and (max-width:1024px)  {
						font-size: 1em;
					}

					a,button {
						padding: 8px 15px;
						color: $white;
						background: $orange;
						border: none;

						@media only screen and (max-width: 414px) {
							width: 49%;
						    padding: 8px 5px;
						    font-size: 13px;
						}
					}
				}

				p.close {
					position: absolute;
					font-size: 2em;
					top: 20px;
					right: 20px;
					cursor: pointer;
				}
			}
		}

		}

		//RESUME

		div.resume { 
			width: 100%;
			max-width: 900px;
			margin: 0 auto;
			overflow: hidden;
			padding-bottom: 20px;

			h2 {
				margin-top: 30px;
			}

			div.resume-block {
				margin: 0 auto;
				width: calc(100% - 20px);
				margin: 0 10px;
				padding: 0px;
				padding-bottom: 0;
				//background: rgba($black,.1);
				text-align: center;
				
				&.summary {
					margin-bottom: 2em;
				}

				p {
					margin: 0;
					line-height: 1.4em;
					font-size: 1em;


					strong {
						font-family: 'Arboria-Bold';
						text-transform: uppercase;
					}
					 
					span {
						&:after {
							content: ", ";
						}
						&:last-child:after {
							content: '';
						}
					}
				}

				a,
				button {
					
					border: none;
					
					background: $orange;
					padding: 8px 20px;
					color: $white;
					text-transform: uppercase;
					position: relative;
					bottom: -15px;
					font-family: 'Arboria-Book';

					transition: .3s ease;

					&:hover {
						bottom: -20px;
					}
				}

				.do-search {
					background: grey;
					transition: .3s ease;
					opacity: .5;
					display: inline-flex;
    				align-items: center;
					//pointer-events: none;

					span {
						// font-family: 'Arboria-Bold';
					}

					svg {
						fill: white;
						height: 14px;
						margin-left: 10px;
					}

					&.not-empty {
						background: $orange;
						//pointer-events: initial;
						opacity: 1;
					}
				}
			}

		}

		div.separator {
			width: calc(100% - 20px);
			margin: 20px auto;
			height: 1px;
			background: rgba($black, .2);
		}

		//SELECTOR
 
		div.selector {
			width: 100%;
			max-width: 900px;
			margin: 0 auto;
			

			div.selector-block {
				width: calc((100% / 2) - 20px);
				margin: 10px;
			    min-height: 110px;
				float: left;
				padding: 15px;
				&.selector-block-pos{
					width: 100% !important;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					@media only screen and (max-width:1200px) {
						max-width: 400px;
						margin: 0 auto;
						display: inline-table;
						float: inherit;
						text-align: center;
					
					}
					@media only screen and (max-width:650px) {
						max-width: inherit;
					}
					.input-field , .selector-energies{
						width: calc(33% - 5px);
						@media only screen and (max-width:1200px) {
							width: 100%;
						}
					}
					.selector-energies .input-field{
						width: 100%;
						.multiselect{
							width: 100%;
						}
					}
				}
				&.large {
					width: calc((100% / 2) - 20px);
					&.in-product {
						float: right;
					} 
				}

				div.resume-block {

					div.search-selection {

						opacity: .75;

						p {

							strong {
								font-family: 'Arboria-Bold';
							}
						}
					}
				}

				@media only screen and (max-width: 767px) {
					width: calc(100% - 20px);
					height: auto;
				}

				h3 {
					font-weight: 'Arboria-Bold';
					font-size: 1em;
					margin-top: 0;
					font-weight: 800;
					color: rgba($black, .8);
					text-align: left;
				}

				div.input-field {
					width: 100%;
					margin-top: 0;
					margin-bottom: 6px;
					padding: 0;
					background: $white; 

					span.caret {
						right: 12px;
						z-index: 2;
						pointer-events: none;
					} 
					
					input {
						
						&.select-dropdown {
							box-sizing: border-box;
							height: auto;
							padding: 10px 20px;

							@media only screen and (max-width: 767px) {
								width: 100%;
							}
						}
						
						&.error-warning {
							background-color: tomato !important;
						}
					}

					&.input-type {

						input {
							box-sizing: border-box;
							margin-bottom: 0;
							position: relative;
						    cursor: pointer;
						    background-color: transparent;
						    border: none;
						    outline: none;
						    height: auto;
						    line-height: 1.2rem;
						    width: 100%;
						    font-size: 1rem;
						    margin: 0;
						    padding: 10px 20px; 
						    display: block;
						    border: 1px solid #cacaca;
						    
						    @include placeholder {
							    color: #555; 
						    }
						}

					}
				}

				div.resume-block {
					a.do-search {
						border: none;
						background: #ea6645;
						padding: 8px 20px;
						color: #fff;
						text-transform: uppercase;
						position: relative;
						bottom: -20px;
						font-family: 'Arboria-Book';
						transition: .3s ease;
						
						div {
							display: inline-flex;
							align-items: center;
							
							span {
								// font-family: 'Arboria-Bold';
							}
		
							svg {
								fill: white;
								height: 14px;
								margin-left: 10px;
							}
						}
						
					}
				}

				div.label-block {
					background: $white;
					height: 1.2rem;
					padding: 20px;
					display: block;
					border: 1px solid #cacaca;
					line-height: 1.2rem;
					margin-bottom: 6px;
					position: relative;

					label {
					    width: 100%;
					    line-height: 1.2rem;
					    height: 1.2rem;
					    top: 50%;
					    transform: translateY(-50%);
					    left: 0;
					    position: absolute;
					    text-align: left;
					    padding-left: 20px;
						font-size: 1rem;
						font-family: 'Arboria-Book';
						color: $black;
						font-weight: 100;

						&:after {
							left: initial;
							right: 12px;
							top: 0px;
						}

						span {
							position: relative;
							img {
								height: 100%;
								height: 22px;
								top: -2px;
								left: 3px;
								position: absolute;
							}
						}
					}

					input {
						
					}
				}
			}
			
			&.in-page {
				&.corporate {
					width: 760px;
					
					@media only screen and (max-width: 1535px) {
						margin-top: 60px;
					}
					
					@media only screen and (max-width: 1023px) {
						margin-top: 0;
						padding: 40px;
					}
					
					div.selector-block {
						width: calc(50% - 20px);
						float: left;
						min-height: 162px;
						&:last-child {
							margin-right: 0;
						//	width: calc(34% - 10px);
							min-height: 162px;
						}
						
						@media only screen and (max-width: 1023px) {
							width: 100%;
							&.energies { 
								display: none;
							}
						}
					}	
				}
			}
		}
	}

	div.map {
		min-height: 620px;
		position: relative;
		overflow: hidden;
		z-index: 999;
		width: 55%;
		@media only screen and (max-width:1200px) {
			width: 100%;
			max-height: 45vh;
			min-height: 320px;
		}
		// @media only screen and (max-width: 767px) {
		// 	//height: auto;
		// 	height: 100vh;
		// 	width: 100%;
		// 	margin-bottom: 30px;
		// }

		div.posmap {
			height: 100%;
			.gm-style .gm-style-iw-c{
				border-radius: 0 !important;
				box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06) !important;

			}
			@media only screen and (max-width: 767px) {
				// Show map on mobile now please
				//display: none;
			}
		}

		#posmaptwo{
			&.hidden{
				display: none;
			}
		}

		
	}
	.content-dealers{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: 60px;
		
	}
	div.dealers ,div.dealers-stuv { 
		width: 45%;
		height: 100%;
		right: 0px;
		margin-top: 0;
		padding-left: 40px;
		padding-right: 30px;
		position: relative;
		
		@media only screen and (max-width:1200px) {
			width: 100%;
		}

		@media only screen and (max-width: 767px) {
			width: 100%;
			position: relative;
			padding-left: 20px;
			padding-right: 20px;
		}

		.bloc_info--dealers{
			max-width: 1023px;
			@media only screen and (max-width:1200px) {
				max-width: inherit;
			}
			h3{
				font-size: 20px;
				font-family: 'Arboria-Bold';
				color: #434343;
				margin-top: 0;
				margin-bottom: 5px;
				font-size: 15px;
				
			}
			.content--studio--showroom{
				width: 100%;
				display: block;
				width: 100%;
				margin-right: 30px;
				float: left;
				margin-bottom: 50px;
				.studio  , .showroom, .reseller{
					box-shadow: none;
					float: inherit;
					width: 100%;
					border: none;
					
				}
			}
			.dealer.importer{
				p {
					position: relative;
					padding-left: 80px;
					padding-top: 0;
					font-weight: 900;
					@media only screen and (max-width: 767px) {
						padding-left: 80px;
						font-size: 14px;
					}
					&:before{
						background-image: url('../img/icon_dealers/pin_importers.svg');
						height: 40px;
						width: 40px;
						position: absolute;
						left: 0;
						top: -6px;
						content: "";
						background-size: contain;
						background-repeat: no-repeat;
						@media only screen and (max-width: 767px) {
							height: 35px;
							width: 40px;
							top: 0;
						
						}
					}
				}
				&.disabled{
					opacity: .3;
				}
			}
			.dealer.store{
				p {
					position: relative;
					padding-left: 80px;
					padding-top: 0;
					font-weight: 900;
					@media only screen and (max-width: 767px) {
						padding-left: 80px;
						font-size: 14px;
					}
					&:before{
						background-image: url('../img/icon_dealers/pin_house.svg');
						height: 40px;
						width: 40px;
						position: absolute;
						left: 0;
						top: -6px;
						content: "";
						background-size: contain;
						background-repeat: no-repeat;
						@media only screen and (max-width: 767px) {
							height: 35px;
							width: 40px;
							top: 0;
						
						}
					}
				}
				&.disabled{
					opacity: .3;
				}
			}
			.dealer.studio{
				p {
					position: relative;
				    padding-left: 80px;
    				padding-top: 0;
					font-weight: 900;
					@media only screen and (max-width: 767px) {
						padding-left: 80px;
						font-size: 14px;
					}
					&:before{
						background-image: url('../img/icon_dealers/pin_studio.svg');
						height: 40px;
						width: 40px;
						position: absolute;
						left: 0;
						top: -6px;
						content: "";
						background-size: contain;
						background-repeat: no-repeat;
						@media only screen and (max-width: 767px) {
							height: 35px;
							width: 40px;
							top: 0;
						
						}
					}
				}
				&.disabled{
					opacity: .3;
				}
			}
			.dealer.showroom,
			.dealer.reseller {
				p {
					position: relative;
				    padding-left: 80px;
    				padding-top: 0;
					font-weight: 900;
					@media only screen and (max-width: 767px) {
						padding-left: 80px;
						font-size: 14px;
					}
					&:before{
						background-image: url('../img/icon_dealers/pin_dealers.svg');
						height: 40px;
						width: 40px;
						position: absolute;
						left: 0;
						top: -6px;
						content: "";
						background-size: contain;
						background-repeat: no-repeat;
						@media only screen and (max-width: 767px) {
							height: 35px;
							width: 40px;
							top: 0;
						
						}
					}
				}
				&.disabled{
					opacity: .3;
				}
			}
		}
		div.dealer {
			width: 100%;
			height: auto;
			margin: 0;
			background: none;
			padding: 15px;
			padding-left: 0;	
			text-align: left;
			position: relative;
			@media only screen and (max-width: 767px) {
				padding: 10px 0;
			}
			&:before{
				content: "";
				position: absolute;
				left: 55px;
				top: 10%;
				height: 70%;
				width: 1px;
				color: #434343;
				background-color: #434343;
				@media only screen and (max-width: 767px) {
					left: 60px;
					top: 30%;
					height: 50%;
				}
			}
			@media only screen and (max-width:670px) {
			    width: 100%;
				padding: 35px;
				padding-top: 35px;
				padding-left: 30px;
				padding: 10px 0;
			}
			.cta{
				color: #575757;
			}

			//&:after,
			i.icon {
				//content:'';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 50px;
				height: 40px;
				width: 35px;
				background-size: cover !important;
			
				&.light_grey-d { background-image: url('../img/map/markers/light_grey-d.png') };
				&.light_grey-s { background-image: url('../img/map/markers/light_grey-s.png') };
				&.light_grey-g { background-image: url('../img/map/markers/light_grey-g.png') };
				&.light_grey-showroom { background-image: url('../img/map/markers/light_grey-showroom.png') };
				&.light_grey-i { background-image: url('../img/map/markers/light_grey-i.png') };
				
				&.dark_grey-d { background-image: url('../img/map/markers/dark_grey-d.png') };
				&.dark_grey-s { background-image: url('../img/map/markers/dark_grey-s.png') };
				&.dark_grey-g { background-image: url('../img/map/markers/dark_grey-g.png') };
				&.dark_grey-showroom { background-image: url('../img/map/markers/dark_grey-showroom.png') };
				
				&.orange-d { background-image: url('../img/map/markers/orange-d.png') };
				&.orange-s { background-image: url('../img/map/markers/orange-s.png') };
				&.orange-g { background-image: url('../img/map/markers/orange-g.png') };
				&.orange-showroom { background-image: url('../img/map/markers/orange-showroom.png') };

				&.violet-i { background-image: url('../img/map/markers/violet-i.png') };
				@media only screen and (max-width:670px) {
					left: 30px;
				}
			}
			&.store {
				
				margin-top: 0;

				
				// &:after {
				// 	background: url('../img/map/studio.png') 50% 50%;
				// }
			}
			/*&.classic {
				&:after {
					background: url('../img/map/dealer.png') 50% 50%;
				}
			}

		

			

			&.gallery {
				&:after {
					background: url('../img/map/gallery.png') 50% 50%;
				}
			}*/
			
			// &.outside {
			// 	padding-bottom: 60px;
			// }
			 
			h4 {
				margin: 0;
				font-size: 16px;
					max-width: 220px;
				a {
					font-size: 1.2em;
					text-transform: uppercase;
					margin: 0;
					color: $white ;
					background: none;
					padding: 0;
					font-size: 16px;
					font-family: "Arboria-Bold";
				}
			}
			&.studio , &.showroom  , &.dealer, &.reseller{
				h4 a{
					color: #575757;
				}
			}
			&.store h4 a{
				color: #fff !important;
			}
			p {
				color: #575757;
				margin: 0;
				padding-left: 50px;

				a {
					font-size: 14px;
				}
			}
			a.cta {
				position: absolute;
				padding: 0px 15px;
				color: $white;
				//background: $orange;
				top: 27px;
				right: 10px;
				transition: ease .3s;
				top: 27px;
				right: 30px;
				transition: .3s ease;
				background-color: #e66543;
				padding: 5px 10px;
				@media only screen and (max-width:670px) {
					right: 10px;
    				top: 17px;
				}
				&:hover {
					right: 20px;
				}

				img {
					width: 15px;
				}
			}
		}
	}

}

section.pos.splash {
	&::after{
		background-image: url('../img/bg_pdv2022.png');
	}
}


//SPECIAL RULES

.pos [type=checkbox]+label:before, .pos [type=checkbox]:not(.filled-in)+label:after {
	content: "";
    position: absolute;
    top: 0px;
    left: initial;
    right: 12px;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid #5a5a5a;
    border-radius: 1px;
    margin-top: 2px;
    -webkit-transition: .2s;
    transition: .2s;
}
.pos [type=checkbox].filled-in:checked+label:before, div.material_special_select [type=checkbox].filled-in:checked+label:before {
	top: 0;
    left: initial;
    right: 23px;
    width: 8px;
    height: 13px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(37deg);
    transform: rotate(37deg);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}


//INNER DEALERS

section.template-container.inner-dealers {


	div.template-header {
		height: 250px;
	}


	div.template-content {
		
		@at-root {
			.header-sticky-scroll {
				
				.header-sticky {
					position: fixed;
					background: white;
					width: 100%;
					display: block;
					padding-bottom: 3em;
					z-index: 3000;
					top: 0px;
				}
			}
		}

		div.dealers-details {

			width: 100%;
    		//max-width: 900px;
    		margin: 0 auto;
    		overflow: hidden;
    		margin-top: 0px;
    		background: rgba(29,29,29,.1);

    		div.details-txt {
    			padding: 25px;
    			float: left;
    			width: 400px;

    			li strong {
    				    font-size: 1em;
    margin-top: 0;
    font-weight: 800;
    color: rgba(29,29,29,.8);
    text-align: left;
    text-transform: uppercase;
    			}
    			


    			@media only screen and (max-width: 767px) {
    				width: calc(100% - 20px);
    			}

    			h3 {
					font-size: 1em;
				    margin-top: 0;
				    font-weight: 800;
				    color: rgba(29,29,29,.8);
				    text-align: left;
				    text-transform: uppercase;
    			}
    		}

    		div.details-gallery {
    			margin: 0px 0px;
    			width: calc(100% - 400px);
    			max-height: 700px;
    			float: right;
    			position: relative;
    			padding: 0px;
    			overflow: hidden;
    			margin-bottom: 0px;
    			padding: 20px;
    			background: rgba(29,29,29,.9);
    			
    			@media only screen and (max-width: 767px) {
	    			width: auto;
	    			float: none; 
    			}

    			img {
    				max-height: 700px !important; 
    			}

    			.unslider-arrow {

    			}

    			.unslider-arrow.next {
				    left: auto;
				    right: 20px;
				    color: white;
				}

				.unslider-arrow {
				    position: absolute;
				    left: 20px;
				    z-index: 2;
				    cursor: pointer;
				    top: 50%;
				    transform: translateY(-50%);
				    color: white;
    				font-family: 'Arboria-Bold';
				    font-size: 16px;
				    border: 2px solid #fff;
				    border-radius: 50px;
				    padding: 15px;
				    line-height: 8px;
				}

    			li {
    				text-align: center;
    			}


    			h3 {
					font-size: 1em;
				    margin-top: 0;
				    font-weight: 800;
				    color: rgba(29,29,29,.8);
				    text-align: left;
    			}

    			div.preview {
    				background-size: cover !important;
    				width: 50%;
    				margin: 0 auto;
    				float: left;
    				height: 500px;
    				height: 26vw;

    				@media only screen and (max-width: 767px) {
    					width: 100%;
    				}

    				img {
    					width: 100%;
    				}
    			}
    		}
		}

		div.map-dealers { 
			height: 600px;
			width: 100%;
    		position: relative;
    		overflow: hidden;
    		margin-top: 70px;

    		iframe {
    			background: #c9c9c9;
    			display: block;
    			width: calc(100% - 400px) !important;
    			float: right; 

    			//pointer-events: none;

    			@media only screen and (max-width: 767px) {
    				display: none;
    			}
    		}

    		@media only screen and (max-width: 767px) {
    			height: auto;
    		}

    		div.dealers-infos {
				width: 400px;
			    height: 100%;
			    overflow: hidden;
			    overflow-x: hidden;
			    //overflow-y: scroll;
			    position: absolute;
			    top: 0;
			    left: 0;
			    margin-top: 0;
			    background: rgba(29,29,29,.9);
		

			    @media only screen and (max-width: 767px) {
			    	width: 100%;
			    	position: relative;
			    }

			    div.showroom,
				div.reseller {
			    	width: 100%;
			    	height: 200px;
			    	background-size: cover !important;
			    	
			    	img {
				    	display: none !important;
			    	}
			    }

			    div.dealers-txt {
			    	padding: 25px;
			    }

			    img {
			    	width: 100%;
			    }

				h4 {
					margin: 0;
					font-size: 16px;
					    max-width: 220px;
					
						font-size: 1.2em;
						text-transform: uppercase;
						margin: 0;
						color: $white;
						background: none;
						padding: 0;
						font-size: 16px;
						font-family: "Arboria-Bold";
					
				}

				ul {

					margin: 0;
					padding: 0;
					list-style: none;

					li {
						color: $white;
					}
				}

				p {
					color: $white;
					margin: 0;

					a {
						font-size: 14px;
						color: $orange;
					}
				}
    		}
		}
	}
}





//SPECIAL

li.display-hours {

	strong	{
		display: inline-block;
		width: 120px;
	}
} 

// Block inside products

#pos {
	// @media only screen and (max-width: 1050px) {
	// 	h2 {
	// 		font-size: 1.6em;
	// 	}
	// }
	
	@media only screen and (max-width: 1023px) { 
		p{
			text-align: left;
		}
		//height: 650px; 
		height: 580px;
		
		&:before,
		&:after {
		  content: "";
		  display: table;
		  background-image: none;
		}
		 
		&:after {
		  clear: both;
		}
		
		div.content {
			margin-top: 0;
			position: relative;
			transform: none;
			padding: 40;
			padding-bottom: 0;
			
		}
	}
}

section.pos.pos-products { 
 	
	div.template-content {
		
		background: none;

		@media only screen and (max-width: 1023px) {
			padding: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: auto;
		}	
		
		h2 {
			text-align: left !important;
		}
		
		div.selector {
			
			width: 580px;
			max-width: none; 
			float: right;
			margin: 20px 20px 0 0; 
			
			div.selector-block {
				width: calc(50% - 20px); 
				background: #CCC; 
				
				@media only screen and (max-width: 1023px) {
					width: 100%; 
				}							
			}
			
			@media only screen and (max-width: 1023px) {
				float: none;
				width: 100%;
				max-width: 100%;
				padding: 40px;
				
				div.selector-block {
					margin: 0;
					margin-bottom: 20px;
					
					&.models {
						display: none !important;  
					}
				}
			}
		}
		
		div.resume {	 		
			max-width: 100%;			
			div.resume-block {
				width: 100%;				
				button, a {
					
					margin-right: 37px;
					bottom: -10px;
					float: right;
					width: 248px;
					
					background: $orange;
					opacity: 1;
					font-weight: bold;
					
					@media only screen and (max-width: 1023px) {
						width: 100%;
						margin-right: 0; 
					}
				}
			}
			
			&.show-for-small {
				display: none !important;
			}
			
			@media only screen and (max-width: 1023px) {
				display: none !important;
				&.show-for-small {
					display: block !important;
				}
			}			
		}
	}
}


.special_dealers , .classic_dealers{
	@media only screen and (max-width: 767px){
		background-color: #434343;
		padding-top: 80px;
	}
	.logo_corporate{
		position: absolute;
		left: 30px;
		top: 30px;
		z-index: 100;


		img{
			float: left;
			width: 170px;
			margin-top: 2px;
		}


		@media only screen and (max-width: 767px){
			left: 50%;
			transform: translateX(-50%);

			img{
				
				margin-top: -13px;
				left: 50%;
			}
		}
	}
	.special_dealers--content , .classic_dealers--content{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@media only screen and (max-width: 767px){
			flex-direction: column-reverse;
		}
		.form_special--dealers{
			width: 45%;
			padding-top: 30px;
			@media only screen and (max-width: 767px){
				width: 100%;
			}
			h1{
				color: #EA6645;
				text-align: left;
				padding-left: 60px;
				// text-transform: inherit;
				// font-size: 2.5em;
				// max-width: 1279px;
				// width: auto;
				// margin-top: 40px;
				// max-width: 1279px;
				// width: auto;
				// margin-top: 40px;
				// margin-left: 0;
				// position: relative;
				// padding-left: 60px;
				
				&:before{	
					width: 37px;
					height: 45px;
					background-size: contain;
					position: absolute;
					content: "";
					top: -10px;
					left: 0;
					@media only screen and (max-width: 767px){
						width: 27px;
					}
				}
				
				&.house:before {
					background: url(../img/icon_dealers/pin_house.svg) no-repeat center center; 
					background-size: contain;
				}
				
				&.studio:before {
					background: url(../img/icon_dealers/pin_studio.svg) no-repeat center center; 
					background-size: contain;
				}
				
				&.dealer:before {
					background: url(../img/icon_dealers/pin_dealers.svg) no-repeat center center; 
					background-size: contain;
				}

				@media only screen and (max-width: 767px){
					font-size: 1.7em;
					padding: 0;
					margin: 40px 20px 20px;
					padding-left: 45px;
				}
				

				&::after{
					display: none;
				}
			}

			p{
				max-width: 80%;
				font-size: 18px;
				@media only screen and (max-width: 767px){
					max-width: 90%;
					margin: 0 auto;
				}
			}
			.form-appointment {
				padding-top: 0;
				padding-left: 0;
				@media only screen and (max-width: 767px){
					padding-top: 0;
					padding: 0;
					margin: 0 20px;
				}
				form{
					h2{
						color: #434343;
						text-align: left;
						// text-transform: inherit;
						// margin-top: 80px;

						// @media only screen and (max-width: 639px){
						// 	margin-top: 40px;
						// }
					}

					p{
						text-align: left !important;
					}
					input[type=submit]{
						width: 30%;
						text-transform: inherit;
						margin: 0 auto;
						margin-top: 30px;
					}
				}
			}
		}

		.form_classic--dealers{
			margin-left: 3em;
			@media only screen and (max-width: 1279px){
				width: 90%;
				margin: 0 auto;
			}
			h1{
				color: #434343;
				// font-size: 2em;
				// @media only screen and (max-width: 639px){
				// 	font-size: 1.7em;
				// 	padding: 0;
				// 	margin: 40px 20px 20px;
				// 	padding-left: 45px;
				// }
			}
		}
		.slider_pos--special_dealers , .slider_pos--classic_dealers{
			width: 52%;
			position: relative;
			overflow: hidden;
			background-color: #434343;

			@media only screen and (max-width: 767px){
				width: 100%;
				height: 100%;
				
			}
			&:after{
				content: "";
				width: 80px;
				height: 100%;
				background-color: #fff;
				position: absolute;
				right: 0;
				top: 0;
				@media only screen and (max-width: 767px){
					display: none;
				}
			}
			.content{
				width: 80%;
			    margin-top: -140px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				background-color: #fff;
				padding: 3em;
				color: #434343;
				z-index: 10;
				position: absolute;
				left: 2em;
				box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
				font-size: 14px;
				max-width: 672px;
				@media only screen and (max-width: 1800px){
					width: 90%;
					font-size: 14px;
				}
				@media only screen and (max-width: 1279px){
					width: 87%;
					font-size: 14px;
				}
				@media only screen and (max-width: 767px){
					position: relative;
					width: 90%;
					left: 0;
					margin: 0 auto;
					margin-top: -90px;
					max-width: 100%;
					margin-bottom: 50px;
					
				}
			
				p , h4 ,  ul , li{
					color: #434343;
				}
				h4{
					margin-top: 0;
					color: #ea6745;
					text-transform: uppercase;
				}
				h3{
					color: #434343;
					font-weight: 900;
					font-size: 22px;
				}

				ul{
					list-style: none;
					padding: 0;
				}
			}
		}

		.slider_pos--classic_dealers{
			background-color: #fff;
			width: 50%;
			@media only screen and (max-width: 1279px){
				width: 100%;

				.content{
					position: relative;
					width: 90%;
					left: 0;
					margin: 0 auto;
					margin-top: -90px;
					max-width: 100%;
					margin-bottom: 50px;
				}
			}
		}
	
		
	}

	.classic_dealers--content{
		flex-direction: row-reverse;
		@media only screen and (max-width: 1279px){
			flex-direction: column-reverse;
			padding-top: 50px;
		}
		@media only screen and (max-width: 767px){
			padding-top:  0px;
		}
		
		.swiper-containe-dealer{
			@media only screen and (max-width: 1279px){
				flex-direction: column-reverse;
				margin-top: 0px;
			}
		}
	}
}

.classic_dealers{
	margin-top: 110px;
	@media only screen and (max-width: 1279px){
		margin-top: 0px;
	}
}

.map-dealers{
	height: 330px;
}

.corporate.pos:after{ 
	display: none;
}

.content--map_dealers{
	margin-top: -70px;
	
	.dealers-infos{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 30px;
		max-width: 1800px;
		width: auto;
		margin-top: 40px;
		margin: 0 auto;
		.details-txt--modele{
			padding: 2em;
			width: 70%;
			background-color: #fff;
			max-width: 45%;
			z-index: 2;
			box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
			@media only screen and (max-width: 639px){
				width: 100%;
				max-width: 100%;
			}
			ul{
				list-style: none;
				column-count: 3;
				padding: 0;
				li{
					margin-bottom: 5px;
				}
			}
			h4{
				color: #ea6745;
				text-transform: uppercase;
			}

			@media only screen and (max-width: 1279px){
				ul{
					list-style: none;
					column-count: 2;
					padding: 0;
					li{
						margin-bottom: 5px;
					}
				}
			}
		}
		@media only screen and (max-width: 1800px){
			margin-left: 30px;
			margin-right: 30px;
		}
		@media only screen and (max-width: 639px){
			width: 90%;
			margin: 0 auto;
		}

		.dealers-txt{
			h4{
				font-size: 21px;
				color: #434343;
				margin: 0;
			}
		}
	}

	.dealers-txt{
		color: #434343;
		box-shadow: 0 1px 23px 1px rgba(0,0,0,.1);
		padding: 2em;
		width: 45%;
		color: #434343;
		z-index: 2;
		height: fit-content;

		@media only screen and (max-width: 639px){
			max-width: 100%;
		}

		p{
			line-height: 1.6;
			font-size: 14px;
			margin-bottom: 0;
		}

		h4{
			color: #fff;
			
		}
	}

	.content-right{
		background-color: #fff;
		box-shadow: 0 1px 23px 1px rgba(0,0,0,.1);
		padding: 2em;
		width: 45%;
		color: #434343;
		z-index: 2;
		height: fit-content;
		@media only screen and (max-width: 1535px){
			width: 40%;
		}
		@media only screen and (max-width: 639px){
			width: 100%;
			margin-top: 50px;
		}

		ul{
			padding: 0;
			text-align: center;
			padding: 12px 0;
			margin: 0;
			li{
				
				width: calc(100% / 3 - 3px);
				text-align: center;
				padding-top: 70px;
				position: relative;
				@media only screen and (max-width: 1279px){
					width: 100%;
				}
				&:after{
					width: 20px;
					height: 80px;
					background: url(../img/icon_dealers-01.svg) no-repeat center center; 
					background-size: contain;
					position: absolute;
					content: "";
					top: -10px;
					left: 50%;
					transform: translateX(-50%);
				}
				&:nth-child(2){
					border-right: 1px solid #434343;
					border-left: 1px solid #434343;
					@media only screen and (max-width: 1279px){
						border: none;
					}
					&:after{
						width: 50px;
						height: 100px;
						background: url(../img/icon_dealers-02.svg) no-repeat center center; 
						background-size: contain;
					}
				}
				&:nth-child(3){
					&:after{
						width: 50px;
						height: 100px;
						background: url(../img/icon_dealers-03.svg) no-repeat center center; 
						background-size: contain;
					}
				}
				span{
					width: 100%;
					display: block;
					font-weight: 900;
					margin-bottom: 10px;
				}
				display: inline-block;
				
			}
		}
	}
}

.swiper-containe-dealer {
	width: 100%;
	height: 100%;
    margin-top: 160px;
	padding-bottom: 50px;
	max-height: 470px;
	position: relative;

	@media only screen and (max-width: 767px){
		width: 100%;
		height: 100%;
		margin-top: 80px;
		padding-bottom: 50px;
		max-height: 470px;
		position: relative;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	
	
	.swiper-button-prev , .swiper-button-next{
		bottom: 0;
		top: inherit;
		color: #fff;
		background: no-repeat;

		&:after{
			font-size: 17px;
			font-weight: 800;
		}

		&.swiper-button-next{
			right: 12%;
			bottom: -10px;
			top: inherit;
			left: inherit;
			@media only screen and (max-width: 767px){
				right: 12%;
				bottom: 20px;
			}
		}
		&.swiper-button-prev{
			right: 16%;
			bottom: -10px;
			top: inherit;
			left: inherit;
			@media only screen and (max-width: 767px){
				right: 14%;
				bottom: 20px;
			}
		}
	}	
	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.swiper-containe-realisation{
	padding-bottom: 0;
	max-height: initial;
	width: 100%;
	height: 100%;
	padding-bottom: 50px;
	position: relative;
	@media only screen and (max-width: 767px){
		margin-top: 20px;
		padding-top: 110px;
	}
	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.slider--buttons{
	
		@media only screen and (max-width: 767px){
			top: -50px;
			left: 20px;
			
		}
  
	
		&:after{
			content: "";
			width: 100%;
			height: 100%;
			background-color: #434343;
			position: absolute;
			right: 0;
			top: 0;
			@media only screen and (max-width: 767px){
				display: none;
			}
	  
		}
	}
		
	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.swiper-wrapper{
		height: 470px;
		width: 55%;
	}

	.slider--buttons{
		height: 50px;
		@media only screen and (max-width: 767px){
			left: inherit;
		}
	}

	.swiper-button-prev , .swiper-button-next{

		color: #303030;
		background: #fff;
		width: 34px;
		height: 34px;
		&:after{
			font-size: 17px;
		}

		&.swiper-button-next{
		    left: 60px;
			bottom: 0;
			top: 90%;
			@media only screen and (max-width: 767px){
				top: 60px;
			}
		}

		&.swiper-button-prev{
		    left: 0px;
			bottom: 0;
			top: 90%;
			@media only screen and (max-width: 767px){
				top: 60px;
				left: 10px;
			}
		
		}
	}	
}
.section-map{ 
	background-color: #434343;

	&.section-map-classic{
		background-color: #ffffff;
	}
}
.section-realisation { 
	background-color: #434343;

	&.section-realisation--classic{


		.dealers-details{
			background-color: #ffffff;
			flex-direction: row-reverse;

			.details-gallery{
				
				@media only screen and (max-width: 1279px){
				    width: 90%;
					position: relative;
					overflow: hidden;
					background-color: #434343;
					margin-right: 30px;
					margin-left: auto;
					margin: 0 auto;
					margin-top: 10px;
				}
			}

			.realisation{
				color: #434343;
				width: 35%;
				text-align: left;
				font-size: 19px;
				margin-top: 130px;
				margin-left: 2%;
				margin-right: 8%;
				margin-top: 190px;
				z-index: 150;

				@media only screen and (max-width: 1279px){
					width: 85%;
					margin-top: 50px;
				}
			}
		}

		.swiper-containe-realisation{
			padding-top: 80px;
			left: inherit;
			@media only screen and (max-width: 767px){
			    background-color: #fff;
    			margin: 0;
			}
			
		}

		.swiper-containe-realisation .slider--buttons:after{
			background-color: #fff;
		}
		.swiper-containe-realisation .swiper-button-next, .swiper-containe-realisation .swiper-button-prev{
			color: #ffffff;
			background: #EA6645;
		}

		.swiper-button-prev{
			right: 50px;
			bottom: 0;
			top: 90%;
			left: inherit;

			@media only screen and (max-width: 1279px){
				top: 30px;
				right: 90px;
			}
		}
		.swiper-button-next{
			right: 0;
			bottom: 0;
			top: 90%;
			left: inherit;

			@media only screen and (max-width: 1279px){
				top: 30px;
				right: 40px;
			}
		}
	}

	.dealers-details{
		background-color: #434343;
		position: relative;
		padding-bottom: 100px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@media only screen and (max-width: 767px){
			padding-bottom: 100px;
		}
		.realisation{
			width: 28%;
			text-align: right;
			font-size: 19px;
			margin-top: 130px;
			margin-left: 14%;
			margin-top: 190px;
			color: #fff;
			z-index: 150;
			@media only screen and (max-width: 1279px){
				width: 40%;
				text-align: right;
				font-size: 19px;
				margin-top: 130px;
				margin-left: 2%;
				margin-top: 190px;
				color: #fff;
				z-index: 150;
			}

			@media only screen and (max-width: 767px){
				width: 90%;
				text-align: left;
				font-size: 19px;
				margin-top: 130px;
				margin-left: 2%;
				margin-top: 40px;
				color: #fff;
				margin: 0 auto;
				margin-top: 30px;
				z-index: 150;
			}
			h3{
				font-size: 32px;
				font-weight: 900;
			}
		}
		.details-txt{
			box-shadow: 0 1px 23px 1px rgba(0,0,0,.1);
			padding: 2em;
			max-width: 50%;
			width: 40%;
			background: #fff;
			margin-top: 130px;
			color: #434343;
			position: absolute;
		    right: 46%;
			z-index: 10;
			top: 50px;
			height: auto !important;

			@media only screen and (max-width: 767px){
				height: 388px;
				width: 90%;
				position: inherit;
				left: 0;
				max-width: 100%;
				margin: 0 auto;
			}
			

			h3{
				font-weight: 800;
				font-size: 22px;
				font-weight: 900;
				font-size: 22px;
				margin-top: 0;
				font-size: 21px;
				color: #434343;
				margin: 0;
				font-family: 'Arboria-Bold';



			}
			ul{
				column-count: 2;
				line-height: 1.4;
			}
		}

		.details-gallery{
			width: 52%;
			position: relative;
			overflow: hidden;
			background-color: #434343;
			margin-right: 30px;
			margin-left: auto;
			margin-top: 130px;

			@media only screen and (max-width: 767px){
				width: 100%;
				margin-right: 0px;
				margin-top: 0px;
			}

			.swiper-slide{
				height: auto;
				object-fit: contain;
				background-color: #303030;
				img{
					display: block;
					width: 100%;
					max-height: 470px;
					height: 100%;
					-o-object-fit: cover;
				    object-fit: contain!important;
				}
			}
		}
	}
}


body.page--dealers_house{
margin-top: 0;
}
.page--dealers_house header , .page--dealers_house footer {
display: none;
}

.list_dealers{
	margin-top: 60px;

	ul{
		li{
			text-align: left;
			margin-bottom: 50px;
			display: inline-block;
			width: 32%;
			@media only screen and (max-width: 767px) {
				width: 48%;
			}
			h4{
				font-size: 14px;
				text-transform: uppercase;
			}
			&.list_dealers--house h4,
			&.list_dealers--store h4 {
				color: #EA6645;
				position: relative;
				&:before{
					background-image: url('../img/icon_dealers/pin_house.svg');
					height: 24px;
					width: 24px;
					position: absolute;
					left: 0;
					top: -30px;
					content: "";
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
			&.list_dealers--importer h4{
				color: #9A3367;
				position: relative;
				&:before{
					background-image: url('../img/icon_dealers/pin_importer.svg');
					height: 24px;
					width: 24px;
					position: absolute;
					left: 0;
					top: -30px;
					content: "";
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
			&.list_dealers--studio h4{
				color: #F29400;
				position: relative;
				&:before{
					background-image: url('../img/icon_dealers/pin_studio.svg');
					height: 24px;
					width: 24px;
					position: absolute;
					left: 0;
					top: -30px;
					content: "";
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
			&.list_dealers--dealers h4,
			&.list_dealers--dealer h4 {
				color: #919092;
				position: relative;
				&:before{
					background-image: url('../img/icon_dealers/pin_dealers.svg');
					height: 24px;
					width: 24px;
					position: absolute;
					left: 0;
					top: -30px;
					content: "";
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
			p{
				font-size: 14px;
				margin: 0;
				line-height: 1.5;
			}

			a{
				color: #434343;
				display: inline-block;
				font-size: 14px;
				position: relative;
				padding-right: 15px;
				margin-top: 10px;
				transition: .3s ease-in-out;
				&:after{
				
					background-image: url('../img/icon_dealers/arrow.svg');
					height: 10px;
					width: 8px;
					position: absolute;
					right: 1px;
					top: 3px;
					content: "";
					background-size: contain;
					background-repeat: no-repeat;
					transition: .3s ease-in-out;
				}

				&:hover{
					&:after{
				
						background-image: url('../img/icon_dealers/arrow.svg');
						height: 10px;
						width: 8px;
						position: absolute;
						right: -6px;
						top: 3px;
						content: "";
						background-size: contain;
						background-repeat: no-repeat;
						
					}
				}
			}
		}
	}
	.discover--more{
		padding: 10px 25px;
		border: 3px solid #EA6645;
		display: inline-block;
		transition: .3s ease-in;
		font-weight: 900;
		&:hover{
			background-color: #EA6645 ;
			color: #FFF;
		}

	}
}

		// &.store span{
		// 	background-color: #FBDEDB !important;
		// 	color: #E74C3C !important;
		// }
		// &.dealer span{
		// 	background-color: #DCDCDC;
		// 	color: #434343;
		// }

		// p{
		// 	padding-left: 0;
		// }

		.multipleSelection {
            width: 300px;
            background-color: #BCC2C1;
        }
  
        .selectBox {
            position: relative;
        }
  
        .selectBox select {
            width: 100%;
            font-weight: bold;
        }
  
        .overSelect {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
  
        #checkBoxes {
            display: none;
            border: 1px #8DF5E4 solid;
        }
  
        #checkBoxes label {
            display: block;
        }
  
        #checkBoxes label:hover {
            background-color: #4F615E;
        }


section.dealers--details{
	margin-top: 230px;
	.template-content .header-sticky{
		
		h1{
			text-align: left;
			// margin: 0;
		}
	}
}

.select2-container{
	width: 100% !important;
}


.list_dealers_list{
	text-align: left;
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
.section-realisation .dealers-details .details-gallery.details-gallery-classic .swiper-slide{
	background: #f8f8f8 !important;
}


section.block_pdv-pos{
	height: 450px !important;
	@media only screen and (max-width: 767px) {
		height: 540px !important;
	}
	div.infos div.content {
		h2, p{
			color: #494a52;
		}
	}
}