// Text Inputs + Textarea
$input-height: 3rem !default;
$input-border-color: $hard-grey !default;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;
$input-error-color: tomato !default;
$input-success-color: green !default;
$input-focus-color: $orange !default;
$input-font-size: 1rem !default;
$input-margin: 0 0 20px 0 !default;
$input-padding: 0 !default;
$input-transition: all .3s !default;
$label-font-size: .8rem !default;
$input-disabled-color: rgba(0,0,0, .26) !default;
$input-disabled-solid-color: #BDBDBD !default;
$input-disabled-border: 1px dotted $grey !default;
$input-invalid-border: 1px solid tomato !default;
$placeholder-text-color: lighten($input-border-color, 20%) !default;

// Radio Buttons
$radio-fill-color: $orange !default;
$radio-empty-color: #5a5a5a !default;
$radio-border: 2px solid $grey !default;

// Range
$range-height: 14px !default;
$range-width: 14px !default;
$track-height: 3px !default;

// Select
$select-border: 1px solid #f2f2f2 !default;
$select-background: rgba(255, 255, 255, 0.90) !default;
$select-focus: 1px solid lighten($orange, 47%) !default;
$select-padding: 5px !default;
$select-radius: 2px !default;
$select-disabled-color: rgba(0,0,0,.3) !default;

// Switches
$switch-bg-color: $orange !default;
$switch-checked-lever-bg: desaturate(lighten($orange, 25%), 25%) !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: #818181 !default;
$switch-radius: 15px !default;

$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
$dropdown-color: $secondary-color !default;
$dropdown-item-height: 50px !default;

// Remove Focus Boxes

.materialize {

	select:focus {
	  outline: $select-focus;
	}
	
	button:focus {
	  outline: none;
	  background-color: $orange;
	}
	
	label {
	  font-size: $label-font-size;
	  color: $input-border-color;
	}

}

@import 'forms/input-fields';
@import 'forms/radio-buttons';
@import 'forms/checkboxes';
@import 'forms/switches';
@import 'forms/select';
@import 'forms/file-input';
@import 'forms/range';
