// Product swiper

div.block.swiper-container {
	padding: 0 !important;

	
	
	.swiper-slide {
		background-size: cover;
		background-position: center center;
		min-height: 400px;
		@media only screen and (max-width: 767px) {
			max-height: 400px;
		}
		
		a {
			cursor: pointer;
			display: block;
			width: 100%;
			height: 100%;

			&:hover {
				&:after {
					content:'';
					position: absolute;
					height: 50px;
					width: 50px;
					background-image: url('../img/zoom-in.svg');
					background-size: cover;
					background-position: 50% 50%;
					top: 50%;
					left: 0;
					right: 0;
					text-align: center;
					margin: 0 auto;
					transform: translateY(-50%)
				}
			}
			
			img {
				visibility: hidden; 
			}
		}
	}
	
	.swiper-button-prev,
	.swiper-button-next {
		background-image: url("data:image/svg+xml;charset=utf-2,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ea6645'%2F%3E%3C%2Fsvg%3E");

		&:before, &:after{
			display: none;
		}
	}
	
	.swiper-button-prev {
		left: 20px;
	}
	
	.swiper-button-next {
		transform: rotate(180deg);
		right: 20px;
	}
	
	.swiper-pagination-bullet-active {
		color: $orange;
		background: $orange;
	}	
}

.pswp__caption__center {
	&.center {
		text-align: center !important;
		font-family: 'Arboria-Book', sans-serif !important;
		font-weight: 300 !important;
	}
}
 